import { useState } from 'react';
import { getIcon } from 'utils/icons';

function ProductCard(props: {
    name: string;
    img: string;
    visible: boolean;
    price: number;
    type: string;
    id: string;
    alert: number;
    reference?: string;
    productType?: string;
    color?: string;
    updateVisibility: any;
    isSelected: boolean;
    setSelected: any;
    category: string;
}) {
    const [displayQR, setDisplayQR] = useState(false);
    const {
        name,
        img,
        type,
        price,
        visible,
        reference,
        productType,
        isSelected,
        setSelected,
        category,
    } = props;
    return (
        <div
            className={`relative flex items-center rounded-[20px] bg-clip-border border px-[18px] py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none `}
            onMouseOver={() => setDisplayQR(true)}
            onMouseLeave={() => setDisplayQR(false)}
        >
            <p className="text-xs absolute bottom-2 left-4 text-gray-600">
                {productType === 'transformed' && 'Transformé'}
                {productType === 'assembled' && 'Assemblé'}
                {productType === 'brut' && 'Brut'}
            </p>
            <input
                key={`-checkbox`}
                id={'test'}
                type="checkbox"
                checked={isSelected}
                onChange={setSelected}
                className="absolute top-2 right-3 w-4 h-4 text-toola-500 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            ></input>
            <div className="absolute bottom-1 right-2">
                {!visible && category !== 'hidden' && (
                    <p className="text-xs text-orange-400">
                        Caché pour certains clients
                    </p>
                )}
                {visible && category !== 'hidden' && (
                    <p className="text-xs text-green-600">Visible pour tous</p>
                )}
                {category === 'hidden' && (
                    <p className="text-xs text-red-600">Non vendable</p>
                )}
            </div>
            <div className="grid grid-cols-12">
                <div className="-bottom-12 col-span-4 flex h-[87px] w-[87px] items-center justify-center rounded-full">
                    <object
                        data={type === 'product' ? img : getIcon(name, type)}
                        type="image/png"
                        className={`h-[80px] w-[80px] rounded-xl`}
                    >
                        {productType === 'brut' && (
                            <div className="relative bg-[#d9d9d9] h-[75px] w-[75px] text-center rounded-xl flex items-center justify-center">
                                <p className="text-white font-bold text-xl">
                                    BRUT.
                                </p>
                            </div>
                        )}
                        {productType === 'transformed' && (
                            <div className="relative bg-[#ffdf59] h-[75px] w-[75px] text-center rounded-xl flex items-center justify-center">
                                <p className="text-white font-bold text-md">
                                    TRANSF.
                                </p>
                            </div>
                        )}
                        {productType === 'assembled' && (
                            <div className="relative bg-[#7ed956] h-[75px] w-[75px] text-center rounded-xl flex items-center justify-center">
                                <p className="text-white font-bold text-md">
                                    ASSEMB.
                                </p>
                            </div>
                        )}
                    </object>
                </div>
                <div className="col-span-8 ml-4">
                    <h2
                        className={`mt-1 text-sm font-medium leading-6 text-navy-700 dark:text-white`}
                    >
                        {name}
                    </h2>
                    <p className="mb-1 text-xs font-medium text-gray-600">
                        {reference ? reference : 'Référence: inconnue'}
                    </p>
                    <h1 className="mt-2 text-md font-bold">
                        {price && price.toFixed(2)} €
                    </h1>
                </div>
            </div>
        </div>
    );
}

export default ProductCard;
