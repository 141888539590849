import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActionButton from 'components/buttons/ActionButton';
import { getIcon } from 'utils/icons';
import {
    getAlreadyOrderedProducts,
    getAutoOrderConditions,
    getPaginatedAutoOrderProducts,
    sendAutoOrder,
} from 'interfaces/autoOrder';
import InputField from 'components/fields/InputField';
import ProductCard from './ProductCard';
import ProductLoadingCard from './ProductLoadingCard';
import { useDisclosure } from '@chakra-ui/hooks';
import AnnounceModal from 'views/admin/main/ecommerce/commandPage/default-toola/AnnounceModal';
import Header from '../default-toola/Header';
import ProductViewModal from '../default-toola/ProductViewModal';
import { icons } from 'views/stock/updateProduct/components/CreateCategory';

const CommandInfo = (props: { haveMultipleSupliers: boolean }) => {
    const { haveMultipleSupliers } = props;

    const nav = useNavigate();
    const [autoOrderProducts, setAutoOrderProducts] = useState([]);
    const [alreadyOrderedProducts, setAlreadyOrderedProducts] = useState([]);
    const [autoOrderProductsMeta, setAutoOrderProductsMeta] = useState(null);
    const [newOrder, setNewOrder] = useState<any>({ items: [], totalPrice: 0 });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [search, setSearch] = useState('');
    const [dateError, setDateError] = useState(null);
    const [page, setPage] = useState(1);
    const isFullyLoaded = autoOrderProducts.length > 0 && autoOrderProductsMeta;
    const [showOrderSummary, setShowOrderSummary] = useState(false);
    const [productsPerLoad, setProductsPerLoad] = useState(0);
    const [companyInfos, setCompanyInfos] = useState<any>(null);
    const [conditions, setConditions] = useState<any>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [category, setCategory] = useState('all');

    const [selectedProductId, setSelectedProductId] = useState(0);
    const [confirmPage, setConfirmPage] = useState(false);

    const {
        isOpen: isOpenProductView,
        onOpen: onOpenProductView,
        onClose: onCloseProductView,
    } = useDisclosure();
    const productsPerScroll = 36;

    const handleChange = (e: any) => {
        setNewOrder((prevCustomer: any) => ({
            ...prevCustomer,
            [e.target.id]: e.target.value,
        }));
    };

    const handleDateChange = (e: any) => {
        const today = new Date();
        const date = new Date(e.target.value);
        const day = date
            .toLocaleDateString('en-US', { weekday: 'long' })
            .toLowerCase();

        date.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        // Handle blocked days
        if (conditions && conditions.blockedDays.includes(day)) {
            let error = 'Livraisons indisponible le ';

            conditions.blockedDaysFr.forEach(
                (blockedDay: any, index: number) => {
                    if (index === 0) error += blockedDay;
                    else if (index === conditions.blockedDaysFr.length - 1)
                        error += ' et ' + blockedDay;
                    else error += ', ' + blockedDay;
                },
            );
            setDateError(error);
            handleChange({ target: { id: 'deliveryDate', value: '' } });
            return;
        }

        // Handle delivery delay
        if (conditions && conditions.deliveryDelay !== 0) {
            const deliveryDelay = conditions.deliveryDelay;
            const deliveryDate = new Date(e.target.value);

            const dateOrderAvailable = new Date();
            dateOrderAvailable.setHours(0, 0, 0, 0);
            dateOrderAvailable.setDate(
                dateOrderAvailable.getDate() + deliveryDelay,
            );

            if (dateOrderAvailable > deliveryDate) {
                setDateError(
                    `La date de livraison doit être au moins dans ${deliveryDelay} jours`,
                );
                handleChange({ target: { id: 'deliveryDate', value: '' } });
                return;
            }
        }

        if (date < today) {
            setDateError('La date de livraison ne peut pas être dans le passé');
            handleChange({ target: { id: 'deliveryDate', value: '' } });
            return;
        }
        setDateError(null);
        handleChange(e);
    };

    const fetchProducts = async (firstLoad = false) => {
        // if (firstLoad) {
        //     const productsPerLine = window.innerWidth < 640 ? 2 : (window.innerWidth < 1024 ? 3 : 4);
        //     const productsCollumns = Math.ceil(window.innerHeight / 207);
        //     setProductsPerLoad(productsPerLine * productsCollumns);
        // }
        const res = await getPaginatedAutoOrderProducts(page, 500);
        if (res) {
            setCompanyInfos({
                company: res.company,
                customerCompany: res.customerCompany,
                logo: res.companyLogoUrl,
                contact: res.contact,
                announcements: res.announcements,
                categories: res.categories,
            });

            if (res.announcements) onOpen();

            setAutoOrderProducts(() => [...res.products]);
            setPage(firstLoad ? 1 : (prevPage) => prevPage + 1);
            setAutoOrderProductsMeta(res.meta);
            if (firstLoad) {
                setProductsPerLoad(productsPerScroll);
            }
        }
    };

    const sendOrder = async () => {
        const data = { ...newOrder };
        data.items = data.items.filter(
            (i: any) => i.quantity > 0 && i.quantity !== '',
        );

        //Remove img from items for not sending it to the server
        data.items = data.items.map((i: any) => {
            delete i.img;
            return i;
        });
        const res = await sendAutoOrder(newOrder);
        if (res) {
            if (res.orderId) nav(`/command/success?id=${res.orderId}`);
            else nav('/command/success');
        }
    };

    const searchProduct = (product: any, search: string) => {
        for (const key in product)
            if (
                product[key] &&
                typeof product[key] === 'string' &&
                product[key]
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase())
            )
                return true;
        return false;
    };

    const addNewItem = (productId: string, name: string, quantity: number) => {
        let updatedItems: any = [];
        const alreadyExist = newOrder.items.find(
            (i: any) => i.productId === productId,
        );
        const price = autoOrderProducts.find(
            (p: any) => p.id === productId,
        ).price;

        const unity = autoOrderProducts.find(
            (p: any) => p.id === productId,
        ).unity;

        const img = getIcon(name, 'product');

        if (alreadyExist) {
            updatedItems = newOrder.items.map((item: any) => {
                if (item.productId === productId) {
                    return {
                        ...item,
                        quantity: item.quantity + quantity,
                    };
                }
                return item;
            });
        } else {
            updatedItems = [...newOrder.items];
            updatedItems.push({
                productId: productId,
                quantity: quantity,
                unity: unity,
                img: img,
                name,
                price,
            });
        }

        setNewOrder((prevCustomer: any) => ({
            ...prevCustomer,
            items: updatedItems,
            totalPrice: prevCustomer.totalPrice + price * quantity,
        }));
    };

    const removeItem = (productId: string) => {
        const itemToRemove = newOrder.items.find(
            (i: any) => i.productId === productId,
        );
        if (!itemToRemove) return;

        let totalPrice = newOrder.totalPrice;

        if (itemToRemove.quantity >= 1)
            totalPrice -= itemToRemove.price * itemToRemove.quantity;

        // Reduce quantity for the item
        let updatedItems = newOrder.items.map((item: any) => {
            if (item.productId === productId) {
                return {
                    ...item,
                    quantity: item.quantity - itemToRemove.quantity,
                };
            }
            return item;
        });

        // Remove item if quantity is < 0
        updatedItems = updatedItems.filter((item: any) => item.quantity > 0);

        setNewOrder((prevCustomer: any) => ({
            ...prevCustomer,
            items: updatedItems,
            totalPrice,
        }));
    };

    const handleQuantityChange = (quantity: number, id: string) => {
        if (quantity === 0) {
            return removeItem(id);
        }

        const updatedItem = newOrder.items.find((i: any) => i.productId === id);

        let totalPrice =
            newOrder.totalPrice - updatedItem.quantity * updatedItem.price;
        totalPrice += quantity * updatedItem.price;

        setNewOrder((prevCustomer: any) => ({
            ...prevCustomer,
            items: prevCustomer.items.map((item: any) => {
                if (item.productId === id) {
                    return {
                        ...item,
                        quantity: quantity,
                    };
                }
                return item;
            }),
            totalPrice: totalPrice,
        }));
    };

    const fetchAlreadyOrderedProducts = async () => {
        const res = await getAlreadyOrderedProducts();
        if (res) setAlreadyOrderedProducts(res);
    };

    const fetchConditions = async () => {
        const res = await getAutoOrderConditions();
        if (res) setConditions(res);
    };

    const getOrderCount = (productId: any) => {
        const sum = alreadyOrderedProducts.find((p: any) => p._id === productId)
            ?.count;
        return sum ? sum : 0;
    };

    const productsHaveFav = () => {
        if (!autoOrderProducts || !alreadyOrderedProducts) return false;
        for (const product of autoOrderProducts) {
            const fav = alreadyOrderedProducts.find(
                (p: any) => p._id === product.id,
            );
            if (fav) return true;
        }
        return false;
    };

    useEffect(() => {
        fetchProducts(true);
        fetchAlreadyOrderedProducts();
        fetchConditions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="h-full w-full rounded-xl xl:!pl-10 xl:!pr-4">
            <AnnounceModal
                isOpen={isOpen}
                onClose={onClose}
                description={companyInfos ? companyInfos.announcements : ''}
            />
            {autoOrderProducts && selectedProductId ? (
                <ProductViewModal
                    isOpen={isOpenProductView}
                    onClose={() => {
                        onCloseProductView();
                        setSelectedProductId(null);
                    }}
                    product={autoOrderProducts.find(
                        (p: any) => p.id === selectedProductId,
                    )}
                    newOrder={newOrder}
                    onAddProduct={addNewItem}
                    onQuantityChange={handleQuantityChange}
                />
            ) : (
                ''
            )}
            {/* Header */}
            <Header
                companyInfos={companyInfos}
                haveMultipleSupliers={haveMultipleSupliers}
            />

            {/* Categories */}
            {companyInfos &&
                !confirmPage &&
                companyInfos.categories &&
                companyInfos.categories.length > 0 && (
                    <div className="mt-2 flex gap-2 overflow-x-auto">
                        <p
                            className={`bg-white rounded-xl py-1 px-3 text-sm hover:cursor-pointer ${
                                'all' === category && 'font-bold shadow'
                            }`}
                            onClick={() => setCategory('all')}
                        >
                            Tout
                        </p>
                        {productsHaveFav() && (
                            <p
                                className={`bg-white rounded-xl py-1 px-3 text-sm hover:cursor-pointer ${
                                    'fav' === category && 'font-bold shadow'
                                }`}
                                onClick={() => setCategory('fav')}
                            >
                                ❤️ Favoris
                            </p>
                        )}
                        {companyInfos.categories
                            // Remove categories without products
                            .filter((c: any) =>
                                autoOrderProducts.find(
                                    (p: any) => p.category === c.id,
                                ),
                            )
                            .map((cat: any) => (
                                <p
                                    className={`bg-white block rounded-xl py-1 px-3 text-sm hover:cursor-pointer ${
                                        cat.id === category &&
                                        'font-bold shadow'
                                    }`}
                                    onClick={() => setCategory(cat.id)}
                                >
                                    {icons.find((i: any) => i.name === cat.icon)
                                        ?.icon || ''}{' '}
                                    {cat.name}
                                </p>
                            ))}
                    </div>
                )}

            {/* Order details */}
            {confirmPage && (
                <div className="relative h-screen">
                    <p
                        className="mt-2 text-xs cursor-pointer"
                        onClick={() => setConfirmPage(false)}
                    >
                        {' '}
                        {'< '}Retour à la boutique B2B
                    </p>
                    <div className="flex mt-2 justify-between bg-white rounded-t-xl p-3">
                        <div className="">
                            <InputField
                                label="Date de livraison*"
                                id="deliveryDate"
                                type="date"
                                min={new Date().toISOString().split('T')[0]}
                                onChange={handleDateChange}
                                inputSmall={true}
                            />
                            {dateError && (
                                <p className="ml-2 text-red-500 text-xs">
                                    {dateError}.
                                </p>
                            )}
                        </div>
                        <div className="">
                            <InputField
                                label="Information livreur"
                                placeholder="Porte 2, code 7678"
                                id="customerNotes"
                                type="text"
                                onChange={handleChange}
                                inputSmall={true}
                            />
                        </div>
                    </div>
                    {/* Order Summary */}
                    <div
                        className={`flex mt-3 flex-col items-center h-[calc(84px+${
                            newOrder.items.length * 50
                        }px)] p-[10px]  bg-white rounded-xl`}
                    >
                        <p className="mt-1 text-xl text-[#1B234B] font-medium h-[24px]">
                            Récapitulatif de la commande
                        </p>
                        {newOrder.items.length > 0 ? (
                            <table className="mt-4 w-full">
                                <thead>
                                    <tr className="text-left h-[24px]">
                                        <th className="pl-[10px] w-[40%] text-sm text-gray-600 dark:text-white font-medium">
                                            Produit
                                        </th>
                                        <th className="pl-[10px] w-[20%] text-sm text-gray-600 dark:text-white font-medium">
                                            Quantité
                                        </th>
                                        <th className="pl-[10px] w-[25%] text-sm text-gray-600 dark:text-white font-medium">
                                            Prix
                                        </th>
                                        <th className="w-[15%]"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newOrder.items.map((item: any) => {
                                        return (
                                            <tr className="h-[50px] border-t border-[#E9EDF7]">
                                                <td className="mt-2 pl-[10px] flex gap-[5px] items-center text-sm text-gray-600 dark:text-white">
                                                    {item.name}
                                                </td>
                                                <td className="pl-[10px] text-sm text-gray-600 dark:text-white">
                                                    {item.quantity +
                                                        ' ' +
                                                        item.unity}
                                                </td>
                                                <td className="pl-[10px] text-sm font-bold">
                                                    {(
                                                        item.price *
                                                        item.quantity
                                                    ).toFixed(2)}{' '}
                                                    €
                                                </td>
                                                <td>
                                                    <ActionButton
                                                        extra="mx-auto block w-[34px] h-[34px] px-[0px] bg-red-400 hover:bg-red-500 active:bg-red-600"
                                                        value={
                                                            <RxCross2
                                                                size={25}
                                                            />
                                                        }
                                                        onClick={() => {
                                                            removeItem(
                                                                item.productId,
                                                            );
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <p className="mt-[12px] mb-[8px] text-sm text-gray-600">
                                Votre commande ne contient aucun produit
                            </p>
                        )}
                    </div>
                    <button
                        className={`flex mt-10 h-[34px] items-center justify-center rounded-xl mx-auto py-2 px-4 text-center text-sm font-medium text-white transition duration-200 ${
                            newOrder.items.length > 0 &&
                            newOrder.deliveryDate &&
                            (conditions && conditions.minimumOrderAmount
                                ? newOrder.totalPrice >=
                                  conditions.minimumOrderAmount
                                : true)
                                ? 'bg-toola-500 hover:bg-toola-600 active:bg-toola-700'
                                : 'bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default'
                        }`}
                        onClick={() => {
                            newOrder.items.length > 0 &&
                                newOrder.deliveryDate &&
                                sendOrder();
                        }}
                    >
                        Envoyer la commande
                    </button>
                </div>
            )}

            {confirmPage === false && (
                <>
                    {/* Products */}
                    <div className="mt-2 flex flex-col mb-[86px]">
                        <div className="grid grid-cols-2 gap-1.5 sm:grid-cols-3 lg:grid-cols-4">
                            {isFullyLoaded
                                ? autoOrderProducts
                                      .filter((p: any) =>
                                          searchProduct(p, search),
                                      )
                                      .filter((p: any) =>
                                          category === 'all' ||
                                          category === 'fav'
                                              ? true
                                              : p.category === category,
                                      )
                                      .filter((p: any) =>
                                          category === 'fav'
                                              ? getOrderCount(p.id) > 0
                                              : true,
                                      )
                                      .sort(
                                          (a: any, b: any) =>
                                              getOrderCount(b.id) -
                                              getOrderCount(a.id),
                                      )
                                      .map((product: any, index: number) => {
                                          return (
                                              <ProductCard
                                                  id={product.id}
                                                  key={index}
                                                  name={product.name}
                                                  imageUrl={product.imageUrl}
                                                  type={product.type}
                                                  alert={product.alert}
                                                  img={getIcon(
                                                      product.name,
                                                      'product',
                                                  )}
                                                  unity={product.unity}
                                                  price={product.price}
                                                  onAddProduct={addNewItem}
                                                  onQuantityChange={
                                                      handleQuantityChange
                                                  }
                                                  quantity={
                                                      newOrder.items.find(
                                                          (i: any) =>
                                                              i.productId ===
                                                              product.id,
                                                      )?.quantity
                                                  }
                                                  orderedCount={getOrderCount(
                                                      product.id,
                                                  )}
                                                  remove={removeItem}
                                                  order={newOrder}
                                                  onClick={() => {
                                                      setSelectedProductId(
                                                          product.id,
                                                      );
                                                      onOpenProductView();
                                                  }}
                                              />
                                          );
                                      })
                                : Array(productsPerLoad)
                                      .fill(<ProductLoadingCard />)
                                      .map((product: any) => {
                                          return product;
                                      })}
                        </div>
                        {isFullyLoaded && (
                            <div
                                className={`mt-[5px] ${
                                    autoOrderProducts.length >=
                                        autoOrderProductsMeta.total && 'hidden'
                                } w-full h-[58px] flex flex-col items-center justify-center`}
                            >
                                <div role="status">
                                    <svg
                                        aria-hidden="true"
                                        className="w-[30px] h-[30px] mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                    <span className="sr-only">
                                        Chargement des produits...
                                    </span>
                                </div>
                                <p className="mt-2 text-sm text-gray-600">
                                    Chargement des produits...
                                </p>
                            </div>
                        )}
                    </div>

                    {/* Bottom hover-menu */}
                    <div className="fixed bottom-0 left-0 mx-auto w-full px-[16px] h-[66px] bg-[#f7f6f7] border-t border-[#7090B0/0.25] rounded-[30px] flex justify-between items-center">
                        <div
                            className="flex gap-[10px] items-center"
                            onClick={() => {
                                setShowOrderSummary(!showOrderSummary);
                            }}
                        >
                            <div className="h-[30px] w-[30px] border border-[#7090B0/0.25] rounded-full flex items-center justify-center">
                                {!showOrderSummary ? (
                                    <FaChevronUp />
                                ) : (
                                    <FaChevronDown />
                                )}
                            </div>
                            <div className="flex flex-col">
                                <p className="text-sm text-gray-600">
                                    {newOrder.items.length} produit
                                    {(newOrder.items.length === 0 ||
                                        newOrder.items.length > 1) &&
                                        's'}
                                </p>
                                <div className="flex gap-[10px]">
                                    <p className="text-sm font-bold leading-6 text-navy-700 dark:text-white">
                                        Total HT
                                    </p>
                                    <h2 className="text-sm font-bold leading-6 text-[#0260CB] dark:text-white">
                                        {newOrder.totalPrice.toFixed(2)}€
                                    </h2>
                                </div>
                                {conditions && conditions.minimumOrderAmount ? (
                                    <p className="text-xs">
                                        Min. de {conditions.minimumOrderAmount}€
                                        de commande
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <button
                            className={`flex w-[180px] h-[40px] items-center justify-center rounded-xl py-2 text-center text-sm font-medium text-white transition duration-200 ${
                                newOrder.items.length > 0 &&
                                (conditions && conditions.minimumOrderAmount
                                    ? newOrder.totalPrice >=
                                      conditions.minimumOrderAmount
                                    : true)
                                    ? 'bg-toola-500 hover:bg-toola-600 active:bg-toola-700'
                                    : 'bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default'
                            }`}
                            onClick={() => {
                                newOrder.items.length > 0 &&
                                    (conditions && conditions.minimumOrderAmount
                                        ? newOrder.totalPrice >=
                                          conditions.minimumOrderAmount
                                        : true) &&
                                    setConfirmPage(true);
                            }}
                        >
                            Vérifier ma commande
                        </button>
                    </div>

                    {/* Order Summary */}
                    <div
                        className={`${
                            showOrderSummary ? 'flex' : 'hidden'
                        } flex-col items-center fixed bottom-[72px] left-0 h-[calc(84px+${
                            newOrder.items.length * 50
                        }px)] w-[calc(100%-20px)] p-[10px] mx-[10px] bg-white shadow-[0_0_4px_3px_rgba(163,174,288,0.25)] rounded-xl`}
                    >
                        <p className="mt-[10px] text-xl text-[#1B234B] font-medium h-[24px]">
                            Récapitulatif de la commande
                        </p>
                        {newOrder.items.length > 0 ? (
                            <table className="mt-[16px] w-full">
                                <thead>
                                    <tr className="text-left h-[24px]">
                                        <th className="pl-[10px] w-[40%] text-sm text-gray-600 dark:text-white font-medium">
                                            Produit
                                        </th>
                                        <th className="pl-[10px] w-[20%] text-sm text-gray-600 dark:text-white font-medium">
                                            Quantité
                                        </th>
                                        <th className="pl-[10px] w-[25%] text-sm text-gray-600 dark:text-white font-medium">
                                            Prix
                                        </th>
                                        <th className="w-[15%]"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newOrder.items.map((item: any) => {
                                        return (
                                            <tr className="h-[50px] border-t border-[#E9EDF7]">
                                                <td className="mt-2 pl-[10px] flex gap-[5px] items-center text-sm text-gray-600 dark:text-white">
                                                    {item.name}
                                                </td>
                                                <td className="pl-[10px] text-sm text-gray-600 dark:text-white">
                                                    {item.quantity +
                                                        ' ' +
                                                        item.unity}
                                                </td>
                                                <td className="pl-[10px] text-base text-[#0260CB] dark:text-white font-bold">
                                                    {(
                                                        item.price *
                                                        item.quantity
                                                    ).toFixed(2)}{' '}
                                                    €
                                                </td>
                                                <td>
                                                    <ActionButton
                                                        extra="mx-auto block w-[34px] h-[34px] px-[0px] bg-red-400 hover:bg-red-500 active:bg-red-600"
                                                        value={
                                                            <RxCross2
                                                                size={25}
                                                            />
                                                        }
                                                        onClick={() => {
                                                            removeItem(
                                                                item.productId,
                                                            );
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <p className="mt-[12px] mb-[8px] text-sm text-gray-600">
                                Votre commande ne contient aucun produit
                            </p>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default CommandInfo;
