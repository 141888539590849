import Card from 'components/card';
import InputField from 'components/fields/InputField';
import { useEffect, useState } from 'react';
import SettingsMenu from 'views/admin/main/account/component/SettingsMenu';
import MyUsersList from './components/DeliveryPersonsTable';
import LoadingTableLine from './components/loadingTableLine';
import {
    createCompanyUser,
    deleteCompanyUser,
    getCompanyAccounts,
} from 'interfaces/user';
import { toast } from 'react-toastify';

const Users = () => {
    const [isFullyLoaded, setIsFullyLoaded] = useState(true);
    const [account, setAccount] = useState<any>({});
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(false);

    const initAccounts = async () => {
        const myFullName = localStorage.getItem('name');
        const res = await getCompanyAccounts();

        console.log(myFullName);
        if (res) {
            setUsers(
                res.data.filter((user: any) => user.fullName !== myFullName),
            );
            setIsFullyLoaded(true);
        }
    };

    const handleChange = (e: any) => {
        setAccount((prevDeliveryPerson: any) => ({
            ...prevDeliveryPerson,
            [e.target.id]: e.target.value,
        }));
    };

    const handleAddAccount = async () => {
        const res = await createCompanyUser(account.email);

        if (res) {
            window.location.reload();
        } else {
            setError(true);
        }
    };

    const handleDeleteAccount = async (id: string) => {
        const res = await deleteCompanyUser(id);
        if (res) {
            window.location.reload();
        } else {
            toast.error("Erreur lors de la suppression de l'utilisateur");
        }
    };

    useEffect(() => {
        initAccounts();
    }, []);

    return (
        <div className="mt-2">
            <SettingsMenu path="/settings/users" />
            <Card extra="mt-4 pt-[10px] pl-[20px] pr-[20px] pb-[20px] border">
                <div className="h-full w-full pt-3">
                    <div>
                        <h4 className="text-lg font-medium">
                            Gestion des utilisateurs
                        </h4>
                        <div className="mt-5 mb-3 grid grid-cols-1 gap-3 xl:grid-cols-12">
                            <div className="col-span-3">
                                <InputField
                                    label="Email*"
                                    placeholder="nom.prenom@example.com"
                                    id="email"
                                    type="email"
                                    value={account.email}
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-3">
                                <button
                                    className={`sm:mt-6 linear col-span-3 flex items-center justify-center rounded-xl ${
                                        false
                                            ? 'bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default'
                                            : 'bg-toola-500 hover:bg-toola-600 active:bg-toola-700'
                                    } px-5 py-2 text-xs font-medium text-white`}
                                    onClick={handleAddAccount}
                                >
                                    Ajouter
                                </button>
                            </div>
                            {/* Permissions */}
                            {/* <div className="col-span-12">
                                <div className="ml-2 mt-2 flex gap-5">
                                    {[
                                        {
                                            display: 'Commandes & CRM',
                                            value: 'check',
                                        },
                                        { display: 'Stock', value: 'cash' },
                                        {
                                            display: 'Livraisons',
                                            value: 'transfer',
                                        },
                                        {
                                            display: 'Facturation',
                                            value: 'credit-card',
                                        },
                                    ].map((option, index) => (
                                        <div className="flex gap-2">
                                            <p className="text-xs mb-1">
                                                {option.display}
                                            </p>
                                            <input
                                                key={`checkbox-${index}`}
                                                id={option.value}
                                                type="checkbox"
                                                checked={true}
                                                onChange={() => {}}
                                                className="w-4 h-4 text-toola-500 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            ></input>
                                        </div>
                                    ))}
                                </div>
                            </div> */}
                        </div>
                        {error && (
                            <p className="ml-2 mb-5 text-sm">
                                Aucun utilisateur trouvé. Vous pouvez{' '}
                                <span
                                    className="hover:cursor-pointer text-toola-500"
                                    onClick={() =>
                                        window.open(
                                            'https://app.toola.eu/auth/register',
                                            '_bank',
                                        )
                                    }
                                >
                                    créer un nouveau compte
                                </span>{' '}
                                puis rajouter les permissions.
                            </p>
                        )}
                        {isFullyLoaded ? (
                            users.length > 0 ? (
                                <MyUsersList
                                    deliveryPersons={users}
                                    onDelete={(id: string) =>
                                        handleDeleteAccount(id)
                                    }
                                />
                            ) : (
                                <div className="rounded-xl border bg-white/0 outline-none">
                                    <p className="my-6 text-center">
                                        Vous n'avez pas encore créé
                                        d'utilisateur.
                                    </p>
                                </div>
                            )
                        ) : (
                            <div>
                                <MyUsersList
                                    deliveryPersons={users}
                                    onDelete={() => {}}
                                />
                                {Array(5).fill(<LoadingTableLine />)}
                            </div>
                        )}
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default Users;
