import {
    MdConveyorBelt,
    MdGridView,
    MdHome,
    MdOutlinePersonPin,
    MdOutlineViewKanban,
    MdPallet,
    MdPayment,
} from 'react-icons/md';

// Admin Imports
import DashboardsDefault from 'views/dashboards/default';

import AccountSettings from 'views/admin/main/account/settings';

import EcommerceOrderList from 'views/stock/stockList';
import SignInDefault from 'views/auth/signIn/SignInDefault';
import CreateProduct from 'views/stock/createProduct';
import UpdateIngredient from 'views/stock/updateIngredient';
import MoveStock from 'views/stock/moveStock';
import ViewProduction from 'views/production/viewProductions';
import Subscribe from 'views/subscribe';
import UpdateProduct from 'views/stock/updateProduct';
import Alerts from 'views/admin/main/account/courses';
import PlannedProductions from 'views/production/plannedProductions';
import HandleComp from 'views/stock/handleComp';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import StockMovement from 'views/stock/stockMovements';
import CRM from 'views/crm/list-customers';
import CreateCustomer from 'views/crm/createCustomer';
import EditCustomer from 'views/crm/editCustomer';
import ListOrders from 'views/orders/listOrders';
import CreateOrder from 'views/orders/createOrders';
import EditOrder from 'views/orders/editOrders';
import { GiCargoCrate } from 'react-icons/gi';
import ListDeliveries from 'views/delivery/listDeliveries';
import EditDeliveries from 'views/delivery/editDeliveries';
import AutomaticOrder from 'views/orders/automaticOrder/Earn';
import EditCustomerPricing from 'views/crm/editCustomerPricing';
import GenerateInvoice from 'views/invoicing/generate-invoice';
import ListInvoices from 'views/invoicing/list-invoices/ListInvoices';
import InvoiceFolder from 'views/invoicing/list-invoices';
import ManageDeliveryTour from 'views/delivery/manageDeliveryTours';
import ImportCustomer from 'views/crm/importCustomer';
import EditInformation from 'views/crm/editCustomer/editInformation';
import AccountingExport from 'views/invoicing/accounting-export';
import CommandPagePreference from 'views/admin/main/account/command-page';
import { RiTruckLine } from 'react-icons/ri';
import PluginsList from 'views/plugins';
import SlackPlugin from 'views/plugins/slack';
import Kanban from 'views/crm/kanban';
import CreateProspect from 'views/crm/createProspect';
import Modules from 'views/branding/modules';
import InConnect from 'views/admin/in-connect';
import CRMTasks from 'views/crm/tasks';
import Returnables from 'views/crm/list-retunable';
import PrepareProduction from 'views/production/prepareProductions';
import PricingSettings from 'views/pricing';
import ListRecurringOrders from 'views/recurringOrders/listOrders';
import CreateRecurringOrder from 'views/recurringOrders/createOrders';
import EditRecurringOrder from 'views/recurringOrders/editOrders';
import UnpaidManager from 'views/invoicing/unpaid-manager/UnpaidManager';
import B2bShopProductsDisplay from 'views/stock/B2bShopProductDisplay';
import ListTransactions from 'views/invoicing/list-transactions/ListTransactions';
import UnpaidFolder from 'views/invoicing/list-invoices/unpaidFolder';
import EditCustomerShopVisibility from 'views/crm/editCustomerShop';
import EditCustomerShopGlobaly from 'views/crm/editShopGlobaly';
import ListCustomersVisibility from 'views/crm/listCustomersVisibility';
import Users from 'views/users';
import DashboardV2 from 'views/dashboards/v2';

const routes = [
    // --- Dashboards ---
    {
        name: 'Dashboard',
        path: '/dashboard',
        layout: '',
        icon: <MdHome className="text-inherit h-8 w-8" />,
        component: <DashboardsDefault />,
    },
    {
        name: 'Dashboard',
        path: '/dashboard/V2',
        layout: '',
        menu: false,
        icon: <MdHome className="text-inherit h-8 w-8" />,
        component: <DashboardV2 />,
    },
    {
        name: 'Commandes',
        path: '/orders',
        layout: '',
        icon: <MdGridView className="text-inherit h-8 w-8" />,
        component: <ListOrders />,
    },
    {
        name: 'Commandes récurrentes',
        path: '/recurring-orders',
        layout: '',
        menu: false,
        icon: <MdGridView className="text-inherit h-8 w-8" />,
        component: <ListRecurringOrders />,
    },
    {
        name: 'Clients',
        path: '/customers',
        layout: '',
        icon: <MdOutlinePersonPin className="text-inherit h-8 w-8" />,
        component: <CRM />,
    },
    {
        name: 'Clients',
        path: '/customers-visibility',
        layout: '',
        menu: false,
        icon: <MdOutlinePersonPin className="text-inherit h-8 w-8" />,
        component: <ListCustomersVisibility />,
    },
    {
        name: 'Modules',
        path: '/modules',
        layout: '',
        menu: false,
        icon: <MdOutlinePersonPin className="text-inherit h-8 w-8" />,
        component: <Modules />,
    },
    {
        name: 'Kanban',
        path: '/kanban',
        layout: '',
        menu: false,
        icon: <MdOutlineViewKanban className="text-inherit h-8 w-8" />,
        component: <Kanban />,
    },
    {
        name: 'Affichage boutique',
        path: '/b2b-shop/display',
        layout: '',
        menu: false,
        icon: <MdOutlineViewKanban className="text-inherit h-8 w-8" />,
        component: <B2bShopProductsDisplay />,
    },
    {
        name: 'Stock',
        path: '/stock',
        layout: '/',
        icon: <MdPallet className="text-inherit h-8 w-8" />,
        collapse: true,
        items: [
            {
                name: 'Mon stock',
                layout: '',
                path: '/stock/stock-list',
                component: <EcommerceOrderList />,
            },
            {
                name: 'Mouvements',
                path: '/stock/movements',
                layout: '',
                //icon: <MdOutlineMoveDown className="text-inherit h-8 w-8" />,
                component: <StockMovement />,
            },
            {
                name: 'Consignes',
                path: '/returnables',
                layout: '',
                //icon: <MdOutlineMoveDown className="text-inherit h-8 w-8" />,
                component: <Returnables />,
            },
            {
                name: 'Production',
                path: '/stock/production',
                layout: '',
                //icon: <MdConveyorBelt className="text-inherit h-8 w-8" />,
                component: <ViewProduction />,
            },
            {
                name: 'Préparation',
                path: '/stock/prepare-production',
                layout: '',
                menu: false,
                //icon: <MdConveyorBelt className="text-inherit h-8 w-8" />,
                component: <PrepareProduction />,
            },
        ],
    },
    {
        name: 'Livraison',
        path: '/delivery/delivery-list',
        layout: '',
        component: <ListDeliveries isExternalPage={false} />,
        icon: <RiTruckLine className="text-inherit h-8 w-8" />,
        menu: true,
    },
    {
        name: 'Livraison',
        path: '/delivery/:date/:deliveryId',
        layout: '',
        menu: false,
        icon: <GiCargoCrate className="text-inherit h-8 w-8" />,
        component: <EditDeliveries isExternalPage={false} />,
    },
    {
        name: 'Facturation',
        path: '/generate-invoice',
        layout: '',
        menu: false,
        icon: <MdGridView className="text-inherit h-8 w-8" />,
        component: <GenerateInvoice />,
    },
    // {
    //     name: 'Facturation',
    //     path: '/invoices',
    //     layout: '',
    //     menu: true,
    //     icon: <MdPayment className="text-inherit h-8 w-8" />,
    //     component: <InvoiceFolder />,
    // },
    {
        name: 'Facturation',
        path: '/invoices',
        layout: '/',
        icon: <MdPayment className="text-inherit h-8 w-8" />,
        collapse: true,
        items: [
            {
                name: 'Factures',
                layout: '',
                path: '/invoices/invoices-list',
                component: <InvoiceFolder />,
            },
            {
                name: 'Règlements',
                layout: '',
                path: '/invoices/transactions',
                component: <ListTransactions />,
            },
            {
                name: 'Impayés',
                layout: '',
                path: '/invoices/unpaid',
                component: <UnpaidFolder />,
            },
        ],
    },
    {
        name: 'Facturation',
        path: '/invoices/:month/:year',
        layout: '',
        menu: false,
        icon: <MdGridView className="text-inherit h-8 w-8" />,
        component: <ListInvoices />,
    },
    {
        name: 'Règlements',
        path: '/transactions',
        layout: '',
        menu: false,
        icon: <MdGridView className="text-inherit h-8 w-8" />,
        component: <ListTransactions />,
    },
    {
        name: 'Facturation',
        path: '/unpaid-manager/:month/:year',
        layout: '',
        menu: false,
        icon: <MdGridView className="text-inherit h-8 w-8" />,
        component: <UnpaidManager />,
    },
    {
        name: 'Mes alertes',
        path: '/alerts',
        layout: '',
        icon: <MdPallet className="text-inherit h-8 w-8" />,
        component: <Alerts />,
        menu: false,
    },
    {
        name: 'Subscribe',
        path: '/subscribe',
        layout: '',
        menu: false,
        icon: <MdPallet className="text-inherit h-8 w-8" />,
        component: <Subscribe />,
    },
    {
        name: 'Mouvement',
        path: '/move-stock/:id',
        layout: '',
        menu: false,
        icon: <MdPallet className="text-inherit h-8 w-8" />,
        component: <MoveStock />,
    },
    {
        name: 'Client',
        path: '/customer/:id',
        layout: '',
        menu: false,
        icon: <MdPallet className="text-inherit h-8 w-8" />,
        component: <EditCustomer />,
    },
    {
        name: 'Edition client',
        path: '/edit-customer/:id',
        layout: '',
        menu: false,
        icon: <MdPallet className="text-inherit h-8 w-8" />,
        component: <EditInformation />,
    },
    {
        name: 'Client',
        path: '/customer-pricing/:id',
        layout: '',
        menu: false,
        icon: <MdPallet className="text-inherit h-8 w-8" />,
        component: <EditCustomerPricing />,
    },
    {
        name: 'Visibilité page B2B',
        path: '/b2b-display/:id',
        layout: '',
        menu: false,
        component: <EditCustomerShopVisibility />,
    },
    {
        name: 'Gestion des produits',
        path: '/b2b-display-global',
        layout: '',
        menu: false,
        component: <EditCustomerShopGlobaly />,
    },
    {
        name: 'Commande',
        path: '/order/:id',
        layout: '',
        menu: false,
        component: <EditOrder />,
    },
    {
        name: 'Commande',
        path: '/recurring-order/:id',
        layout: '',
        menu: false,
        component: <EditRecurringOrder />,
    },
    {
        name: 'Commande',
        path: '/setup-automatic-order',
        layout: '',
        menu: false,
        component: <AutomaticOrder />,
    },
    {
        name: 'Ingredient',
        path: '/update-ingredient/:id',
        layout: '',
        menu: false,
        component: <UpdateIngredient />,
    },
    {
        name: 'Produit',
        path: '/update-product/:id',
        layout: '',
        menu: false,
        component: <UpdateProduct />,
    },
    {
        name: 'Produit',
        path: '/handle-comp/:id',
        layout: '',
        menu: false,
        component: <HandleComp />,
    },
    {
        name: 'Produit',
        path: '/create-product',
        layout: '',
        menu: false,
        component: <CreateProduct />,
    },
    {
        name: 'Client',
        path: '/create-customer',
        layout: '',
        menu: false,
        component: <CreateCustomer />,
    },
    {
        name: 'Client',
        path: '/create-prospect',
        layout: '',
        menu: false,
        component: <CreateProspect />,
    },
    {
        name: 'Client',
        path: '/import-customer',
        layout: '',
        menu: false,
        component: <ImportCustomer />,
    },
    {
        name: 'Commande',
        path: '/create-order',
        layout: '',
        menu: false,
        component: <CreateOrder />,
    },
    {
        name: 'Commande',
        path: '/create-recurring-order',
        layout: '',
        menu: false,
        component: <CreateRecurringOrder />,
    },
    {
        name: 'Planification',
        path: '/planned-production',
        layout: '',
        menu: false,
        icon: <MdConveyorBelt className="text-inherit h-8 w-8" />,
        component: <PlannedProductions />,
    },
    {
        name: 'Export comptable',
        path: '/accounting-export',
        layout: '',
        menu: false,
        icon: <MdPayment className="text-inherit h-8 w-8" />,
        component: <AccountingExport />,
    },
    {
        name: 'Default',
        menu: false,
        layout: '/auth',
        path: '/login',
        component: <SignInDefault />,
    },
    {
        name: 'Default',
        menu: false,
        layout: '/auth',
        path: '/register',
        component: <SignUpDefault />,
    },
    {
        name: 'Mon compte',
        layout: '',
        path: '/settings',
        menu: false,
        component: <AccountSettings />,
    },
    {
        name: 'Boutique B2B',
        layout: '',
        path: '/settings/command-page',
        menu: false,
        component: <CommandPagePreference />,
    },
    {
        name: 'Options de distribution',
        path: '/settings/deliveries',
        layout: '',
        menu: false,
        component: <ManageDeliveryTour />,
    },
    {
        name: 'Extensions',
        path: '/settings/plugins',
        layout: '',
        menu: false,
        component: <PluginsList />,
    },
    {
        name: 'Utilisateurs',
        path: '/settings/users',
        layout: '',
        menu: false,
        component: <Users />,
    },
    {
        name: 'Tarifs',
        path: '/group-pricing',
        layout: '',
        menu: false,
        component: <PricingSettings />,
    },
    {
        name: 'Slack',
        path: '/plugins/slack',
        layout: '',
        menu: false,
        component: <SlackPlugin />,
    },
    {
        name: 'Tâches',
        path: '/tasks',
        layout: '',
        menu: false,
        component: <CRMTasks />,
    },
    {
        name: '',
        path: '/in-connect',
        layout: '',
        menu: false,
        icon: <MdOutlinePersonPin className="text-inherit h-8 w-8" />,
        component: <InConnect />,
    },
];
export default routes;
