import Card from 'components/card';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { transformMongoAggregateToApexChart } from 'utils/analytics';

function ChartBar3Period(props: {
    title: string;
    data: any;
    calculateCumulativeSum: boolean;
}) {
    const { title, data, calculateCumulativeSum } = props;

    const [state, setState] = useState(null);
    const [period, setPeriod] = useState('monthly');

    useEffect(() => {
        if (!data || !data.length) return;
        setState(
            transformMongoAggregateToApexChart(
                data,
                period,
                calculateCumulativeSum,
            ),
        );
    }, [data, period]);

    return (
        <Card extra={'relative p-5 text-dm lg:h-full max-h-[320px] border'}>
            <h4 className="ml-px text-base font-bold text-navy-700 dark:text-white">
                {title}
            </h4>
            {data &&
                data.length > 1 &&
                state &&
                (() => {
                    const seriesLength = state.series[0].data.length;
                    const previous = state.series[0].data[seriesLength - 2];
                    const current = state.series[0].data[seriesLength - 1];
                    const growthRate = ((current - previous) / previous) * 100;
                    const isPositive = growthRate >= 0;
                    const periods: any = {
                        monthly: 'ce mois',
                        quarterly: 'ce trimestre',
                        yearly: 'cette année',
                    };

                    return (
                        <p
                            className={`mt-1 text-[13px] ${
                                isPositive ? 'text-green-500' : 'text-red-500'
                            }`}
                        >
                            {isPositive && '+'}
                            {growthRate.toFixed(2)}% {periods[period]}
                        </p>
                    );
                })()}
            <div className="absolute right-4 top-4">
                <div className="flex mt-2 gap-4">
                    <p
                        className={`text-sm ${
                            period === 'monthly'
                                ? 'text-toola-500 underline'
                                : ''
                        } cursor-pointer`}
                        onClick={() => setPeriod('monthly')}
                    >
                        Mensuel
                    </p>
                    <p
                        className={`text-sm ${
                            period === 'quarterly'
                                ? 'text-toola-500 underline'
                                : ''
                        } cursor-pointer`}
                        onClick={() => setPeriod('quarterly')}
                    >
                        Trimestriel
                    </p>
                    <p
                        className={`text-sm ${
                            period === 'yearly'
                                ? 'text-toola-500 underline'
                                : ''
                        } cursor-pointer`}
                        onClick={() => setPeriod('yearly')}
                    >
                        Annuel
                    </p>
                </div>
            </div>
            {state ? (
                <div id="chart">
                    <ReactApexChart
                        options={state.options as any}
                        series={state.series}
                        type="bar"
                        height={250}
                    />
                </div>
            ) : (
                <div className="flex justify-center items-center h-96">
                    <p className="text-lg text-gray-500">Chargement...</p>
                </div>
            )}
            <div id="html-dist"></div>
        </Card>
    );
}

export default ChartBar3Period;
