import InputField from 'components/fields/InputField';
import SearchSelector from 'components/fields/SearchSelector';
import { getTotalHT } from '../utils';
import { useState } from 'react';

const OrderTable = (props: {
    order: any;
    handleQtyChange: any;
    handlePriceChange: any;
    handleDiscountChange: any;
    handleLotNumberChange: any;
    handleDiscountTypeChange: any;
    handleCommentChange: any;
    onDelete: any;
}) => {
    const {
        order,
        handleQtyChange,
        handlePriceChange,
        handleDiscountChange,
        handleDiscountTypeChange,
        handleCommentChange,
        handleLotNumberChange,
        onDelete,
    } = props;

    const [isCommentSectionOpen, setIsCommentSectionOpen] = useState<any>({});

    const getMarginString = (item: any, sellPrice: number) => {
        let totalCost = item.totalPurchasePrice * item.quantity;

        if (!totalCost) return null;
        return `Marge : ${(sellPrice - totalCost)
            .toFixed(2)
            .replace(/\.?0+$/, '')} € (${parseFloat(
            (((sellPrice - totalCost) / sellPrice) * 100).toFixed(2),
        )} %)`;
    };

    return (
        <table className="w-full">
            <thead className="hidden sm:contents">
                <tr key={0} className="!border-px !border-gray-400">
                    <th
                        key={1}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Produit
                        </p>
                    </th>
                    <th
                        key={2}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Quantité
                        </p>
                    </th>
                    <th
                        key={3}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Prix unitaire HT
                        </p>
                    </th>
                    <th
                        key={4}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Type de remise
                        </p>
                    </th>
                    <th
                        key={5}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Remise
                        </p>
                    </th>
                    <th
                        key={6}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Prix total HT
                        </p>
                    </th>
                    <th
                        key={7}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Actions
                        </p>
                    </th>
                </tr>
            </thead>
            <tbody>
                {order.items.map((item: any, index: number) => (
                    <>
                        <tr key={`${item.name}-${index}`}>
                            <td
                                key={`${item.name}-${index}-product`}
                                className="hidden sm:table-cell border-b text-sm dark:border-white/10 px-[10px] py-[10px]"
                            >
                                <div>
                                    <p className="text-sm font-medium text-navy-700 dark:text-white xl:leading-3">
                                        {item.name}
                                    </p>
                                    <div className="flex gap-[5px] items-center">
                                        <p className="font-base mt-[2px] text-gray-600">
                                            {item.ref}
                                        </p>
                                        <input
                                            type="text"
                                            className="w-[80px] h-5 text-xs px-2 border rounded-xl py-2 focus:outline-none text-gray-600 focus:ring focus:border-blue-300 transition-colors duration-300"
                                            value={item.lotNumber}
                                            placeholder="N° de lot"
                                            onChange={(e) =>
                                                handleLotNumberChange(
                                                    item,
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </div>
                                    {isCommentSectionOpen &&
                                    isCommentSectionOpen[
                                        `${item.name}-${item.ref}`
                                    ] ? (
                                        <>
                                            <textarea
                                                id="textInput"
                                                className="w-full h-10 px-4 py-2 border rounded-xl focus:outline-none focus:ring focus:border-blue-300 transition-colors duration-300"
                                                placeholder="Ajouter un commentaire ici..."
                                                onChange={(e) =>
                                                    handleCommentChange(
                                                        item,
                                                        e.target.value,
                                                    )
                                                }
                                            ></textarea>
                                            <p
                                                className="ml-1 text-xs text-toola-500 hover:cursor-pointer"
                                                onClick={() => {
                                                    setIsCommentSectionOpen({
                                                        ...isCommentSectionOpen,
                                                        [`${item.name}-${item.ref}`]:
                                                            false,
                                                    });
                                                }}
                                            >
                                                Retirer le commentaire
                                            </p>
                                        </>
                                    ) : (
                                        <p
                                            className="text-xs text-toola-500 hover:cursor-pointer"
                                            onClick={() => {
                                                setIsCommentSectionOpen({
                                                    ...isCommentSectionOpen,
                                                    [`${item.name}-${item.ref}`]:
                                                        true,
                                                });
                                            }}
                                        >
                                            Ajouter un commentaire
                                        </p>
                                    )}
                                </div>
                            </td>
                            <td
                                key={`${item.name}-${index}-quantity`}
                                className="hidden sm:table-cell border-b text-sm dark:border-white/10 px-[10px] py-[10px]"
                            >
                                <div className="flex gap-[5px] items-center">
                                    <InputField
                                        key={`${item.name}-${index}-qty`}
                                        extra="w-[70px]"
                                        placeholder="Quantité"
                                        id={item.name + '_qty'}
                                        type="number"
                                        value={item.quantity}
                                        onChange={(e: any) => {
                                            handleQtyChange(
                                                item,
                                                e.target.value,
                                            );
                                        }}
                                        inputSmall={true}
                                    />
                                    <p className="mt-1 text-sm font-bold text-navy-700 dark:text-white">
                                        {item.unity}
                                    </p>
                                </div>
                            </td>
                            <td
                                key={`${item.name}-${index}-price`}
                                className="hidden sm:table-cell border-b text-sm dark:border-white/10 px-[10px] py-[10px]"
                            >
                                <div className="">
                                    <div className="flex gap-[5px] items-center">
                                        <InputField
                                            key={`${item.name}-${index}-qty`}
                                            extra="w-[90px]"
                                            placeholder="Prix unitaire"
                                            id={item.name + '_up'}
                                            type="number"
                                            value={item.price}
                                            onChange={(e: any) => {
                                                handlePriceChange(
                                                    item,
                                                    e.target.value,
                                                );
                                            }}
                                            inputSmall={true}
                                        />
                                        <p className="mt-1 text-sm font-bold text-navy-700 dark:text-white">
                                            €
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td
                                key={`${item.name}-${index}-total-price`}
                                className="hidden sm:table-cell border-b text-sm dark:border-white/10 px-[10px] py-[10px]"
                            >
                                <div className="flex mt-2 gap-[5px] items-center w-[100px]">
                                    <SearchSelector
                                        displayKey="name"
                                        value={item.discountType}
                                        options={[
                                            {
                                                name: 'Aucune',
                                                _id: 'none',
                                            },
                                            {
                                                name: '%',
                                                _id: 'percent',
                                            },
                                            {
                                                name: '€',
                                                _id: 'value',
                                            },
                                        ]}
                                        onSelect={(e: any) =>
                                            handleDiscountTypeChange(
                                                item,
                                                e._id,
                                            )
                                        }
                                        inputSmall={true}
                                    />
                                </div>
                            </td>
                            <td
                                key={`${item.name}-${index}-total-price`}
                                className="hidden sm:table-cell border-b text-sm dark:border-white/10 px-[10px] py-[10px]"
                            >
                                <div className="flex gap-[5px] items-center">
                                    <InputField
                                        key={`${item.name}-${index}-discount`}
                                        extra="w-[70px]"
                                        placeholder="0"
                                        id={item.name + '_discount'}
                                        type="number"
                                        value={item.discountValue}
                                        onChange={(e: any) =>
                                            handleDiscountChange(
                                                item,
                                                e.target.value,
                                            )
                                        }
                                        inputSmall={true}
                                    />
                                    <p className="mt-1 text-sm font-bold text-navy-700 dark:text-white">
                                        {item.discountType === 'percent'
                                            ? '%'
                                            : '€'}
                                    </p>
                                </div>
                            </td>
                            <td
                                key={`${item.name}-${index}-total-price`}
                                className="hidden sm:table-cell border-b text-sm dark:border-white/10 px-[10px] py-[10px]"
                            >
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {getTotalHT([item]).toFixed(2)} €
                                </p>
                                <p className="text-xs text-navy-700 dark:text-white">
                                    {getMarginString(item, getTotalHT([item]))}{' '}
                                    €
                                </p>
                            </td>
                            <td
                                key={`${item.name}-${index}-mobile`}
                                className="sm:hidden border-b text-sm dark:border-white/10 py-[10px] w-full"
                            >
                                <div>
                                    <div className="flex gap-[5px] items-center">
                                        <InputField
                                            key={`${item.name}-${index}-qty-m`}
                                            extra="w-[50px]"
                                            inputSmall={true}
                                            placeholder="Quantité"
                                            id={item.name + '_qty_m'}
                                            type="number"
                                            value={item.quantity}
                                            onChange={(e: any) => {
                                                handleQtyChange(
                                                    item,
                                                    e.target.value,
                                                );
                                            }}
                                        />
                                        <p className="text-base font-bold text-navy-700 dark:text-white xl:leading-3">
                                            {item.unity} {item.name}
                                        </p>
                                    </div>
                                    <p className="font-base text-gray-600">
                                        {item.ref}
                                    </p>
                                    <div className="flex gap-[5px] items-center">
                                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                                            Prix unitaire :
                                        </p>
                                        <InputField
                                            key={`${item.name}-${index}-price-m`}
                                            extra="w-[50px]"
                                            inputSmall={true}
                                            placeholder="Prix unitaire"
                                            id={item.name + '_up'}
                                            type="number"
                                            value={item.price}
                                            onChange={(e: any) => {
                                                handlePriceChange(
                                                    item,
                                                    e.target.value,
                                                );
                                            }}
                                        />
                                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                                            €
                                        </p>
                                    </div>
                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                        Total : {getTotalHT([item]).toFixed(2)}{' '}
                                        €
                                    </p>
                                </div>
                            </td>
                            <td
                                key={`${item.name}-${index}-actions`}
                                className="border-b text-sm dark:border-white/10 px-[10px] py-[10px]"
                            >
                                <button
                                    className="linear col-span-3 flex items-center justify-center rounded-xl bg-red-400 px-3 py-1 text-sm font-medium text-white hover:bg-red-500 active:bg-red-600"
                                    onClick={() => onDelete(item)}
                                >
                                    Retirer
                                </button>
                            </td>
                        </tr>
                    </>
                ))}
            </tbody>
        </table>
    );
};

export default OrderTable;
