import Card from 'components/card';
import { MdChecklist, MdSettings } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const CustomerCard = (props: {
    image: string;
    company: string;
    displayName?: string;
    email: string;
    phone: string;
    download?: string;
    commands: string | number;
    extra?: string;
    id: string;
    customerId: string;
    magicLink: string;
}) => {
    const { company, extra, id, customerId, displayName } = props;
    const nav = useNavigate();
    return (
        <Card
            extra={`flex flex-col w-full h-[180px] !p-4 3xl:p-![18px] bg-white border border-[#7090B0]/[0.25] ${extra}`}
        >
            <div className="h-full w-full">
                <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                    <div className="mb-2">
                        {displayName ? (
                            <div>
                                <p className="text-md font-medium text-navy-700 dark:text-white">
                                    {' '}
                                    {displayName}
                                </p>
                                <p className="text-xs text-navy-700 dark:text-white">
                                    {' '}
                                    {company}
                                </p>
                            </div>
                        ) : (
                            <p className="text-lg font-bold text-navy-700 dark:text-white">
                                {' '}
                                {company}
                                {displayName}
                            </p>
                        )}
                        <p className="text-sm text-navy-700 dark:text-white">
                            {'#'}
                            {customerId}{' '}
                        </p>
                        <div className="mt-2 flex mx-auto">
                            <div className="mt-2 grid grid-cols-1 gap-2 justify-center">
                                <button
                                    className="linear flex cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-1 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                    onClick={() =>
                                        nav(`/customer-pricing/${id}`)
                                    }
                                >
                                    <MdSettings className="h-3 w-3 mr-1" />
                                    Gérer les prix
                                </button>
                                <button
                                    className="linear flex cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-1 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                    onClick={() => nav(`/b2b-display/${id}`)}
                                >
                                    <MdChecklist className="h-3 w-3 mr-1" />
                                    Visibilité Boutique B2B
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default CustomerCard;
