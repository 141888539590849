import { useEffect, useMemo, useState } from 'react';
import {
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';
import { toast } from 'react-toastify';

import ColumnContainer from './ColumnContainer';
import TaskCard from './TaskCard';
import { getKanban, updateKanban } from 'interfaces/kanban';
import SaveStatus from 'components/actions/SaveStatus';
import CRMMenu from 'views/crm/list-customers/components/CRMMenu';

function KanbanBoard(props: {
    cards: any;
    onColumnDrop: any;
    onDragEnded: any;
    onCardClick?: any;
}) {
    const { cards, onColumnDrop, onCardClick, onDragEnded } = props;
    const [columns, setColumns] = useState<any[]>([]);
    const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);
    const [saveStatus, setSaveStatus] = useState('none');

    const [tasks, setTasks] = useState<any[]>(cards);

    const [activeColumn, setActiveColumn] = useState<any | null>(null);

    const [activeTask, setActiveTask] = useState<any | null>(null);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
    );

    const initKanban = async () => {
        const kanban = await getKanban();
        console.log('kanban', kanban);
        if (kanban) {
            setColumns(kanban.columns);
        }
    };

    useEffect(() => {
        initKanban();
    }, []);

    useEffect(() => {
        if (columns.length === 0) return;
        setSaveStatus('saving');
        const kanbanColumns = columns.map((col: any, index: number) => {
            return {
                index: index,
                id: col.id,
                title: col.title,
            };
        });
        const delayDebounceFn = setTimeout(async () => {
            const res = await updateKanban({ columns: kanbanColumns });
            if (res) setSaveStatus('saved');
            else setSaveStatus('error');
        }, 2000);

        return () => clearTimeout(delayDebounceFn);
    }, [columns]);

    return (
        <>
            <div className="flex justify-between">
                <CRMMenu path="/kanban" />
                <SaveStatus status={saveStatus} />
            </div>
            <div
                className="
        flex
        mt-4
        w-full
        overflow-x-auto
        overflow-y-hidden
    "
            >
                <DndContext
                    sensors={sensors}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDragOver={onDragOver}
                >
                    <div className="flex gap-4">
                        <div className="flex gap-4">
                            <SortableContext items={columnsId}>
                                {columns.map((col) => (
                                    <ColumnContainer
                                        key={col.id}
                                        column={col}
                                        deleteColumn={deleteColumn}
                                        updateColumn={updateColumn}
                                        createTask={createTask}
                                        deleteTask={deleteTask}
                                        updateTask={updateTask}
                                        onCardClick={onCardClick}
                                        tasks={tasks.filter(
                                            (task) => task.columnId === col.id,
                                        )}
                                    />
                                ))}
                            </SortableContext>
                        </div>
                        <button
                            onClick={() => {
                                createNewColumn();
                            }}
                            className="
                            mt-1
                            h-[50px]
                            w-[100px]
                            text-sm
                            cursor-pointer
                            rounded-lg
                            bg-mainBackgroundColor
                            border-2
                            border-columnBackgroundColor
                            ring-rose-500
                            hover:ring-2
                            flex
                            gap-2
                            "
                        >
                            + Ajouter une colonne
                        </button>
                    </div>

                    {createPortal(
                        <DragOverlay>
                            {activeColumn && (
                                <ColumnContainer
                                    column={activeColumn}
                                    deleteColumn={deleteColumn}
                                    updateColumn={updateColumn}
                                    createTask={createTask}
                                    deleteTask={deleteTask}
                                    updateTask={updateTask}
                                    tasks={tasks.filter(
                                        (task) =>
                                            task.columnId === activeColumn.id,
                                    )}
                                />
                            )}
                            {activeTask && (
                                <TaskCard
                                    task={activeTask}
                                    deleteTask={deleteTask}
                                    updateTask={updateTask}
                                />
                            )}
                        </DragOverlay>,
                        document.body,
                    )}
                </DndContext>
            </div>
        </>
    );

    function createTask(columnId: any) {
        const newTask: any = {
            id: generateId(),
            columnId,
            content: `Task ${tasks.length + 1}`,
        };

        setTasks([...tasks, newTask]);
    }

    function deleteTask(id: any) {
        const newTasks = tasks.filter((task) => task.id !== id);
        setTasks(newTasks);
    }

    function updateTask(id: any, content: string) {
        const newTasks = tasks.map((task) => {
            if (task.id !== id) return task;
            return { ...task, content };
        });

        setTasks(newTasks);
    }

    function createNewColumn() {
        const columnToAdd: any = {
            id: generateId().toString(),
            title: `Column ${columns.length + 1}`,
        };

        setColumns([...columns, columnToAdd]);
    }

    function deleteColumn(id: any) {
        const tasksInColumn = tasks.filter((task) => task.columnId === id);

        if (tasksInColumn.length > 0) {
            toast.error(
                'Vous ne pouvez pas supprimer une colonne avec des tâches',
            );
            return;
        }

        const filteredColumns = columns.filter((col) => col.id !== id);
        setColumns(filteredColumns);

        const newTasks = tasks.filter((t) => t.columnId !== id);
        setTasks(newTasks);
    }

    function updateColumn(id: any, title: string) {
        const newColumns = columns.map((col) => {
            if (col.id !== id) return col;
            return { ...col, title };
        });

        setColumns(newColumns);
    }

    function onDragStart(event: DragStartEvent) {
        if (event.active.data.current?.type === 'Column') {
            setActiveColumn(event.active.data.current.column);
            return;
        }

        if (event.active.data.current?.type === 'Task') {
            setActiveTask(event.active.data.current.task);
            return;
        }
    }

    function onDragEnd(event: DragEndEvent) {
        setActiveColumn(null);
        setActiveTask(null);

        // Trigger customer conversion pop-up if needed
        if (event.active.data.current?.type === 'Task') onDragEnded();

        const { active, over } = event;
        if (!over) return;

        const activeId = active.id;
        const overId = over.id;

        if (activeId === overId) return;

        const isActiveAColumn = active.data.current?.type === 'Column';
        if (!isActiveAColumn) return;

        setColumns((columns) => {
            const activeColumnIndex = columns.findIndex(
                (col) => col.id === activeId,
            );

            const overColumnIndex = columns.findIndex(
                (col) => col.id === overId,
            );
            const result = arrayMove(
                columns,
                activeColumnIndex,
                overColumnIndex,
            );
            return result;
        });
    }

    function onDragOver(event: DragOverEvent) {
        const { active, over } = event;
        if (!over) return;

        const activeId = active.id;
        const overId = over.id;

        if (activeId === overId) return;

        const isActiveATask = active.data.current?.type === 'Task';
        const isOverATask = over.data.current?.type === 'Task';

        if (!isActiveATask) return;

        // Im dropping a Task over another Task
        if (isActiveATask && isOverATask) {
            setTasks((tasks) => {
                const activeIndex = tasks.findIndex((t) => t.id === activeId);
                const overIndex = tasks.findIndex((t) => t.id === overId);

                if (tasks[activeIndex].columnId !== tasks[overIndex].columnId) {
                    tasks[activeIndex].columnId = tasks[overIndex].columnId;
                    const move = arrayMove(tasks, activeIndex, overIndex - 1);
                    onColumnDrop(tasks[activeIndex]);
                    return move;
                }
                const move = arrayMove(tasks, activeIndex, overIndex);
                onColumnDrop(tasks[activeIndex]);
                return move;
            });
        }

        const isOverAColumn = over.data.current?.type === 'Column';

        // Im dropping a Task over a column
        if (isActiveATask && isOverAColumn) {
            setTasks((tasks) => {
                const activeIndex = tasks.findIndex((t) => t.id === activeId);

                tasks[activeIndex].columnId = overId;
                console.log('DROPPING TASK OVER COLUMN', { activeIndex });
                console.log(tasks[activeIndex]);
                onColumnDrop(tasks[activeIndex]);
                return arrayMove(tasks, activeIndex, activeIndex);
            });
        }
    }
}

function generateId() {
    /* Generate a random number between 0 and 10000 */
    return Math.floor(Math.random() * 10001);
}

export default KanbanBoard;
