import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Card from 'components/card';
import InputField from 'components/fields/InputField';
import SearchSelector from 'components/fields/SearchSelector';
import { getCustomerLastOrder, searchCustomers } from 'interfaces/customer';
import { createOrder } from 'interfaces/order';
import { getProducts } from 'interfaces/products';
import OrderTable from './components/OrderTable';
import { getTotalHT, getTotalTTC } from './utils';
import { BsStars } from 'react-icons/bs';
import { calculateTotalPurchasePrice } from 'utils/margin';
import { productsWithDisplayKey } from './utils/display';
import CustomerSearchBar from 'components/fields/CustomerSearchBar';
import { useDisclosure } from '@chakra-ui/hooks';
import AddProductsModal from './components/AddProductModal';
import { HiCursorClick } from 'react-icons/hi';
import { MdAddBox } from 'react-icons/md';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { getDeliveryTours } from 'interfaces/deliveryTour';

const CreateOrder = () => {
    const navigate = useNavigate();
    const [customers, setCustomers] = useState<any>([]);
    const [warning, setWarning] = useState<any>(null);
    const [products, setProducts] = useState<any>([]);
    const [defaultDeliveryTour, setDefaultDeliveryTour] = useState<any>(true);
    const [deliveryTours, setDeliveryTours] = useState<any>([]);
    const [customerSearchValue, setCustomerSearchValue] = useState<string>('');
    const [customerLoading, setCustomerLoading] = useState<boolean>(true);
    const [IARefreshCounter, setIARefreshCounter] = useState<number>(0);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [customerLastOrder, setCustomerLastOrder] = useState<any>(null);
    const [newOrder, setNewOrder] = useState<any>({
        items: [],
        totalPrice: 0,
        status: 'pending',
        customerId: null,
    });

    const handleChange = (e: any) => {
        setNewOrder((prevCustomer: any) => ({
            ...prevCustomer,
            [e.target.id]: e.target.value,
        }));
    };

    const updateQuantity = (product: any, value: number) => {
        newOrder.items.find((item: any) => item === product).quantity = value;
        newOrder.totalPrice = getTotalHT(newOrder.items);
        setNewOrder({
            ...newOrder,
        });
    };

    const updateComment = (product: any, value: number) => {
        newOrder.items.find((item: any) => item === product).comment = value;
        setNewOrder({
            ...newOrder,
        });
    };

    const updateUnityPrice = (product: any, value: number) => {
        newOrder.items.find((item: any) => item === product).price = value;
        newOrder.totalPrice = getTotalHT(newOrder.items);
        setNewOrder({
            ...newOrder,
        });
    };

    const updateDiscount = (product: any, value: number) => {
        newOrder.items.find((item: any) => item === product).discountValue =
            value;
        newOrder.totalPrice = getTotalHT(newOrder.items);
        setNewOrder({
            ...newOrder,
        });
    };

    const updateDiscountType = (product: any, value: string) => {
        newOrder.items.find((item: any) => item === product).discountType =
            value;

        if (value === 'none')
            newOrder.items.find((item: any) => item === product).discountValue =
                0;
        newOrder.totalPrice = getTotalHT(newOrder.items);
        setNewOrder({
            ...newOrder,
        });
    };

    const deleteItem = (item: any) => {
        setNewOrder((prevCustomer: any) => ({
            ...prevCustomer,
            items: prevCustomer.items.filter(
                (i: any) => i.productId !== item.productId,
            ),
            totalPrice: getTotalHT(
                prevCustomer.items.filter(
                    (i: any) => i.productId !== item.productId,
                ),
            ),
        }));
    };

    const initCustomers = async (search: string) => {
        const res = await searchCustomers(search);
        const newCustomers = res.data.map((customer: any) => ({
            ...customer,
            company: customer.displayName || customer.company,
        }));
        if (res) {
            setCustomers(newCustomers);
            setCustomerLoading(false);
        }
    };

    const cleanNewOrder = (order: any) => {
        const newItems = order.items.map((item: any) => {
            const newItem = { ...item };
            if (item.price === '') newItem.price = 0;
            delete newItem.totalPurchasePrice;
            return newItem;
        });
        return { ...order, items: newItems };
    };

    const addOrder = async (createOne = true) => {
        const res = await createOrder(cleanNewOrder(newOrder));
        if (res) {
            toast.success(`Commande n°${res.orderId} créée`);
            if (createOne) navigate(`/order/${res?._id}`);
            else window.location.reload();
        }
    };

    const initDeliveryTours = async () => {
        const res = await getDeliveryTours(false);
        if (res) {
            setDeliveryTours(res.data);
            return;
        }
    };

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            navigate('/login');
        }
        initCustomers('');
        initDeliveryTours();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateProductsPrices = async (products: any) => {
        let isNewOrderUpdated = false;
        const newOrdersItemsUpdated = newOrder.items.map((item: any) => {
            const itemUpdated = products.find(
                (product: any) => product._id === item._id,
            );
            if (itemUpdated) {
                item.price = itemUpdated.price;
                isNewOrderUpdated = true;
            }
            return item;
        });

        const customer = customers.find(
            (customer: any) => customer._id === newOrder.customerId,
        );
        const warning = `Prix unitaire HT modifié en fonction du prix négocié avec ${
            customer ? customer.company : 'le client'
        }.`;

        if (isNewOrderUpdated) {
            setWarning(warning);
            setNewOrder((prevCustomer: any) => ({
                ...prevCustomer,
                items: newOrdersItemsUpdated,
                totalPrice: getTotalHT(newOrdersItemsUpdated),
            }));
        }
    };

    const setOrderWithAI = () => {
        if (newOrder.customerId === null) {
            return toast.error('Veuillez sélectionner un client');
        }

        for (let i = 0; i < customerLastOrder.length; i++) {
            const item = customerLastOrder[i];
            const product = products.find(
                (product: any) => product._id === item.productId,
            );
            if (product) {
                item.totalPurchasePrice = calculateTotalPurchasePrice(product);
            }
        }

        if (customerLastOrder && customerLastOrder.length > 0) {
            setNewOrder({
                ...newOrder,
                items: customerLastOrder,
                totalPrice: getTotalHT(customerLastOrder),
            });
            updateProductsPrices(products);
        }
    };

    const fetchProductsPrices = async (customerId: string) => {
        if (!customerId) return;

        setWarning(`Chargement des prix personnalisés pour ce client...`);
        const res = await getProducts(customerId);
        setWarning(null);
        if (res) {
            // const productsForSales = res.data.filter(
            //     (product: any) => product.category !== 'hidden',
            // );
            setProducts(res.data);
            updateProductsPrices(res.data);
        }
    };

    const fetchLastOrder = async (customerId: string) => {
        if (!customerId) return;
        const res = await getCustomerLastOrder(customerId);
        if (res) {
            console.log(res);
            setCustomerLastOrder(res);
        }
    };

    // Fetch products prices when the AI button is clicked
    useEffect(() => {
        fetchProductsPrices(newOrder ? newOrder.customerId : null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [IARefreshCounter]);

    useEffect(() => {
        setCustomerLoading(true);
        const delayDebounceFn = setTimeout(async () => {
            initCustomers(customerSearchValue);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerSearchValue]);

    useEffect(() => {
        fetchLastOrder(newOrder ? newOrder.customerId : null);
        fetchProductsPrices(newOrder ? newOrder.customerId : null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newOrder.customerId]);

    const isValidOrder = (order: any) => {
        return (
            order.status &&
            order.items.length > 0 &&
            order.deliveryDate &&
            order.customerId &&
            order.totalPrice >= 0
        );
    };

    const totalBrutWeight = () => {
        return newOrder.items
            .reduce(
                (acc: number, item: any) =>
                    acc +
                    (isNaN(Number(item.brutWeight * item.quantity))
                        ? 0
                        : Number(item.brutWeight * item.quantity)),
                0,
            )
            ?.toFixed(2);
    };

    const totalNetWeight = () => {
        return newOrder.items
            .reduce(
                (acc: number, item: any) =>
                    acc +
                    (isNaN(Number(item.netWeight * item.quantity))
                        ? 0
                        : Number(item.netWeight * item.quantity)),
                0,
            )
            ?.toFixed(2);
    };

    const getPageState = () => {
        if (!newOrder.customerId) return 'customer';
        return 'order';
    };

    const getCustomerDeliveryTourName = () => {
        const customer = customers.find(
            (c: any) => c._id === newOrder.customerId,
        );
        if (!customer) return 'Tournée par défaut';
        const deliveryTour = deliveryTours.find(
            (dt: any) => dt._id === customer.deliveryTourId,
        );
        return deliveryTour ? deliveryTour.name : 'Tournée par défaut';
    };

    const updateLotNumber = (product: any, value: string) => {
        newOrder.items.find((item: any) => item === product).lotNumber = value;
        setNewOrder({
            ...newOrder,
        });
    };

    const formatedProducts = (products: any) => {
        const lastOrders = customerLastOrder || [];
        const lastOrdersIds = lastOrders.map((order: any) => order.productId);
        const mergedProducts = [...products, ...lastOrders].map(
            (product: any) => {
                const isRecent = lastOrdersIds.includes(product._id);
                return { ...product, isRecent };
            },
        );
        const uniqueProducts = Array.from(
            new Set(mergedProducts.map((a: any) => a._id)),
        ).map((id) => {
            return mergedProducts.find((a: any) => a._id === id);
        });
        uniqueProducts.sort((a: any, b: any) => {
            if (a.isRecent && !b.isRecent) return -1;
            if (!a.isRecent && b.isRecent) return 1;
            return 0;
        });
        return productsWithDisplayKey(uniqueProducts);
    };

    return (
        <div className="mt-10 lg:mt-0">
            <AddProductsModal
                isOpen={isOpen}
                onClose={onClose}
                addProducts={(newItems: any) => {
                    setNewOrder((prevCustomer: any) => ({
                        ...prevCustomer,
                        items: [...prevCustomer.items, ...newItems],
                        totalPrice: getTotalHT([
                            ...prevCustomer.items,
                            ...newItems,
                        ]),
                    }));
                    onClose();
                }}
                products={formatedProducts(products)}
                existingProducts={newOrder.items.map((item: any) => item._id)}
            />
            <div className={`col-span-12`}>
                {/* Select customer */}
                {getPageState() === 'customer' && (
                    <Card extra={'w-full h-[500px] !p-5 border'}>
                        {/* Header */}
                        <div className="h-full w-full">
                            <div>
                                <p className="mt-10 text-center">
                                    Création de commande
                                </p>
                                <h4 className="mt-2 text-2xl text-center font-bold text-navy-700 dark:text-white">
                                    Quelle client souhaitez-vous sélectionner ?
                                </h4>
                                <div className="mt-4 flex mx-auto w-full lg:w-[70%] z-50">
                                    <CustomerSearchBar
                                        label="Client*"
                                        displayKey="company"
                                        options={customers}
                                        onSelect={(e: any) => {
                                            setNewOrder(
                                                (prevCustomer: any) => ({
                                                    ...prevCustomer,
                                                    customerId: e._id,
                                                }),
                                            );
                                        }}
                                        isLoading={customerLoading}
                                        onSearch={setCustomerSearchValue}
                                        inputSmall={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>
                )}
                {/* Order */}
                {getPageState() === 'order' && (
                    <>
                        <div
                            className="flex items-center justify-start cursor-pointer"
                            onClick={() =>
                                setNewOrder({ ...newOrder, customerId: null })
                            }
                        >
                            <svg
                                className="w-5 h-5 mr-2"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 19l-7-7 7-7"
                                />
                            </svg>
                            <p className="text-xs">Choix du client</p>
                        </div>
                        <Card extra={'mt-3 w-full !p-5 border'}>
                            <div className="h-full w-full">
                                <div>
                                    <p className="mt-2 text-center">
                                        Commande de{' '}
                                        <span
                                            className="text-toola-500 cursor-pointer hover:underline"
                                            onClick={() =>
                                                window.open(
                                                    `/customer/${newOrder.customerId}`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            {
                                                customers.find(
                                                    (c: any) =>
                                                        c._id ===
                                                        newOrder.customerId,
                                                )?.company
                                            }
                                        </span>
                                    </p>
                                    <h4 className="mt-2 text-2xl text-center font-bold text-navy-700 dark:text-white">
                                        Que contient la commande ?
                                    </h4>
                                    {warning && (
                                        <p className="mt-10 text-sm text-center text-toola-400">
                                            {warning}
                                        </p>
                                    )}
                                    {newOrder && newOrder.customerId ? (
                                        <>
                                            <>
                                                {/* <div className="mt-2 grid grid-cols-1 gap-3 xl:grid-cols-12">
                                                        <div className="z-40 col-span-4">
                                                            <SearchSelector
                                                                displayKey="displayKey"
                                                                secondaryKey="ref"
                                                                label="Produit*"
                                                                options={productsWithDisplayKey(
                                                                    products,
                                                                )}
                                                                onSelect={(
                                                                    e: any,
                                                                ) => {
                                                                    setNewItem(
                                                                        (
                                                                            prevCustomer: any,
                                                                        ) => ({
                                                                            ...prevCustomer,
                                                                            name: e.name,
                                                                            productId:
                                                                                e._id,
                                                                            _id: e._id,
                                                                            price: e.price,
                                                                            unity: e.unity,
                                                                            ref: e.ref,
                                                                            netWeight:
                                                                                e.netWeight,
                                                                            brutWeight:
                                                                                e.brutWeight,
                                                                            totalPurchasePrice:
                                                                                calculateTotalPurchasePrice(
                                                                                    e,
                                                                                ),
                                                                        }),
                                                                    );
                                                                }}
                                                                inputSmall={
                                                                    true
                                                                }
                                                            />
                                                        </div>
                                                        <div className="mt-7 md:mt-0 col-span-1">
                                                            <InputField
                                                                label="Quantité*"
                                                                placeholder="1"
                                                                id="quantity"
                                                                type="number"
                                                                onChange={
                                                                    handleItemChange
                                                                }
                                                                inputSmall={
                                                                    true
                                                                }
                                                            />
                                                        </div>
                                                        <div className="mt-7 md:mt-0 col-span-2">
                                                            <InputField
                                                                label="Prix unitaire HT*"
                                                                placeholder="17"
                                                                id="price"
                                                                type="number"
                                                                value={
                                                                    newItem.price
                                                                }
                                                                onChange={
                                                                    handleItemChange
                                                                }
                                                                inputSmall={
                                                                    true
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-span-3">
                                                            <button
                                                                className={`sm:mt-6 linear col-span-3 flex items-center justify-center rounded-xl ${
                                                                    alreadyExist(
                                                                        newItem.productId,
                                                                    )
                                                                        ? 'bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default'
                                                                        : 'bg-toola-500 hover:bg-toola-600 active:bg-toola-700'
                                                                } px-5 py-2 text-sm font-medium text-white`}
                                                                onClick={() => {
                                                                    if (
                                                                        !alreadyExist(
                                                                            newItem.productId,
                                                                        )
                                                                    ) {
                                                                        if (
                                                                            !newItem.productId ||
                                                                            !newItem.quantity ||
                                                                            !newItem.price
                                                                        )
                                                                            return toast.error(
                                                                                'Veuillez remplir tous les champs',
                                                                            );
                                                                        setNewOrder(
                                                                            (
                                                                                prevCustomer: any,
                                                                            ) => ({
                                                                                ...prevCustomer,
                                                                                items: [
                                                                                    ...prevCustomer.items,
                                                                                    newItem,
                                                                                ],
                                                                                totalPrice:
                                                                                    getTotalHT(
                                                                                        [
                                                                                            ...prevCustomer.items,
                                                                                            newItem,
                                                                                        ],
                                                                                    ),
                                                                            }),
                                                                        );
                                                                        setNewItem(
                                                                            {
                                                                                discountType:
                                                                                    'none',
                                                                                discountValue: 0,
                                                                                ...newItem,
                                                                            },
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                Ajouter
                                                            </button>
                                                        </div>
                                                    </div> */}
                                                {/* <button
                                                        className={`mt-5 linear col-span-12 flex items-center justify-center rounded-xl bg-[#0260CB] bg-[] px-3 py-3 text-xs font-medium text-white`}
                                                        onClick={onOpen}
                                                    >
                                                        Ajouter un produit
                                                    </button> */}
                                                <p className="mt-4 text-base text-gray-600">
                                                    Récapitulatif de la commande
                                                </p>
                                                {newOrder && (
                                                    <OrderTable
                                                        order={newOrder}
                                                        handleQtyChange={
                                                            updateQuantity
                                                        }
                                                        handlePriceChange={
                                                            updateUnityPrice
                                                        }
                                                        handleLotNumberChange={
                                                            updateLotNumber
                                                        }
                                                        handleDiscountChange={
                                                            updateDiscount
                                                        }
                                                        handleCommentChange={
                                                            updateComment
                                                        }
                                                        handleDiscountTypeChange={
                                                            updateDiscountType
                                                        }
                                                        onDelete={deleteItem}
                                                    />
                                                )}
                                                {newOrder &&
                                                    newOrder.items.length ===
                                                        0 && (
                                                        <div className="">
                                                            <p className="mt-5 text-lg text-gray-600 text-center">
                                                                Aucun produit
                                                                ajouté dans la
                                                                commande
                                                            </p>
                                                            {customerLastOrder &&
                                                                customerLastOrder.length >
                                                                    0 && (
                                                                    <div className="py-3 flex justify-center">
                                                                        <button
                                                                            className="flex gap-1 text-sm text-white py-2 px-3 bg-gradient-to-r from-toola-400 to-toola-500 rounded-full"
                                                                            onClick={() => {
                                                                                setIARefreshCounter(
                                                                                    IARefreshCounter +
                                                                                        1,
                                                                                );
                                                                                setOrderWithAI();
                                                                            }}
                                                                        >
                                                                            <BsStars className="flex my-auto text-white" />
                                                                            Pré-remplir
                                                                            avec
                                                                            l'IA
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            <div className="flex justify-center">
                                                                <button
                                                                    className="flex gap-1 text-sm text-white py-2 px-3 bg-toola-400 rounded-full"
                                                                    onClick={
                                                                        onOpen
                                                                    }
                                                                >
                                                                    <HiCursorClick className="flex my-auto text-white" />
                                                                    Remplir
                                                                    manuellement
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                            </>
                                        </>
                                    ) : (
                                        <div className="flex justify-center">
                                            <p className="text-lg text-gray-600">
                                                Veuillez sélectionner un client
                                                avant de continuer
                                            </p>
                                        </div>
                                    )}
                                </div>
                                {newOrder.items.length > 0 && (
                                    <div className="mt-2 flex gap-5">
                                        <div className="flex gap-1">
                                            <MdAddBox
                                                size={15}
                                                className="flex my-auto text-toola-500"
                                            />
                                            <p
                                                className="flex text-sm my-auto text-toola-500 hover:cursor-pointer hover:text-toola-600"
                                                onClick={onOpen}
                                            >
                                                Ajouter un produit existant
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {newOrder.items.length > 0 && (
                                    <div className="lg:flex gap-10">
                                        <div className="mt-5 flex gap-[15px]">
                                            <p className="col-span-4 sm:col-span-1 text-lg text-navy-700 dark:text-white">
                                                Total HT
                                            </p>
                                            <p className="text-lg font-bold text-navy-700 dark:text-white">
                                                {newOrder.totalPrice.toFixed(2)}
                                                €
                                            </p>
                                        </div>
                                        <div className="mt-5 gap-[15px]">
                                            <p className="col-span-4 sm:col-span-1 text-lg text-navy-700 dark:text-white">
                                                Total TTC
                                            </p>
                                            <p className="text-lg font-bold text-navy-700 dark:text-white">
                                                {getTotalTTC(
                                                    newOrder.items,
                                                ).toFixed(2)}
                                                €
                                            </p>
                                        </div>
                                        {totalNetWeight() > 0 && (
                                            <div className="mt-5 flex gap-[15px]">
                                                <p className="col-span-4 sm:col-span-1 text-lg text-navy-700 dark:text-white">
                                                    Poids net total:
                                                </p>
                                                <p className="text-lg font-bold text-navy-700 dark:text-white">
                                                    {totalNetWeight()}
                                                    Kg
                                                </p>
                                            </div>
                                        )}
                                        {totalBrutWeight() > 0 && (
                                            <div className="mt-5 flex gap-[15px]">
                                                <p className="col-span-4 sm:col-span-1 text-lg text-navy-700 dark:text-white">
                                                    Poids brut total:
                                                </p>
                                                <p className="text-lg font-bold text-navy-700 dark:text-white">
                                                    {totalBrutWeight()}
                                                    Kg
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Card>
                    </>
                )}
                {/* Manual informations */}
                {getPageState() === 'order' && (
                    <div className="mt-3 grid grid-cols-12 gap-2">
                        <Card
                            extra={
                                'w-full !p-5 border col-span-12 lg:col-span-8'
                            }
                        >
                            {/* Header */}
                            <div className="h-full w-full">
                                <div>
                                    <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                                        Informations finales
                                    </h4>
                                    {/* Manual inputs */}
                                    <div className="mt-10 grid grid-cols-1 gap-3 sm:grid-cols-12">
                                        <div className="mt-3 md:mt-0 col-span-12 lg:col-span-3">
                                            <InputField
                                                label="Date de livraison*"
                                                placeholder="01/01/2021"
                                                id="deliveryDate"
                                                type="date"
                                                onChange={handleChange}
                                                inputSmall={true}
                                            />
                                        </div>
                                        <div className="col-span-12 lg:col-span-3">
                                            <InputField
                                                label="Numéro purchase order (PO)"
                                                value={
                                                    newOrder &&
                                                    newOrder.purchaseOrderNumber
                                                }
                                                placeholder="PO-7578"
                                                id="purchaseOrderNumber"
                                                type="text"
                                                onChange={handleChange}
                                                inputSmall={true}
                                            />
                                        </div>
                                        <div
                                            className={`mb-2 ${
                                                !isOpen && 'z-40'
                                            } md:mt-0 md:mb-0 col-span-12 lg:col-span-3`}
                                        >
                                            <SearchSelector
                                                label="Statut"
                                                options={[
                                                    {
                                                        status: 'En cours',
                                                        _id: 'pending',
                                                    },
                                                    {
                                                        status: 'Livré',
                                                        _id: 'delivered',
                                                    },
                                                ]}
                                                displayKey="status"
                                                value={
                                                    newOrder && newOrder.status
                                                }
                                                onSelect={(e: any) => {
                                                    setNewOrder(
                                                        (
                                                            prevCustomer: any,
                                                        ) => ({
                                                            ...prevCustomer,
                                                            status: e._id,
                                                        }),
                                                    );
                                                }}
                                                inputSmall={true}
                                            />
                                        </div>
                                        <div
                                            className={`col-span-12 lg:col-span-3 ${
                                                !isOpen && 'z-40'
                                            }`}
                                        >
                                            {defaultDeliveryTour ? (
                                                <>
                                                    <label
                                                        htmlFor={
                                                            'deliverytourlabel'
                                                        }
                                                        className={`text-xs font-medium text-navy-700 dark:text-white`}
                                                    >
                                                        Tournée de livraison
                                                    </label>
                                                    <p className="text-sm">
                                                        {getCustomerDeliveryTourName()}
                                                    </p>
                                                    <p
                                                        className="text-xs text-toola-500 cursor-pointer hover:underline"
                                                        onClick={() =>
                                                            setDefaultDeliveryTour(
                                                                false,
                                                            )
                                                        }
                                                    >
                                                        Modifier sur cette
                                                        commande
                                                    </p>
                                                </>
                                            ) : (
                                                <SearchSelector
                                                    label="Tournée de livraison"
                                                    displayKey="name"
                                                    options={deliveryTours}
                                                    value={
                                                        newOrder?.deliveryTourId
                                                    }
                                                    onSelect={(e: any) => {
                                                        setNewOrder(
                                                            (prev: any) => ({
                                                                ...prev,
                                                                deliveryTourId:
                                                                    e._id,
                                                            }),
                                                        );
                                                    }}
                                                    canCreate={true}
                                                    createNew={() =>
                                                        window.open(
                                                            '/settings/deliveries',
                                                            '_blank',
                                                        )
                                                    }
                                                    inputSmall={true}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Notes */}
                            <div className="h-full w-full">
                                <div className="relative">
                                    <div className="h-full w-full pt-3">
                                        <div className="mt-2 grid grid-cols-1 gap-3 xl:grid-cols-12">
                                            <div className="col-span-4">
                                                <InputField
                                                    label="Note du client"
                                                    placeholder={`Pouvez-vous livrer avant 8h ?`}
                                                    id="customerNotes"
                                                    type="text"
                                                    onChange={handleChange}
                                                    inputSmall={true}
                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <InputField
                                                    label={`Note interne`}
                                                    placeholder="Livraison prioritaire"
                                                    id="internalNotes"
                                                    type="text"
                                                    onChange={handleChange}
                                                    inputSmall={true}
                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <InputField
                                                    label={`Note interne`}
                                                    placeholder="Livraison prioritaire"
                                                    id="internalNotes"
                                                    type="text"
                                                    onChange={handleChange}
                                                    inputSmall={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <Card
                            extra={
                                'w-full !p-5 border col-span-12 lg:col-span-4'
                            }
                        >
                            {/* Header */}
                            <div className="h-full w-full">
                                <div className="relative">
                                    <div className="flex gap-[10px] absolute right-0 top-0"></div>
                                    <div className="h-full w-full">
                                        <div className="flex justify-between">
                                            <h4 className="text-lg font-medium text-navy-700">
                                                Client
                                            </h4>
                                        </div>
                                        <p className="mt-2 text-sm text-gray-600">
                                            {customers.find(
                                                (c: any) =>
                                                    c._id ===
                                                    newOrder.customerId,
                                            ) &&
                                                `Nom: ${customers.find(
                                                    (c: any) =>
                                                        c._id ===
                                                        newOrder.customerId,
                                                )?.company}`}{' '}
                                        </p>
                                        <p className="mt-2 text-sm text-gray-600">
                                            {customers.find(
                                                (c: any) =>
                                                    c._id ===
                                                    newOrder.customerId,
                                            ) &&
                                                `Email: ${customers.find(
                                                    (c: any) =>
                                                        c._id ===
                                                        newOrder.customerId,
                                                )?.mail}`}{' '}
                                        </p>
                                        <p className="mt-2 text-sm text-gray-600">
                                            {customers.find(
                                                (c: any) =>
                                                    c._id ===
                                                    newOrder.customerId,
                                            ) &&
                                                `Téléphone: ${
                                                    customers.find(
                                                        (c: any) =>
                                                            c._id ===
                                                            newOrder.customerId,
                                                    )?.phone || 'Non renseigné'
                                                }`}
                                            {!customers.find(
                                                (c: any) =>
                                                    c._id ===
                                                    newOrder.customerId,
                                            ) && 'Aucune information client'}
                                        </p>
                                        <p className="mt-2 text-sm text-gray-600">
                                            {customers.find(
                                                (c: any) =>
                                                    c._id ===
                                                    newOrder.customerId,
                                            ) &&
                                                `Adresse: ${
                                                    customers.find(
                                                        (c: any) =>
                                                            c._id ===
                                                            newOrder.customerId,
                                                    )?.deliveryAddress ||
                                                    'Non renseigné'
                                                }`}
                                            {!customers.find(
                                                (c: any) =>
                                                    c._id ===
                                                    newOrder.customerId,
                                            ) && 'Aucune information client'}
                                        </p>
                                        <p
                                            className="flex gap-2 mt-2 text-sm text-toola-500 hover:cursor-pointer"
                                            onClick={() =>
                                                window.open(
                                                    `/customer/${customers.find(
                                                        (c: any) =>
                                                            c._id ===
                                                            newOrder.customerId,
                                                    )?._id}`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            <FaExternalLinkAlt
                                                size={12}
                                                className="flex my-auto"
                                            />
                                            Voir la fiche client
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                )}
                {getPageState() === 'order' && (
                    <div className="mt-5 ml-5 grid grid-cols-1 sm:grid-cols-12 gap-4">
                        <button
                            className={`linear col-span-12 sm:col-span-3 flex items-center justify-center rounded-xl px-10 py-2 text-xs font-medium text-white ${
                                isValidOrder(newOrder)
                                    ? 'bg-[#0260CB] hover:bg-[#01499b] active:bg-[#024086]'
                                    : 'bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default'
                            }`}
                            onClick={() => {
                                isValidOrder(newOrder) && addOrder();
                            }}
                        >
                            Ajouter la commande
                        </button>
                        {/* <button
                            className={`linear col-span-12 sm:col-span-3 flex items-center justify-center rounded-xl px-10 py-2 text-xs font-medium text-white ${
                                isValidOrder(newOrder)
                                    ? 'bg-[#0260CB] hover:bg-[#01499b] active:bg-[#024086]'
                                    : 'bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default'
                            }`}
                            onClick={() => {
                                isValidOrder(newOrder) && addOrder(false);
                            }}
                        >
                            Ajouter puis en créer une autre
                        </button> */}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CreateOrder;
