import axios from 'axios';

import env from 'variables/config';
import { errorHandler } from './utils';

axios.defaults.baseURL = env.apiUrl;

const deliveryConfig = () => {
    return {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('delivery-token'),
        },
    };
};

const config = () => {
    return {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    };
};

export const getPaginatedCustomers = async (
    page: number,
    limit: number,
    search: string,
) => {
    const res: any = await axios
        .get(
            `/customers?limit=${limit}&page=${page}&search=${search}`,
            config(),
        )
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getPaginatedCustomersByReturnables = async (
    page: number,
    limit: number,
    search: string,
) => {
    const res: any = await axios
        .get(
            `/customers/returnables?limit=${limit}&page=${page}&search=${search}`,
            config(),
        )
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const shareMagicLink = async (email: string) => {
    const res = await axios
        .post(`/auto-orders/share`, { email }, config())
        .catch(errorHandler);
    return res.data;
};

export const getProspects = async (search: string) => {
    const res: any = await axios
        .get(`/customers/prospects?search=${search}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const addCustomerActivity = async (
    id: string,
    content: string,
    type: string,
) => {
    const res = await axios
        .post(`/customers/${id}/activities`, { content, type }, config())
        .catch(errorHandler);
    return res.data;
};

export const addCustomerTasks = async (
    id: string,
    content: string,
    dueDate: string,
) => {
    const res = await axios
        .post(`/customers/${id}/tasks`, { content, dueDate }, config())
        .catch(errorHandler);
    return res.data;
};

export const getCustomers = async () => {
    const res: any = await axios
        .get('/customers?limit=5000', config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const searchCustomers = async (search: string) => {
    const res: any = await axios
        .get(`/customers?limit=50&search=${search}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const searchCustomersAndProspects = async (search: string) => {
    const res: any = await axios
        .get(`/customers/all?limit=50&search=${search}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getCustomersWithNotInvoicedOrder = async () => {
    const res: any = await axios
        .get('/customers/invoices', config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getCustomer = async (id: string) => {
    const res = await axios.get(`/customers/${id}`, config()).catch((err) => {
        return null;
    });
    if (!res) return null;
    return res.data;
};

export const getCustomerPage = async (id: string) => {
    const res = await axios
        .get(`/customers/page/${id}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getCustomerAnalytics = async (id: string) => {
    const res = await axios
        .get(`/customers/${id}/analytics`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getCustomerLastOrder = async (id: string) => {
    const res = await axios
        .get(`/customers/${id}/lastOrder`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const updateCustomerDelivery = async (customer: any) => {
    const id = customer._id;
    delete customer._id;
    const res: any = await axios
        .put(`/customers/${id}`, customer, deliveryConfig())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const updateCustomer = async (customer: any) => {
    const id = customer._id;
    delete customer._id;
    const res: any = await axios
        .put(`/customers/${id}`, customer, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const updatePageActivated = async (customer: any) => {
    const id = customer._id;
    delete customer._id;
    const res: any = await axios
        .put(`/customers/${id}/isOrderingPageActivated`, customer, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const archiveCustomer = async (id: string) => {
    const res: any = await axios
        .delete(`/customers/${id}/archive`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const deleteCustomer = async (id: string) => {
    const res: any = await axios
        .delete(`/customers/${id}`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const createCustomer = async (customer: any) => {
    const res = await axios
        .post('/customers', customer, config())
        .catch(errorHandler);
    return res.data;
};

export const importCustomers = async (customers: any) => {
    const res = await axios
        .post('/customers/bulk', { customers }, config())
        .catch(errorHandler);
    return res.data;
};
