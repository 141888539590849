import { useEffect, useState } from 'react';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';

import Card from 'components/card';
import SearchIcon from 'components/icons/SearchIcon';
import { searchCustomersAndProspects } from 'interfaces/customer';

const SelectCustomersModal = (props: {
    isOpen: any;
    onClose: any;
    newState: string;
    onAction: any;
}) => {
    const { isOpen, onClose, newState, onAction } = props;

    const [customers, setCustomers] = useState<any[]>([]);
    const [tab, setTab] = useState('search');
    const [customerLoading, setCustomerLoading] = useState(true);
    const [customerSearchValue, setCustomerSearchValue] = useState('');
    const [selectedCustomers, setSelectedCustomers] = useState<any[]>([]);

    const handleItemClicked = (customer: any) => {
        if (!selectedCustomers.includes(customer))
            setSelectedCustomers((prev) => [...prev, customer]);
        else
            setSelectedCustomers((prev) =>
                prev.filter((item) => item !== customer),
            );
    };

    const initCustomers = async (search: string) => {
        const res = await searchCustomersAndProspects(search);
        const newCustomers = res.data.map((customer: any) => ({
            ...customer,
            company: customer.displayName || customer.company,
        }));
        if (res) {
            setCustomers(newCustomers);
            setCustomerLoading(false);
        }
    };

    const handleClick = () => {
        if (tab === 'all') {
            onAction(['all']);
        } else {
            onAction(selectedCustomers.map((customer) => customer._id));
        }
    };

    useEffect(() => {
        setCustomerLoading(true);
        const delayDebounceFn = setTimeout(async () => {
            initCustomers(customerSearchValue);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerSearchValue]);

    useEffect(() => {
        initCustomers('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] mx-auto md:top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[20px] pb-[40px] max-w-[30%] max-h-[500px] flexs !z-[1004] w-full mx-auto">
                        <h1 className="text-lg font-bold">
                            {newState === 'hidden'
                                ? 'Cacher'
                                : 'Rendre visible'}{' '}
                            mes produits pour:
                        </h1>
                        <p className="mt-2 text-sm text-gray-600">
                            Sélectionnez les clients pour lesquels vous
                            souhaitez{' '}
                            {newState === 'hidden'
                                ? 'cacher'
                                : 'rendre visible'}{' '}
                            vos produits.
                        </p>
                        {/* Tabs */}
                        <div className="mt-4 flex gap-2">
                            <p
                                className={`text-xs cursor-pointer ${
                                    tab === 'search'
                                        ? 'text-toola-500'
                                        : 'text-gray-600hover:underline'
                                }`}
                                onClick={() => setTab('search')}
                            >
                                Recherche
                            </p>
                            <p
                                className={`text-xs cursor-pointer ${
                                    tab === 'selected'
                                        ? 'text-toola-500'
                                        : 'text-gray-600 cursor-pointer hover:underline'
                                }`}
                                onClick={() => setTab('selected')}
                            >
                                Sélectionnés ({selectedCustomers.length})
                            </p>
                            <p
                                className={`text-xs cursor-pointer ${
                                    tab === 'all'
                                        ? 'text-toola-500'
                                        : 'text-gray-600 cursor-pointer hover:underline'
                                }`}
                                onClick={() => setTab('all')}
                            >
                                Tous mes clients
                            </p>
                        </div>
                        {/* Search */}
                        {tab === 'search' && (
                            <div className="mt-3 flex h-[35px] flex-grow items-center rounded-xl bg-lightPrimary text-sm text-gray-600">
                                <SearchIcon />
                                <input
                                    type="text"
                                    placeholder="Rechercher un client"
                                    onChange={(e) =>
                                        setCustomerSearchValue(e.target.value)
                                    }
                                    className="mtblock w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none"
                                />
                                {customerLoading && (
                                    <div className="mr-4 border-gray-300 h-1 w-1 animate-spin rounded-full border-8 border-t-toola-500" />
                                )}
                            </div>
                        )}
                        {/* Search Items */}
                        <div className="mt-4 grid grid-cols-1 overflow-y-auto gap-2">
                            {customers &&
                                tab === 'search' &&
                                customers.map((item: any, index: number) => (
                                    <div
                                        className={`relative mb-2 flex items-center gap-3 rounded-[20px] ${
                                            selectedCustomers.includes(item)
                                                ? 'bg-green-100'
                                                : 'bg-gray-100'
                                        } bg-clip-border py-4 shadow-3xl shadow-shadow-500 hover:cursor-pointer dark:!bg-navy-800 dark:shadow-none`}
                                        onClick={() => handleItemClicked(item)}
                                        key={index}
                                    >
                                        <div className="w-full flex justify-between px-3">
                                            <div>
                                                <h2 className="ml-3 text-xs text-navy-700 dark:text-white">
                                                    {item.company || 'Inconnu'}
                                                </h2>
                                            </div>
                                            <div>
                                                {selectedCustomers.includes(
                                                    item,
                                                ) ? (
                                                    <MdRemoveCircleOutline
                                                        size="20"
                                                        color="red"
                                                    />
                                                ) : (
                                                    <MdAddCircleOutline
                                                        size="20"
                                                        className="text-toola-500"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        {/* Selected Items */}
                        <div className="mt-2 grid grid-cols-1 overflow-y-auto gap-2">
                            {selectedCustomers &&
                                tab === 'selected' &&
                                selectedCustomers.map(
                                    (item: any, index: number) => (
                                        <div
                                            className={`relative mb-2 flex items-center gap-3 rounded-[20px] ${
                                                selectedCustomers.includes(item)
                                                    ? 'bg-green-100'
                                                    : 'bg-gray-100'
                                            } bg-clip-border py-4 shadow-3xl shadow-shadow-500 hover:cursor-pointer dark:!bg-navy-800 dark:shadow-none`}
                                            onClick={() =>
                                                handleItemClicked(item)
                                            }
                                            key={index}
                                        >
                                            <div className="w-full flex justify-between px-3">
                                                <div>
                                                    <h2 className="ml-3 text-xs text-navy-700 dark:text-white">
                                                        {item.company ||
                                                            'Inconnu'}
                                                    </h2>
                                                </div>
                                                <div>
                                                    {selectedCustomers.includes(
                                                        item,
                                                    ) ? (
                                                        <MdRemoveCircleOutline
                                                            size="20"
                                                            color="red"
                                                        />
                                                    ) : (
                                                        <MdAddCircleOutline
                                                            size="20"
                                                            className="text-toola-500"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                )}
                        </div>
                        <div className="mt-5 flex gap-2 mx-auto">
                            <button
                                className="flex mx-auto linear ml-3 text-sm items-center justify-center rounded-xl bg-[#0260CB] bg-[] px-3 py-3 font-medium text-white hover:bg-[#01499b] active:bg-[#0260CB]"
                                onClick={handleClick}
                            >
                                {newState === 'hidden'
                                    ? 'Cacher'
                                    : 'Rendre visible'}
                                {tab === 'all'
                                    ? ' pour tous mes clients'
                                    : ' pour les clients sélectionnés'}
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default SelectCustomersModal;
