import CreateButton from 'components/buttons/CreateButton';
import Card from 'components/card';
import Select from 'components/fields/Select';
import { getMonthlyInvoicesFolder } from 'interfaces/invoice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EnterPayement from './components/EnterPayement';
import { useDisclosure } from '@chakra-ui/hooks';

const defaultFolder = [
    {
        month: 0,
        invoices: 0,
        unpaidInvoices: 0,
        totalHT: 0,
        totalHTNotPaid: 0,
    },
    {
        month: 1,
        invoices: 0,
        unpaidInvoices: 0,
        totalHT: 0,
        totalHTNotPaid: 0,
    },
    {
        month: 2,
        invoices: 0,
        unpaidInvoices: 0,
        totalHT: 0,
        totalHTNotPaid: 0,
    },
    {
        month: 3,
        invoices: 0,
        unpaidInvoices: 0,
        totalHT: 0,
        totalHTNotPaid: 0,
    },
    {
        month: 4,
        invoices: 0,
        unpaidInvoices: 0,
        totalHT: 0,
        totalHTNotPaid: 0,
    },
    {
        month: 5,
        invoices: 0,
        unpaidInvoices: 0,
        totalHT: 0,
        totalHTNotPaid: 0,
    },
    {
        month: 6,
        invoices: 0,
        unpaidInvoices: 0,
        totalHT: 0,
        totalHTNotPaid: 0,
    },
    {
        month: 7,
        invoices: 0,
        unpaidInvoices: 0,
        totalHT: 0,
        totalHTNotPaid: 0,
    },
    {
        month: 8,
        invoices: 0,
        unpaidInvoices: 0,
        totalHT: 0,
        totalHTNotPaid: 0,
    },
    {
        month: 9,
        invoices: 0,
        unpaidInvoices: 0,
        totalHT: 0,
        totalHTNotPaid: 0,
    },
    {
        month: 10,
        invoices: 0,
        unpaidInvoices: 0,
        totalHT: 0,
        totalHTNotPaid: 0,
    },
    {
        month: 11,
        invoices: 0,
        unpaidInvoices: 0,
        totalHT: 0,
        totalHTNotPaid: 0,
    },
];

function UnpaidFolder() {
    const nav = useNavigate();
    const [year, setYear] = useState('2025');
    const [monthlyFolders, setMonthlyFolders] = useState(defaultFolder);
    const [loading, setLoading] = useState(true);
    const { isOpen, onClose, onOpen } = useDisclosure();

    const months = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ];

    const fetchMonthlyFolders = async (year: string) => {
        const res = await getMonthlyInvoicesFolder(year);
        if (res && res.data) {
            setMonthlyFolders(res.data);
            setLoading(false);
        }
    };

    const padZero = (num: number) => {
        return num < 10 ? `0${num}` : num;
    };

    const AllUnpaidCard = () => {
        const allTotal = {
            invoices: 0,
            unpaidInvoices: 0,
            totalHT: 0,
            totalHTNotPaid: 0,
        };
        for (const folder of monthlyFolders) {
            allTotal.invoices += folder.invoices;
            allTotal.unpaidInvoices += folder.unpaidInvoices;
            allTotal.totalHT += folder.totalHT;
            allTotal.totalHTNotPaid += folder.totalHTNotPaid;
        }
        return (
            <Card
                extra={`flex flex-col w-full h-[150px] !p-4 3xl:p-![18px] bg-white border border-[#7090B0]/[0.25]`}
            >
                <div
                    className="h-full w-full hover:cursor-pointer"
                    onClick={() => nav(`/unpaid-manager/all/${year}`)}
                >
                    {loading ? (
                        // Skeleton Loader
                        <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                            <div className="mb-2">
                                <p className="text-lg font-bold text-navy-700 dark:text-white">
                                    {' '}
                                    {`Impayés ${year}`}
                                </p>
                                <div className="mt-5 animate-pulse h-2 bg-[#1B254B]/[0.25] rounded-full dark:bg-gray-700 w-[55%] my-[7px]"></div>
                                <div className="mt-4 animate-pulse h-2 bg-[#1B254B]/[0.25] rounded-full dark:bg-gray-700 w-[45%] my-[7px]"></div>
                            </div>
                        </div>
                    ) : (
                        // Real Data
                        <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                            <div className="mb-2">
                                <p className="text-lg font-bold text-navy-700 dark:text-white">
                                    {' '}
                                    {`Impayés ${year}`}
                                </p>
                                <p className="mt-2 font-bold mb-2 text-sm text-[#A3AED0] dark:text-white">
                                    {`${allTotal.unpaidInvoices}/${allTotal.invoices} factures impayées`}
                                </p>
                                {allTotal.invoices > 0 && (
                                    <p className="mt-2 mb-2 text-sm font-medium text-[#A3AED0] dark:text-white">
                                        {` ${allTotal.totalHTNotPaid.toFixed(
                                            2,
                                        )} € HT restant à collecter`}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Card>
        );
    };

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            nav('/login');
        }

        setLoading(true);
        fetchMonthlyFolders(year);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year]);

    return (
        <div className="col-span-12 h-funn w-full rounded-[20px]">
            <EnterPayement isOpen={isOpen} onClose={onClose} />
            <Card extra={'w-full px-6 pb-6 sm:overflow-x-auto'}>
                <div className="relative flex items-center justify-between pt-4">
                    <div className="grid grid-cols-2">
                        <div className="hidden sm:flex text-xl font-bold text-navy-700 dark:text-white">
                            Mes factures
                        </div>
                        <div>
                            <Select
                                extra="!mt-0 pt-[5px] h-[30px] !w-[140px]"
                                options={[
                                    { label: 'Exercice 2023', value: '2023' },
                                    { label: 'Exercice 2024', value: '2024' },
                                    { label: 'Exercice 2025', value: '2025' },
                                ]}
                                displayKey="label"
                                onSelect={(e: any) => setYear(e.value)}
                                required={false}
                                selected={'2025'}
                            />
                        </div>
                    </div>
                </div>
                <CreateButton
                    value="Facturer mes clients"
                    extra="my-auto h-10 flex lg:hidden"
                    onClick={() => nav('/generate-invoice')}
                />
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
                    <AllUnpaidCard />
                    {monthlyFolders.map((folder, index) => (
                        <Card
                            extra={`flex flex-col w-full h-[150px] !p-4 3xl:p-![18px] bg-white border border-[#7090B0]/[0.25]`}
                        >
                            <div
                                className="h-full w-full hover:cursor-pointer"
                                onClick={() =>
                                    nav(
                                        `/unpaid-manager/${padZero(
                                            folder.month + 1,
                                        )}/${year}`,
                                    )
                                }
                            >
                                {loading ? (
                                    // Skeleton Loader
                                    <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                                        <div className="mb-2">
                                            <p className="text-lg font-bold text-navy-700 dark:text-white">
                                                {' '}
                                                {`${
                                                    months[folder.month]
                                                } ${year}`}
                                            </p>
                                            <div className="mt-5 animate-pulse h-2 bg-[#1B254B]/[0.25] rounded-full dark:bg-gray-700 w-[100%] my-[7px]"></div>
                                            <div className="mt-4 animate-pulse h-2 bg-[#1B254B]/[0.25] rounded-full dark:bg-gray-700 w-[85%] my-[7px]"></div>
                                        </div>
                                    </div>
                                ) : (
                                    // Real Data
                                    <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                                        <div className="mb-2">
                                            <p className="text-lg font-bold text-navy-700 dark:text-white">
                                                {' '}
                                                {`${
                                                    months[folder.month]
                                                } ${year}`}
                                            </p>
                                            <p className="mt-2 font-bold mb-2 text-sm text-[#A3AED0] dark:text-white">
                                                {`${folder.unpaidInvoices}/${folder.invoices} factures impayées`}
                                            </p>
                                            {folder.invoices > 0 && (
                                                <p className="mt-2 mb-2 text-sm font-medium text-[#A3AED0] dark:text-white">
                                                    {` ${folder.totalHTNotPaid.toFixed(
                                                        2,
                                                    )} € HT restant à collecter`}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Card>
                    ))}
                </div>
            </Card>
        </div>
    );
}

export default UnpaidFolder;
