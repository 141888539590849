import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import Checkbox from 'components/checkbox';
import { useState } from 'react';
import axios from 'axios';
import env from 'variables/config';
import { ToastContainer, toast } from 'react-toastify';

function SignUpDefault() {
    const [user, setUser] = useState({
        name: '',
        surname: '',
        email: '',
        password: '',
        company: '',
    });

    const handleChange = (e: any) => {
        setUser({ ...user, [e.target.id]: e.target.value });
    };

    const handleSubmit = async (e: any) => {
        const { name, surname, email, password, company } = user;

        const res = await axios
            .post(`${env.apiUrl}/auth/register`, {
                fullName: `${name} ${surname}`,
                email,
                password,
                company,
            })
            .catch((err) => {
                console.log(err);
                toast.error("Une erreur est survenue lors de l'inscription");
            });
        if (res && res.data && res.data.token) {
            window.open('https://toola.fr', '_self');
        }
    };

    return (
        <Default
            maincard={
                <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
                    <ToastContainer />
                    {/* Sign up section */}
                    <div className="mt-[10vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
                        <h3 className="text-4xl font-bold text-navy-700 dark:text-white">
                            Inscription
                        </h3>
                        <p className="ml-1 mt-[10px] text-base text-gray-600">
                            Vous êtes à quelques clics de rejoindre Toola.
                        </p>
                        {/* user info */}
                        <div className="mb-3 mt-8 flex w-full items-center justify-center gap-4">
                            <div className="w-1/2">
                                <InputField
                                    variant="auth"
                                    extra="mb-3"
                                    label="Prénom*"
                                    placeholder="Mathieu"
                                    id="name"
                                    type="text"
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="w-1/2">
                                <InputField
                                    variant="auth"
                                    extra="mb-3"
                                    label="Nom*"
                                    placeholder="Dupont"
                                    id="surname"
                                    type="text"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        {/* Company */}
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="Entreprise*"
                            placeholder="Toola"
                            id="company"
                            type="text"
                            onChange={handleChange}
                        />
                        {/* Email */}
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="Email*"
                            placeholder="mathieu@toola.eu"
                            id="email"
                            type="email"
                            onChange={handleChange}
                        />
                        {/* Password */}
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="Password*"
                            placeholder="********"
                            id="password"
                            type="password"
                            onChange={handleChange}
                        />
                        {/* Checkbox */}
                        <div className="mt-4 flex items-center justify-between px-2">
                            <div className="flex">
                                <Checkbox id="checkbox" />
                                <label
                                    htmlFor="checkbox"
                                    className="ml-2 text-sm text-navy-700 hover:cursor-pointer dark:text-white"
                                >
                                    J'accepte les conditions générales
                                    d'utilisation.
                                </label>
                            </div>
                        </div>

                        {/* button */}

                        <button
                            className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                            onClick={handleSubmit}
                        >
                            Créer mon compte
                        </button>

                        <div className="mt-3">
                            <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                                Déjà inscrit ?
                            </span>
                            <a
                                href="/auth/login"
                                className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                            >
                                se connecter
                            </a>
                        </div>
                    </div>
                </div>
            }
        />
    );
}

export default SignUpDefault;
