import { useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { getIcon } from 'utils/icons';

function ProductCard(props: {
    name: string;
    img: string;
    visible: boolean;
    price: number;
    type: string;
    id: string;
    alert: number;
    reference?: string;
    productType?: string;
    color?: string;
    updateVisibility: any;
}) {
    const navigate = useNavigate();
    const [displayQR, setDisplayQR] = useState(false);
    const {
        name,
        img,
        type,
        id,
        price,
        visible,
        reference,
        color,
        productType,
        updateVisibility,
    } = props;
    return (
        <div
            className={`relative flex items-center rounded-[20px] bg-clip-border border px-[18px] py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none `}
            onMouseOver={() => setDisplayQR(true)}
            onMouseLeave={() => setDisplayQR(false)}
        >
            {color && (
                <div
                    className={`absolute  top-3 left-3 h-4 w-4 bg-opacity-80 rounded-full border`}
                    style={{ backgroundColor: color }}
                ></div>
            )}
            <p className="text-xs absolute bottom-2 left-4 text-gray-600">
                {productType === 'transformed' && 'Transformé'}
                {productType === 'assembled' && 'Assemblé'}
                {productType === 'brut' && 'Brut'}
            </p>
            <div className="absolute bottom-2 right-2 hover:cursor-pointer">
                {visible ? (
                    <p
                        className="flex gap-1 text-xs text-red-500 border rounded-xl px-2 border-red-500"
                        onClick={updateVisibility}
                    >
                        <MdRemove className="flex my-auto" />
                        Cacher
                    </p>
                ) : (
                    <p
                        className="flex gap-1 text-xs text-toola-500 border rounded-xl px-2 border-toola-500"
                        onClick={updateVisibility}
                    >
                        <MdAdd className="flex my-auto" />
                        Ajouter
                    </p>
                )}
            </div>

            <div className="grid grid-cols-12">
                <div className="-bottom-12 col-span-4 flex h-[87px] w-[87px] items-center justify-center rounded-full">
                    <object
                        data={type === 'product' ? img : getIcon(name, type)}
                        type="image/png"
                        className={`h-[80px] w-[80px] rounded-xl`}
                    >
                        <img
                            className={`h-[80px] w-[80px] p-2 rounded-xl ${
                                displayQR && 'mb-2'
                            } `}
                            src={getIcon(name, type)}
                            alt=""
                        />
                    </object>
                </div>
                <div className="col-span-8 ml-4">
                    <h2
                        className={`text-md font-medium leading-6 text-navy-700 dark:text-white`}
                    >
                        {name}
                    </h2>
                    <p className="mb-1 text-xs font-medium text-gray-600">
                        {reference ? reference : 'Référence: inconnue'}
                    </p>
                    <h1 className="mt-2 text-md font-bold">
                        {price && price.toFixed(2)} €
                    </h1>
                </div>
            </div>
        </div>
    );
}

export default ProductCard;
