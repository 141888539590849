import { useState } from 'react';
import { MdAddBox, MdClose, MdOutlineFavorite } from 'react-icons/md';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';

import Card from 'components/card';
import SearchIcon from 'components/icons/SearchIcon';
import { calculateTotalPurchasePrice } from 'utils/margin';

const AddProductsModal = (props: {
    isOpen: any;
    onClose: any;
    addProducts: any;
    products: any;
    existingProducts: any;
}) => {
    const { isOpen, onClose, addProducts, existingProducts, products } = props;

    const [search, setSearch] = useState('');
    const [tab, setTab] = useState('all');
    const [customProducts, setCustomProducts] = useState<any[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);

    const handleItemClicked = (product: any, quantity: number) => {
        const newProduct = {
            ...product,
            productId: product._id,
            price: parseFloat(product.price),
            vat: parseFloat(product.vat),
            quantity,
            discountType: 'none',
            discountValue: 0,
            totalPurchasePrice: calculateTotalPurchasePrice(product),
        };
        if (!selectedProducts.find((i) => i.productId === newProduct.productId))
            setSelectedProducts((prev) => [...prev, newProduct]);
        else if (quantity <= 0)
            setSelectedProducts((prev) =>
                prev.filter((i) => i.productId !== newProduct.productId),
            );
        else
            setSelectedProducts((prev) =>
                prev.map((i) =>
                    i.productId === newProduct.productId
                        ? { ...i, quantity }
                        : i,
                ),
            );
    };

    const searchProduct = (customer: any, search: string) => {
        for (const key in customer)
            if (
                customer[key] &&
                typeof customer[key] === 'string' &&
                customer[key]
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase())
            )
                return true;
        return false;
    };

    const generateUUID = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            },
        );
    };

    const handleCustomerProductChange = (
        index: number,
        key: string,
        value: any,
    ) => {
        setCustomProducts(
            customProducts.map((item, i) =>
                i === index
                    ? {
                          ...item,
                          [key]: value,
                      }
                    : item,
            ),
        );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="relative z-40 mx-auto md:top-[12vh]">
                <ModalBody>
                    <Card extra="mt-5 lg:mt-0 px-[10px] lg:px-[30px] pt-[35px] pb-[40px] max-w-[95%] lg:max-w-[50%] max-h-[500px] flexs w-full mx-auto">
                        <div className="absolute top-4 right-4">
                            <button
                                className="text-red-500 hover:text-red-600 hover:cursor-pointer"
                                onClick={onClose}
                            >
                                <MdClose size={20} />
                            </button>
                        </div>
                        <h1 className="text-lg font-bold">
                            Ajouter des produits
                        </h1>
                        <p className="mt-2 text-sm text-gray-600">
                            Cliquez sur les produits que vous souhaitez ajouter
                            à la commande
                        </p>
                        <div className="mt-3 flex h-[35px] flex-grow items-center rounded-xl bg-lightPrimary text-sm text-gray-600">
                            <SearchIcon />
                            <input
                                type="text"
                                placeholder="Rechercher un produit, une référence, ..."
                                onChange={(e) => setSearch(e.target.value)}
                                className="mtblock w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none"
                            />
                        </div>
                        <div className="flex mt-3 ml-4 gap-4">
                            <p
                                className={`text-xs ${
                                    tab === 'all'
                                        ? 'text-toola-500 underline'
                                        : 'text-toola-500/80'
                                } hover:underline hover:cursor-pointer`}
                                onClick={() => setTab('all')}
                            >
                                Tout afficher
                            </p>
                            <p
                                className={`text-xs ${
                                    tab === 'recent-order'
                                        ? 'text-toola-500 underline'
                                        : 'text-toola-500/80'
                                } hover:underline hover:cursor-pointer`}
                                onClick={() => setTab('recent-order')}
                            >
                                Récemment commandé
                            </p>
                            <p
                                className={`text-xs ${
                                    tab === 'custom-price'
                                        ? 'text-toola-500 underline'
                                        : 'text-toola-500/80'
                                } hover:underline hover:cursor-pointer`}
                                onClick={() => setTab('custom-price')}
                            >
                                Avec prix personnalisés
                            </p>
                        </div>
                        <div className="mx-1 mt-3 grid grid-cols-1 gap-3 overflow-y-auto">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                                <thead className="text-gray-700 bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-2 py-3">
                                            Produit
                                        </th>
                                        <th scope="col" className="px-2 py-3">
                                            Prix HT
                                        </th>
                                        <th scope="col" className="px-2 py-3">
                                            Quantité
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customProducts.map((item, index) => (
                                        <tr
                                            className="bg-white border-b text-gray-800 border-gray-200 hover:bg-gray-100 cursor-pointer"
                                            key={`custom-${item._id}`}
                                            onClick={() => {}}
                                        >
                                            <td className="px-2 py-2">
                                                {/* Created 10 minutes ago ? Live ping */}

                                                <p className="flex my-auto gap-5">
                                                    <input
                                                        type="text"
                                                        className="px-2 w-[200px] h-[30px] border border-gray-200 rounded-xl"
                                                        placeholder="Nom du produit/service"
                                                        value={item.name}
                                                        onChange={(e) =>
                                                            handleCustomerProductChange(
                                                                index,
                                                                'name',
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                    <div className="flex">
                                                        <input
                                                            type="number"
                                                            placeholder="TVA (%)"
                                                            className="px-2 text-xs w-[75px] h-[30px] border border-gray-200 rounded-xl"
                                                            value={item.vat}
                                                            onChange={(e) =>
                                                                handleCustomerProductChange(
                                                                    index,
                                                                    'vat',
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </p>
                                                <div className="flex my-auto gap-2">
                                                    {item?.isRecent && (
                                                        <p className="flex gap-1 my-auto text-sm text-pink-500">
                                                            <MdOutlineFavorite />
                                                        </p>
                                                    )}
                                                </div>
                                            </td>
                                            <td className="px-2 py-3 gap-2">
                                                <input
                                                    type="number"
                                                    className="px-2 w-[70px] h-[30px] border border-gray-200 rounded-xl"
                                                    placeholder="0"
                                                    value={item.price}
                                                    onChange={(e) =>
                                                        handleCustomerProductChange(
                                                            index,
                                                            'price',
                                                            e.target.value,
                                                        )
                                                    }
                                                />
                                                €
                                            </td>
                                            <td className="px-2 py-3">
                                                <input
                                                    type="number"
                                                    className="px-2 w-[80px] h-[30px] border border-gray-200 rounded-xl"
                                                    placeholder="0"
                                                    onChange={(e) =>
                                                        handleItemClicked(
                                                            item,
                                                            parseInt(
                                                                e.target.value,
                                                            ),
                                                        )
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    {products &&
                                        products
                                            .filter((i: any) =>
                                                searchProduct(i, search),
                                            )
                                            .filter((i: any) =>
                                                tab === 'recent-order'
                                                    ? i.isRecent
                                                    : tab === 'custom-price'
                                                      ? i.customPrice
                                                      : i,
                                            )
                                            .filter(
                                                (i: any) =>
                                                    !existingProducts.includes(
                                                        i._id,
                                                    ),
                                            ).length === 0 && (
                                            <tr
                                                className="bg-white border-b text-gray-800 border-gray-200 hover:bg-gray-100 cursor-pointer"
                                                key="no-product"
                                            >
                                                <td className="px-2 py-2">
                                                    Aucun produit trouvé
                                                </td>
                                                <td className="px-2 py-3"></td>
                                                <td className="px-2 py-3"></td>
                                            </tr>
                                        )}
                                    {products &&
                                        products
                                            .filter((i: any) =>
                                                searchProduct(i, search),
                                            )
                                            .filter((i: any) =>
                                                tab === 'recent-order'
                                                    ? i.isRecent
                                                    : tab === 'custom-price'
                                                      ? i.customPrice
                                                      : i,
                                            )
                                            .filter(
                                                (i: any) =>
                                                    !existingProducts.includes(
                                                        i._id,
                                                    ),
                                            )
                                            .map((item: any, index: number) => (
                                                <tr
                                                    className="bg-white border-b text-gray-800 border-gray-200 hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => {}}
                                                    key={`product-${item._id}`}
                                                >
                                                    <td className="px-2 py-2">
                                                        {/* Created 10 minutes ago ? Live ping */}

                                                        <p className="flex my-auto">
                                                            {item.name}
                                                        </p>
                                                        <div className="flex my-auto gap-2">
                                                            <p className="text-xs text-gray-700">
                                                                #{item.ref}
                                                            </p>
                                                            {item?.isRecent && (
                                                                <p className="flex gap-1 my-auto text-sm text-pink-500">
                                                                    <MdOutlineFavorite />
                                                                </p>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="px-2 py-3">
                                                        {item?.price} €
                                                    </td>
                                                    <td className="px-2 py-3">
                                                        <input
                                                            type="number"
                                                            className="px-2 w-[80px] h-[30px] border border-gray-200 rounded-xl"
                                                            placeholder="0"
                                                            value={
                                                                selectedProducts.find(
                                                                    (i) =>
                                                                        i.productId ===
                                                                        item._id,
                                                                )?.quantity
                                                            }
                                                            onChange={(e) =>
                                                                handleItemClicked(
                                                                    item,
                                                                    parseInt(
                                                                        e.target
                                                                            .value,
                                                                    ),
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-3 flex gap-1">
                            <MdAddBox
                                size={15}
                                className="flex my-auto text-toola-500"
                            />
                            <p
                                className="flex text-sm my-auto text-toola-500 hover:cursor-pointer hover:text-toola-600"
                                onClick={() => {
                                    setCustomProducts((prev) => [
                                        ...prev,
                                        {
                                            name: '',
                                            price: 0,
                                            _id: `custom-${generateUUID()}`,
                                        },
                                    ]);
                                }}
                            >
                                Ajouter une ligne personnalisée
                            </p>
                        </div>
                        <div className="mt-5 flex gap-2">
                            <button
                                className="linear col-span-12 ml-3 flex items-center justify-center rounded-xl bg-[#0260CB] bg-[] px-3 py-3 text-xs font-medium text-white hover:bg-[#01499b] active:bg-[#0260CB] sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                onClick={() => {
                                    addProducts(selectedProducts);
                                    setSelectedProducts([]);
                                    setCustomProducts([]);
                                }}
                            >
                                Ajouter
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AddProductsModal;
