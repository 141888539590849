import axios from 'axios';
import { errorHandler } from './utils';
import env from 'variables/config';

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    };
};

export const getIngredient = async (id: string) => {
    const res = await axios.get(`/ingredients/${id}`, config()).catch((err) => {
        return null;
    });
    if (!res) return null;
    return res.data;
};

export const deleteIngredient = async (id: string) => {
    const res: any = await axios
        .delete(`/ingredients/${id}`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const updateIngredient = async (ingredient: any) => {
    const id = ingredient._id;
    delete ingredient._id;
    const rest = await axios
        .put(`/ingredients/${id}`, ingredient, config())
        .catch(errorHandler);
    return rest.data;
};
