import Card from 'components/card';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

function transformDataForScatterChart(allProductsMargin: any) {
    if (!allProductsMargin || !allProductsMargin.length) return [];
    const result = [
        {
            name: 'Products',
            data: allProductsMargin.map((product: any) => ({
                y: product.purchasePrice,
                x: product.percentage,
                name: product.name,
            })),
        },
    ];
    return result;
}

function Scatter(props: { title: string; data: any }) {
    const { title, data } = props;

    const [state, setState] = useState(null);

    useEffect(() => {
        if (!data || !data.length) return;
        setState({
            series: transformDataForScatterChart(data),
            options: {
                chart: {
                    height: 350,
                    type: 'scatter',
                    zoom: { enabled: true, type: 'xy' },
                },
                tooltip: {
                    custom: function ({ seriesIndex, dataPointIndex, w }: any) {
                        return `<div class="p-2 bg-white dark:bg-gray-800 rounded-lg shadow-md">
                            <p class="text-xs font-bold text-navy-700 dark:text-white">
                                ${w.config.series[seriesIndex].data[dataPointIndex].name}
                            </p>
                            <p class="text-sm text-gray-500">
                                Coût de revient: ${w.config.series[seriesIndex].data[dataPointIndex].y} €
                            </p>
                            <p class="text-sm text-gray-500">
                                Taux de marque: ${w.config.series[seriesIndex].data[dataPointIndex].x} %
                            </p>
                        </div>`;
                    },
                },
                xaxis: {
                    tickAmount: 5,
                    labels: {
                        formatter: (val: any) => parseFloat(val).toFixed(2),
                    },
                    title: { text: 'Taux de marque (%)' },
                },
                yaxis: {
                    tickAmount: 1,
                    title: { text: 'Coût de revient (€)' },
                    labels: {
                        formatter: (val: any) => parseInt(val),
                    },
                },
            },
        });
    }, [data]);

    return (
        <Card extra={'p-5 text-dm lg:h-full max-h-[350px] border'}>
            <h4 className="ml-px text-base font-bold text-navy-700 dark:text-white">
                {title}
            </h4>
            {state && (
                <div id="chart">
                    <ReactApexChart
                        options={state.options as any}
                        series={state.series}
                        type="scatter"
                        height={250}
                    />
                </div>
            )}
            {!data && (
                <div className="flex justify-center items-center h-96">
                    <p className="text-lg text-gray-500">Chargement...</p>
                </div>
            )}
            {data && data.length === 0 && (
                <div className="flex justify-center items-center h-96">
                    <p className="text-base text-gray-500">
                        Aucun produit avec prix d'achat et de vente indiqué
                    </p>
                </div>
            )}
            <div id="html-dist"></div>
        </Card>
    );
}

export default Scatter;
