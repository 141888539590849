import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import TransactionTable from './components/transactionTable';
import { getPaginatedTransactions } from 'interfaces/invoice';
import Pagination from 'components/pagination/Pagination';

const ListTransactions = () => {
    const nav = useNavigate();
    const params = useParams();
    const [transactions, setTransactions] = useState(null);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('all');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [invoicesMeta, setInvoicesMeta] = useState(null);

    const fetchTransactions = async (
        page: number,
        limit: number,
        search: string = null,
        status: string = null,
    ) => {
        const month = Number(params.month);
        const year = Number(params.year);
        const res = await getPaginatedTransactions(
            limit,
            page,
            month,
            year,
            search,
            status,
        );
        console.log(res.data);
        if (res && res.data) {
            setTransactions(res.data.data);
            setInvoicesMeta(res.data.meta);
            setPage(page);
            setLimit(limit);
        }
    };

    const onFilter = async (newSearch: string, newStatus: string) => {
        if (newSearch === '') setSearch('');
        if (newSearch) setSearch(newSearch);
        if (newStatus) {
            fetchTransactions(1, limit, search, newStatus);
            setStatus(newStatus);
        }
    };

    const onRefresh = () => {
        fetchTransactions(page, limit, search, status);
    };

    // Update filters
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchTransactions(1, limit, search, status);
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    // Default init
    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            nav('/login');
        }
        fetchTransactions(page, limit, search, status);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mt-4 grid h-full w-full grid-cols-1 gap-5 xl:mt-3">
                <div className="col-span-12 h-full w-full rounded-[20px]">
                    {transactions && (
                        <TransactionTable
                            tableData={transactions}
                            invoicesMeta={invoicesMeta}
                            onFilter={(search: string, status: string) =>
                                onFilter(search, status)
                            }
                            onRefresh={onRefresh}
                        />
                    )}
                </div>

                {/* pagination */}
                <Pagination
                    extra="col-span-12"
                    text={false}
                    type="factures"
                    maxItems={invoicesMeta ? invoicesMeta.total : 12}
                    itemsPerPage={[10, 20, 30, 40, 50]}
                    page={page}
                    onChange={(page: number, limit: number) =>
                        fetchTransactions(page, limit, search, status)
                    }
                />
            </div>
        </>
    );
};

export default ListTransactions;
