import { useEffect } from 'react';

import { dateToFrench, dateToFrenchShort } from 'utils/date';

const MyUsersList = (props: { deliveryPersons: any; onDelete: any }) => {
    const { deliveryPersons, onDelete } = props;

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <table className="w-full">
            <thead>
                <tr key={0} className="!border-px !border-gray-400">
                    <th
                        key={1}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] w-full sm:w-[15%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Utilisateur
                        </p>
                    </th>
                    <th
                        key={2}
                        colSpan={0}
                        onClick={() => {}}
                        className="hidden sm:table-cell mt-[20px] w-[15%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Créé le
                        </p>
                    </th>
                    <th
                        key={3}
                        colSpan={0}
                        onClick={() => {}}
                        className="hidden sm:table-cell mt-[20px] w-[20%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Status
                        </p>
                    </th>
                    <th
                        key={5}
                        colSpan={0}
                        onClick={() => {}}
                        className="hidden sm:table-cell mt-[20px] w-[45%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Permissions
                        </p>
                    </th>
                    <th
                        key={6}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] w-[80px] sm:w-[5%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Actions
                        </p>
                    </th>
                </tr>
            </thead>
            <tbody>
                {deliveryPersons.map((deliveryPerson: any, index: number) => (
                    <tr
                        key={`${deliveryPerson.name}-${index}`}
                        className="h-[64px]"
                    >
                        <td
                            key={`${deliveryPerson.name}-${index}-name`}
                            className="border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            <div className="hidden sm:table-cell">
                                <p className="text-sm text-navy-700 dark:text-white xl:leading-3">
                                    {deliveryPerson.fullName}
                                </p>
                                <p className="font-base mt-[2px] text-gray-600">
                                    {deliveryPerson.email}
                                </p>
                            </div>
                            <div className="sm:hidden">
                                <p className="text-sm font-bold text-navy-700 dark:text-white xl:leading-3">
                                    {deliveryPerson.name}
                                </p>
                                <p className="font-sm text-gray-600">
                                    Inscrit le{' '}
                                    {dateToFrench(
                                        deliveryPerson.createdAt,
                                    ).toLocaleLowerCase()}
                                    <br />
                                </p>
                            </div>
                        </td>
                        <td
                            key={`${deliveryPerson.name}-${index}-date`}
                            className="hidden sm:table-cell border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {dateToFrenchShort(
                                    deliveryPerson.createdAt,
                                ).toLocaleLowerCase()}
                            </p>
                        </td>
                        <td
                            key={`${deliveryPerson.name}-${index}-deliveries`}
                            className="hidden sm:table-cell border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            {true ? (
                                <p className="text-xs text-green-500 font-semibold p-1 rounded-xl dark:text-white">
                                    Ajouté
                                </p>
                            ) : (
                                <p className="text-xs text-orange-500 font-semibold p-1 rounded-xl dark:text-white">
                                    Invitation envoyée
                                </p>
                            )}
                        </td>
                        <td
                            key={`${deliveryPerson.name}-${index}-state`}
                            className="hidden sm:table-cell border-t text-sm dark:border-white/10 px-[10px] py-0"
                        >
                            <div className="mt-2 flex gap-5">
                                {/* {[
                                    {
                                        display: 'Commandes & CRM',
                                        value: 'check',
                                    },
                                    { display: 'Stock', value: 'cash' },
                                    {
                                        display: 'Livraisons',
                                        value: 'transfer',
                                    },
                                    {
                                        display: 'Facturation',
                                        value: 'credit-card',
                                    },
                                ].map((option, index) => (
                                    <div className="flex gap-2">
                                        <p className="text-xs mb-1">
                                            {option.display}
                                        </p>
                                        <input
                                            key={`checkbox-${index}`}
                                            id={option.value}
                                            type="checkbox"
                                            checked={true}
                                            onChange={() => {}}
                                            className="w-4 h-4 text-toola-500 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        ></input>
                                    </div>
                                ))} */}
                                Accès total
                            </div>
                            <p className="font-base text-gray-600">
                                {deliveryPerson.status !== 'available' &&
                                    deliveryPerson.live &&
                                    'Tournée : ' +
                                        deliveryPerson.live.deliveryTourName}
                            </p>
                            <p className="font-base text-gray-600">
                                {deliveryPerson.status !== 'available' &&
                                    deliveryPerson.live &&
                                    'Clients livrés : ' +
                                        deliveryPerson.live.deliveredOrders +
                                        '/' +
                                        deliveryPerson.live.totalOrders}
                            </p>
                        </td>
                        <td
                            key={`${deliveryPerson.name}-${index}-turns`}
                            className="hidden sm:table-cell border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            {/* <SearchCheckboxSelector
                                        allSelectionText="Toutes les tournées"
                                        options={deliveryTours}
                                        deliveryPersonTours={
                                            deliveryPerson.authorizedTours
                                        }
                                        displayKey="name"
                                        onSelect={(optionId: string) =>
                                            setAuthorizedTours(
                                                deliveryPerson,
                                                optionId,
                                            )
                                        }
                                    /> */}
                        </td>
                        <td
                            key={`${deliveryPerson.name}-${index}-actions`}
                            className="border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            <button
                                className="linear col-span-3 flex items-center justify-center rounded-xl bg-red-400 px-3 py-2 text-sm font-medium text-white hover:bg-red-500 active:bg-red-600"
                                onClick={() => onDelete(deliveryPerson._id)}
                            >
                                Retirer
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default MyUsersList;
