import React, { useEffect } from 'react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import invoiceImg from 'assets/img/invoice.png';
import SearchIcon from 'components/icons/SearchIcon';
import SearchSelector from 'components/fields/SearchSelector';
import EnterPayement from 'views/invoicing/list-invoices/components/EnterPayement';
import ExtraMenu from './extraMenu';
import PaiementExportModal from 'views/invoicing/list-invoices/components/PaiementExportModal';

const columnHelper = createColumnHelper<any>();

// const columns = columnsDataCheck;
export default function TransactionTable(props: {
    tableData: any;
    invoicesMeta: any;
    onFilter: any;
    onRefresh: any;
}) {
    const { onFilter } = props;

    const [sorting, setSorting] = React.useState<SortingState>([]);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [data, setData] = React.useState(() => []);
    const [status, setStatus] = React.useState('all');
    const [search, setSearch] = React.useState('');

    const {
        isOpen: isPaiementExportModalOpen,
        onOpen: onPaiementExportModalOpen,
        onClose: onPaiementExportModalClose,
    } = useDisclosure();

    useEffect(() => {
        const tableData = props.tableData.map((invoice: any) => {
            return {
                ...invoice,
            };
        });
        setData(tableData);
    }, [props.tableData]);

    const columns = [
        columnHelper.accessor('transaction.date', {
            id: 'transaction.date',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Date de règlement
                </p>
            ),
            cell: (info) => (
                <>
                    <p className="text-sm text-navy-700 dark:text-white">
                        {info
                            .getValue()
                            .split('T')[0]
                            .split('-')
                            .reverse()
                            .join('/')}
                    </p>
                </>
            ),
        }),
        columnHelper.accessor('invoice.invoiceNumber', {
            id: 'invoice.invoiceNumber',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    N° Facture
                </p>
            ),
            cell: (info) => (
                <>
                    <p
                        className="ml-1 text-sm text-toola-500 hover:underline hover:cursor-pointer"
                        onClick={() => {
                            setSearch(info.getValue());
                            onFilter(info.getValue(), null);
                        }}
                    >
                        #{info.getValue()}
                    </p>
                </>
            ),
        }),
        columnHelper.accessor('invoice.customerCompany', {
            id: 'invoice.customerCompany',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Client
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('transaction.reference', {
            id: 'transaction.reference',
            header: () => (
                <p className="text-sm text-gray-600 dark:text-white">
                    Ref. paiement
                </p>
            ),
            cell: (info) => (
                <div className="flex items-center text-sm text-navy-700">
                    {info.getValue() || '--'}
                </div>
            ),
        }),
        columnHelper.accessor('transaction.paymentMethod', {
            id: 'transaction.paymentMethod',
            header: () => (
                <p className="text-sm text-gray-600 dark:text-white">
                    Mode de paiement
                </p>
            ),
            cell: (info) => (
                <div className="flex items-center text-sm text-navy-700">
                    {info.getValue() || 'Non renseigné'}
                </div>
            ),
        }),
        columnHelper.accessor('invoice.totalTTC', {
            id: 'invoice.totalTTC',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Total TTC
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue() !== 0
                        ? info.getValue()?.toFixed(2)
                        : '-- --'}{' '}
                    €
                </p>
            ),
        }),
        columnHelper.accessor('transaction.amount', {
            id: 'transaction.amount',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Règlement
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue() !== 0
                        ? info.getValue()?.toFixed(2)
                        : '-- --'}{' '}
                    €
                </p>
            ),
        }),
        // columnHelper.accessor('_id', {
        //     id: '_id',
        //     header: () => {},
        //     cell: (info) => (
        //         <div className="flex items-center gap-5">
        //             <InvoiceMenu
        //                 disabled={['canceled', 'credit-note'].includes(
        //                     data.find(
        //                         (invoice: any) =>
        //                             invoice._id === info.getValue(),
        //                     )?.status,
        //                 )}
        //                 isLastItem={
        //                     data.findIndex(
        //                         (invoice: any) =>
        //                             invoice._id === info.getValue(),
        //                     ) ===
        //                     data.length - 1
        //                 }
        //                 invoice={data.find(
        //                     (invoice: any) => invoice._id === info.getValue(),
        //                 )}
        //                 openHistory={() => {}}
        //                 updateInvoiceStatus={() => {}}
        //                 createCreditNote={(id: string) => {}}
        //             />
        //         </div>
        //     ),
        // }),
    ]; // eslint-disable-next-line
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    return (
        <>
            <EnterPayement isOpen={isOpen} onClose={onClose} />
            <PaiementExportModal
                isOpen={isPaiementExportModalOpen}
                onClose={onPaiementExportModalClose}
                handleExport={() => {}}
            />
            <Card extra={'mt-1 w-full px-6 pb-6 sm:overflow-x-auto border'}>
                <div className="relative flex items-center justify-between pt-4">
                    <div className="text-xl font-bold text-navy-700 dark:text-white">
                        Règlements
                    </div>
                    <div className="flex items-center gap-4">
                        <div className="invisible lg:visible">
                            <button
                                className="my-auto h-10 text-toola-500 border-toola-500 border-2 rounded-xl px-2"
                                onClick={onOpen}
                            >
                                Pointer un paiement
                            </button>
                        </div>
                        <ExtraMenu
                            onOpen={onOpen}
                            onPaiementExportModalOpen={
                                onPaiementExportModalOpen
                            }
                        />
                    </div>
                </div>

                {/* Filters */}
                <div className="mt-2 grid grid-cols-12 gap-5">
                    <div className="mt-5 col-span-4">
                        <div className="flex h-[40px] flex-grow items-center rounded-xl bg-lightPrimary text-xs text-gray-600">
                            <SearchIcon />
                            <input
                                type="text"
                                placeholder="Client, N° Facture, Référence de paiement, ..."
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value || '');
                                    onFilter(e.target.value || '', null);
                                }}
                                className="block w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none"
                            />
                        </div>
                    </div>
                    {/* <div className="col-span-2 mb-2">
                        <SearchSelector
                            label="Méthode de paiement"
                            displayKey="name"
                            inputSmall={true}
                            value={status}
                            options={[
                                {
                                    _id: 'all',
                                    name: 'Tous',
                                },
                                {
                                    _id: 'cash',
                                    name: 'Espèces',
                                },
                                {
                                    _id: 'check',
                                    name: 'Chèque',
                                },
                                {
                                    _id: 'transfer',
                                    name: 'Virement',
                                },
                                {
                                    _id: 'credit-card',
                                    name: 'Carte bancaire',
                                },
                                {
                                    _id: 'direct-debit',
                                    name: 'Prélèvement',
                                },
                            ]}
                            onSelect={(e: any) => {
                                setStatus(e._id);
                                onFilter(null, e._id);
                            }}
                        />
                    </div> */}
                </div>

                <div className="mt-4 overflow-x-scroll xl:overflow-x-hidden">
                    <table className="w-full">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className="!border-px !border-gray-400"
                                >
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <th
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                onClick={header.column.getToggleSortingHandler()}
                                                className="cursor-pointer border-b border-gray-200 pt-4 pb-2 pr-4 text-start dark:border-white/30"
                                            >
                                                <div className="items-center justify-between text-xs text-gray-200">
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                    {{
                                                        asc: '',
                                                        desc: '',
                                                    }[
                                                        header.column.getIsSorted() as string
                                                    ] ?? null}
                                                </div>
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => {
                                return (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <td
                                                    key={cell.id}
                                                    className="min-w-[150px] border-white/0 py-3  pr-4"
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef
                                                            .cell,
                                                        cell.getContext(),
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {table.getRowModel().rows.length === 0 && (
                        <div className="col-span-12 mt-10">
                            <img
                                className="mx-auto flex h-[125px] w-[125px] items-center justify-center"
                                src={invoiceImg}
                                alt=""
                            />
                            <h4 className="col-span-12 my-auto mt-3 text-center text-2xl font-medium text-navy-700 dark:text-white">
                                Aucune facture disponible
                            </h4>
                            <p className="col-span-12 my-auto mt-3 text-center text-base text-navy-700 dark:text-white">
                                Les filtres appliqués ne correspondent à aucune
                                facture exitante.
                            </p>
                        </div>
                    )}
                </div>
            </Card>
        </>
    );
}
