import Card from 'components/card';
import SearchIcon from 'components/icons/SearchIcon';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SearchSelector from 'components/fields/SearchSelector';
import { productColorList } from 'utils/lib';
import { cleanStr } from 'utils/tools';
import ProductCard from './components/productCard';
import {
    getProductsVisibility,
    updateProductVisibility,
    updateProductVisibilityBulk,
} from 'interfaces/products';
import GoBack from 'components/actions/GoBack';
import { toast } from 'react-toastify';
import { useDisclosure } from '@chakra-ui/hooks';
import ActionMenu from './components/ActionMenu';
import SelectCustomersModal from './components/SelectCustomersModal';

const EditCustomerShopGlobaly = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [newState, setNewState] = useState(null);
    const [products, setProducts] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [meta, setMeta] = useState(null);
    const [productType, setproductType] = useState('all');
    const [category, setCategory] = useState('display');
    const [color, setColor] = useState('all');

    const initProducts = async (customerId: string) => {
        const res = await getProductsVisibility(customerId);
        if (res.error) {
            navigate('/auth/login');
            return;
        }
        const prods = res.products.map((e: any) => ({
            ...e,
            isSelected: false,
        }));
        setProducts(prods);
        setMeta(res.meta);
    };

    const updateVisibility = async (productId: string, customerId: string) => {
        const res = await updateProductVisibility(productId, customerId);
        if (res) {
            setProducts((prev: any) =>
                prev.map((e: any) =>
                    e._id === productId ? { ...e, visible: !e.visible } : e,
                ),
            );
        }
    };

    const getSelectionWording = (isSelected: boolean, type: string) => {
        const display: any = {
            brut: 'bruts',
            transformed: 'transformés',
            assembled: 'assemblés',
        };

        if (isSelected) {
            return `Sélectionner tous les produits ${display[type] ?? ''} ${
                category === 'display' ? 'vendables' : 'non vendables'
            }`;
        } else {
            return `Désélectionner tous les produits ${display[type] ?? ''} ${
                category === 'display' ? 'vendables' : 'non vendables'
            }`;
        }
    };

    const selectAllDisplayProducts = (isSelected: boolean) => {
        const productsToEdit = products
            .filter(
                (e: any) =>
                    cleanStr(e.name).includes(cleanStr(search)) ||
                    cleanStr(e.ref).includes(cleanStr(search)),
            )
            .filter((e: any) =>
                productType === 'all' ? true : e.productType === productType,
            )
            .filter((e: any) =>
                color === 'all' ? true : e.colorCode === color,
            )
            .filter(
                (e: any) =>
                    (category === 'display' && e.category !== 'hidden') ||
                    (category === 'hidden' && e.category === 'hidden'),
            );

        setProducts((prev: any) =>
            prev.map((e: any) =>
                productsToEdit.some((s: any) => s._id === e._id)
                    ? { ...e, isSelected }
                    : e,
            ),
        );
    };

    const editVisibility = async (
        newState: string,
        selectedCustomersIds: any,
    ) => {
        const selectedProducts = products?.filter((e: any) => e.isSelected);
        const selectedProductsIds = selectedProducts.map((e: any) => e._id);
        const res = await updateProductVisibilityBulk(
            selectedProductsIds,
            selectedCustomersIds,
            newState,
        );
        if (res) {
            toast.success('Les produits ont été mis à jour');
            if (newState === 'salable' || newState === 'not-salable') {
                setProducts((prev: any) =>
                    prev.map((e: any) =>
                        selectedProductsIds.includes(e._id)
                            ? {
                                  ...e,
                                  category:
                                      newState === 'salable'
                                          ? 'display'
                                          : 'hidden',
                              }
                            : e,
                    ),
                );
            } else {
                const isAllCustomers = selectedCustomersIds.includes('all');
                let isVisible = false;
                if (newState === 'visible' && isAllCustomers) isVisible = true;
                setProducts((prev: any) =>
                    prev.map((e: any) =>
                        selectedProductsIds.includes(e._id)
                            ? { ...e, visible: isVisible }
                            : e,
                    ),
                );
            }
        }
    };

    useEffect(() => {
        const selectedProducts = products?.filter((e: any) => e.isSelected);
        for (let i = 0; i < selectedProducts?.length; i++) {
            console.log(`${selectedProducts[i].name} is selected`);
        }
    }, [products]);

    const SkelettonProduct = () => {
        return (
            <div
                className={`animate-pulse relative flex items-center rounded-[20px] bg-clip-border border px-[18px] py-4 shadow-3xl shadow-shadow-500 hover:cursor-pointer dark:!bg-navy-800 dark:shadow-none`}
            >
                <div className="grid grid-cols-12">
                    <div className="-bottom-12 col-span-4 flex h-[87px] w-[87px] items-center justify-center rounded-full">
                        <div className="h-[80px] w-[80px] rounded-xl bg-gray-200"></div>
                    </div>
                    <div className="col-span-8 ml-4">
                        <div className="mt-2 w-[99%] h-4 bg-navy-700/50 rounded-full"></div>
                        <div className="my-1 w-[60px] h-2 bg-gray-600 rounded-full"></div>
                        <p className=" mb-1 text-sm font-medium text-gray-600">
                            <div className="w-5 h-3 bg-gray-600 rounded-full"></div>
                        </p>
                        <div className="w-[60px] h-5 bg-gray-600  rounded-full">
                            {' '}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        initProducts('all');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <GoBack />
            <SelectCustomersModal
                isOpen={isOpen}
                onClose={onClose}
                newState={newState}
                onAction={(selectedCustomersIds: any) => {
                    editVisibility(newState, selectedCustomersIds);
                    onClose();
                }}
            />
            <Card
                extra={
                    'mt-3 bg-white w-full h-full sm:overflow-auto px-6 border'
                }
            >
                {/* Search bar */}
                <div className="flex max-w-full justify-between items-center gap-3 rounded-xl pt-3">
                    <div className="flex h-[38px] w-[400px] rounded-xl bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white lg:col-span-4">
                        <SearchIcon />
                        <input
                            type="text"
                            placeholder="Recherche...."
                            onChange={(e) => setSearch(e.target.value)}
                            className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
                        />
                    </div>
                    <div>
                        <p className="flex mt-5 justify-end">
                            <span className="font-bold">
                                {meta?.customer?.name}
                            </span>{' '}
                            {products?.filter((p: any) => p.isSelected).length}{' '}
                            produits sélectionnés
                        </p>
                        <p
                            className="flex justify-end hover:cursor-pointer hover:underline text-xs text-toola-500 my-auto gap-1"
                            onClick={() =>
                                window.open('/customers-visibility', '_blank')
                            }
                        >
                            Configurer pour un client
                        </p>
                    </div>
                </div>
                {/* Product category */}
                <div className="flex justify-between">
                    <div className="mt-4 flex gap-2 overflow-x-auto h-7">
                        <p className="flex my-auto text-sm underline">
                            Statut:{' '}
                        </p>
                        <p
                            className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                'display' === category && 'font-bold'
                            }`}
                            onClick={() => setCategory('display')}
                        >
                            Vendable
                        </p>
                        <p
                            className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                'hidden' === category && 'font-bold'
                            }`}
                            onClick={() => setCategory('hidden')}
                        >
                            Non vendable
                        </p>
                    </div>
                </div>
                {/* Product type and colors */}
                <div className="flex justify-between">
                    <div className="mt-4 flex gap-2 overflow-x-auto h-7">
                        <p className="flex my-auto text-sm underline">
                            Type de produits:{' '}
                        </p>
                        <p
                            className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                'all' === productType && 'font-bold'
                            }`}
                            onClick={() => setproductType('all')}
                        >
                            Tout
                        </p>
                        <p
                            className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                'brut' === productType && 'font-bold'
                            }`}
                            onClick={() => setproductType('brut')}
                        >
                            Produit brut
                        </p>
                        <p
                            className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                'transformed' === productType && 'font-bold'
                            }`}
                            onClick={() => setproductType('transformed')}
                        >
                            Produit transformé
                        </p>
                        <p
                            className={`bg-white rounded-xl py-1 px-3 text-sm cursor-pointer border ${
                                'assembled' === productType && 'font-bold'
                            }`}
                            onClick={() => setproductType('assembled')}
                        >
                            Produit assemblé
                        </p>
                    </div>
                    <div className="pt-3 z-50 w-[180px] text-sm">
                        <SearchSelector
                            displayKey="name"
                            options={[
                                {
                                    name: 'Toutes les couleurs',
                                    id: 'all',
                                    _id: 'all',
                                },
                                ...productColorList.filter(
                                    (e: any) =>
                                        products &&
                                        products.some(
                                            (s: any) => s.colorCode === e.id,
                                        ),
                                ),
                            ]}
                            value={color}
                            onSelect={(e: any) => {
                                setColor(e.id);
                            }}
                            defaultKey="id"
                            canCreate={false}
                            createNew={() => {}}
                            inputSmall={true}
                        />
                    </div>
                </div>
                {/* Select/Unselect all */}
                <div className="flex mt-3 justify-between">
                    <div className="flex gap-4">
                        <p
                            className="text-sm text-toola-500 hover:cursor-pointer hover:underline"
                            onClick={() => selectAllDisplayProducts(true)}
                        >
                            {getSelectionWording(true, productType)}
                        </p>
                        <p
                            className="mx-2 text-sm text-toola-500 hover:cursor-pointer hover:underline"
                            onClick={() => selectAllDisplayProducts(false)}
                        >
                            {getSelectionWording(false, productType)}
                        </p>
                    </div>
                    <ActionMenu
                        transparent={false}
                        vertical={false}
                        editVisibility={editVisibility}
                        openModal={(newState: string) => {
                            setNewState(newState);
                            onOpen();
                        }}
                        quantitySelected={
                            products?.filter((p: any) => p.isSelected).length
                        }
                    />
                </div>
                {/* Skelleton Loader */}
                {!products && (
                    <div className="z-0 col-span-12 mb-10 mt-2 grid h-full w-full grid-cols-1 gap-3 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                        {[
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16,
                        ].map((item, index) => (
                            <SkelettonProduct key={index} />
                        ))}
                    </div>
                )}
                {/* Items list */}
                <div className="z-0 col-span-12 mb-10 mt-2 grid h-full w-full grid-cols-1 gap-3 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    {products &&
                        products
                            .filter(
                                (e: any) =>
                                    cleanStr(e.name).includes(
                                        cleanStr(search),
                                    ) ||
                                    cleanStr(e.ref).includes(cleanStr(search)),
                            )
                            .filter((e: any) =>
                                productType === 'all'
                                    ? true
                                    : e.productType === productType,
                            )
                            .filter((e: any) =>
                                color === 'all' ? true : e.colorCode === color,
                            )
                            .filter(
                                (e: any) =>
                                    (category === 'display' &&
                                        e.category !== 'hidden') ||
                                    (category === 'hidden' &&
                                        e.category === 'hidden'),
                            )
                            .reverse()
                            .map((item: any, index: number) => (
                                <ProductCard
                                    id={item._id}
                                    visible={item.visible}
                                    isSelected={item.isSelected}
                                    setSelected={() =>
                                        setProducts((prev: any) =>
                                            prev.map((e: any) =>
                                                e._id === item._id
                                                    ? {
                                                          ...e,
                                                          isSelected:
                                                              !e.isSelected,
                                                      }
                                                    : e,
                                            ),
                                        )
                                    }
                                    key={index}
                                    name={item.name}
                                    type={item.type}
                                    alert={item.alert}
                                    price={item.price}
                                    img={item.imageUrl}
                                    productType={item.productType}
                                    category={item.category}
                                    reference={item.ref}
                                    color={item.colorCode}
                                    updateVisibility={() =>
                                        updateVisibility(item._id, params.id)
                                    }
                                />
                            ))}
                    {products &&
                        products
                            .filter(
                                (e: any) =>
                                    cleanStr(e.name).includes(
                                        cleanStr(search),
                                    ) ||
                                    cleanStr(e.ref).includes(cleanStr(search)),
                            )
                            .filter((e: any) =>
                                productType === 'all'
                                    ? true
                                    : e.productType === productType,
                            )
                            .filter((e: any) =>
                                color === 'all' ? true : e.colorCode === color,
                            )
                            .filter(
                                (e: any) =>
                                    (category === 'display' &&
                                        e.category !== 'hidden') ||
                                    (category === 'hidden' &&
                                        e.category === 'hidden'),
                            ).length === 0 && (
                            <div className="mt-3 flex justify-center col-span-12">
                                <h3>Aucun produit trouvé avec ces critères</h3>
                            </div>
                        )}
                </div>
            </Card>
        </>
    );
};

export default EditCustomerShopGlobaly;
