import axios from 'axios';
import { errorHandler } from './utils';
import env from 'variables/config';

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    };
};

export const getApiVersion = async (): Promise<{
    data: any;
    error: any;
}> => {
    const res: any = await axios.get(`/`, config()).catch(errorHandler);
    return res?.data?.version;
};

export const getFile = async (
    name: string,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .get(`/user/files?fileName=${name}`, config())
        .catch(() => {});
    return res;
};

export const getMyInfo = async (): Promise<{ data: any; error: any }> => {
    const res: any = await axios.get('/user/me', config()).catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const updateMyInfo = async (
    data: any,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .put('/user/', data, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const uploadCompanyLogo = async (
    data: any,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .post(`/user/upload`, data, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getDashboardAnalytics = async (
    startDate: string,
    endDate: string,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .get(
            `/user/dashboard?startDate=${startDate}&endDate=${endDate}`,
            config(),
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getDashboardAnalyticsV2 = async (): Promise<{
    data: any;
    error: any;
}> => {
    const res: any = await axios
        .get(`/user/dashboard/v2`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getInactiveCustomers = async (): Promise<{
    data: any;
    error: any;
}> => {
    const res: any = await axios
        .get(`/user/dashboard/inactive-customer`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getCompanyAccounts = async (): Promise<{
    data: any;
    error: any;
}> => {
    const res: any = await axios
        .get(`/user/accounts`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const createCompanyUser = async (email: string) => {
    const res: any = await axios
        .post(`/user/accounts`, { email }, config())
        .catch(() => {
            return false;
        });
    if (!res) return false;
    return true;
};

export const deleteCompanyUser = async (id: string) => {
    const res: any = await axios
        .delete(`/user/accounts/${id}`, config())
        .catch(() => {
            return false;
        });
    if (!res) return false;
    return true;
};

export const updatePassword = async (
    data: any,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .put('/user/password', data, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};
