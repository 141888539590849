import { IoMdTrendingUp, IoMdTrendingDown, IoIosRemove } from 'react-icons/io';
import { useEffect, useState } from 'react';

import Card from 'components/card';
import { getDeliveryTours } from 'interfaces/deliveryTour';
import { getOrdersDataByDate } from 'interfaces/order';
import LoadingTableLine from './loadingTableLine';
import Pagination from 'components/pagination/Pagination';
import InputField from 'components/fields/InputField';
import { getMyInfo } from 'interfaces/user';
import { MdPrint } from 'react-icons/md';

const ProductSummary = () => {
    const [user, setUser] = useState<any>(null);
    const [selectedTag, setSelectedTag] = useState(null);
    const [data, setData] = useState(null);
    const [dataMeta, setDataMeta] = useState(null);
    const [page, setPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(5);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDeliveryTour, setSelectedDeliveryTour] = useState(null);
    const [deliveryTours, setDeliveryTours] = useState<any>([]);
    const isFullyLoaded = data && dataMeta && deliveryTours;

    const initDeliveryTours = async () => {
        const res = await getDeliveryTours(false);
        if (res) {
            setDeliveryTours(res.data);
            return;
        }
    };

    const fetchOrdersData = async (
        page: number,
        limit: number,
        date: string = null,
        deliveryTourId: string = null,
        tag: string = null,
    ) => {
        setLimitPerPage(limit);
        setData(null);

        const today = new Date();
        const tdayYear = today.getFullYear();
        const tdayMonth = today.getMonth() + 1;
        const tdayDay =
            today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
        const newDate = date ? date : `${tdayYear}-${tdayMonth}-${tdayDay}`;

        const res = await getOrdersDataByDate(
            newDate,
            deliveryTourId ? deliveryTourId : '',
            tag ? tag : '',
            page,
            limit,
        );
        if (res) {
            setData(res);
            setDataMeta(res.meta);
            setSelectedDate(newDate);
            setSelectedDeliveryTour(deliveryTourId ? deliveryTourId : '');
            setSelectedTag(tag ? tag : '');
            setPage(page);
        }
    };

    const handleDateChange = (e: any) => {
        setPage(1);
        fetchOrdersData(
            page,
            limitPerPage,
            e.target.value,
            selectedDeliveryTour,
            selectedTag,
        );
    };

    const handleDeliveryTourneyChange = (e: any) => {
        setPage(1);
        fetchOrdersData(
            page,
            limitPerPage,
            selectedDate,
            e.target.value,
            selectedTag,
        );
    };

    const handleTagChange = (e: any) => {
        setPage(1);
        fetchOrdersData(
            page,
            limitPerPage,
            selectedDate,
            selectedDeliveryTour,
            e.target.value,
        );
    };

    const initUser = async () => {
        const me = await getMyInfo();
        if (me) {
            setUser(me.data);
        }
    };

    useEffect(() => {
        initDeliveryTours();
        initUser();
        fetchOrdersData(page, limitPerPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card
            extra={
                'relative w-full pb-[10px] pt-[10px] px-[29px] h-fit bg-blue-200 shadow shadow-3xl border'
            }
        >
            <div className="absolute top-4 right-4">
                <button
                    onClick={() =>
                        window.open('/print-order-summary', '_blank')
                    }
                    className="flex items-center gap-2 cursor-pointer"
                >
                    <MdPrint size={20} />
                </button>
            </div>
            <p className="text-lg font-medium">
                Récapitulatif des produits à livrer
            </p>
            <div className="flex gap-[10px] flex-col sm:flex-row">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-[5px] items-center">
                    <InputField
                        label=""
                        placeholder="01/01/2021"
                        id="deliveryDate"
                        type="date"
                        defaultValue={new Date().toISOString().slice(0, 10)}
                        onChange={handleDateChange}
                        inputSmall={true}
                    />
                    <div
                        className={`w-full sm:mt-2 sm:w-[180px] h-[35px] rounded-xl border bg-white border-gray-200 px-2.5 py-1 text-sm outline-none`}
                    >
                        <select
                            className={`flex mt-[2px] mb-0 w-full rounded-xl border-none bg-white text-sm font-medium outline-none`}
                            name="data"
                            onChange={handleDeliveryTourneyChange}
                        >
                            <option value="">Toutes les tournées</option>
                            {deliveryTours &&
                                deliveryTours.map((deliveryTour: any) => {
                                    return (
                                        <option value={deliveryTour._id}>
                                            {deliveryTour.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                    <div
                        className={`w-full sm:w-[180px] sm:mt-2 h-[35px] rounded-xl border bg-white border-gray-200 px-2.5 py-1 text-sm outline-none`}
                    >
                        <select
                            className={`flex mt-[2px] mb-0 w-full rounded-xl border-none bg-white text-sm font-medium outline-none`}
                            name="data"
                            onChange={handleTagChange}
                        >
                            <option value="">Tous les tags</option>
                            {user &&
                                user.tags.map((name: any) => {
                                    return <option value={name}>{name}</option>;
                                })}
                        </select>
                    </div>
                </div>
            </div>
            {isFullyLoaded ? (
                dataMeta.total > 0 ? (
                    <table className="w-full">
                        <thead>
                            <tr className="text-left h-[24px]">
                                <th
                                    className="pl-[10px] w-[50%] sm:w-[35%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                    rowSpan={2}
                                >
                                    Produit
                                </th>
                                <th
                                    className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                    rowSpan={2}
                                >
                                    Quantité
                                </th>
                                <th
                                    className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                    rowSpan={2}
                                >
                                    Prix total
                                </th>
                                <th
                                    className="w-[50%] sm:w-[35%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium text-center"
                                    colSpan={3}
                                >
                                    Fluctuations
                                </th>
                            </tr>
                            <tr>
                                <th className="text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7]">
                                    Jour
                                </th>
                                <th className="text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7]">
                                    Semaine
                                </th>
                                <th className="text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7]">
                                    Mois
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.data.map((data: any) => {
                                return (
                                    <tr className="text-left h-[24px] border-t border-[#E9EDF7] sm:border-none">
                                        <td className="text-xs sm:text-base pl-[10px] text-toola-800">
                                            {data.name}
                                            <div className="sm:hidden font-normal">
                                                {data.quantity +
                                                    ' ' +
                                                    data.unity}
                                                <br />
                                                {data.totalPrice % 1 === 0
                                                    ? data.totalPrice
                                                    : data.totalPrice.toFixed(
                                                          2,
                                                      )}{' '}
                                                €
                                            </div>
                                        </td>
                                        <td className="text-xs sm:text-base hidden sm:table-cell pl-[10px] text-toola-800">
                                            {data.quantity + ' ' + data.unity}
                                        </td>
                                        <td className="text-xs sm:text-base hidden sm:table-cell pl-[10px] text-toola-800">
                                            {data.totalPrice % 1 === 0
                                                ? data.totalPrice
                                                : data.totalPrice.toFixed(
                                                      2,
                                                  )}{' '}
                                            €
                                        </td>
                                        <td className="text-xs sm:text-base text-toola-800">
                                            <div className="flex gap-[5px] justify-between sm:max-w-[100px] mx-auto">
                                                {data.changeDay < 0 ? (
                                                    <IoMdTrendingDown className="text-base sm:text-2xl text-red-500" />
                                                ) : data.changeDay === 0 ? (
                                                    <IoIosRemove className="text-base sm:text-2xl text-yellow-500" />
                                                ) : (
                                                    <IoMdTrendingUp className="text-base sm:text-2xl text-green-500" />
                                                )}
                                                {data.changeDay > 500
                                                    ? '>500'
                                                    : data.changeDay < -500
                                                      ? '<-500'
                                                      : data.changeDay}
                                                %
                                            </div>
                                        </td>
                                        <td className="text-xs sm:text-base text-toola-800">
                                            <div className="flex gap-[5px] justify-between sm:max-w-[100px] mx-auto">
                                                {data.changeWeek < 0 ? (
                                                    <IoMdTrendingDown className="text-base sm:text-2xl text-red-500" />
                                                ) : data.changeWeek === 0 ? (
                                                    <IoIosRemove className="text-base sm:text-2xl text-yellow-500" />
                                                ) : (
                                                    <IoMdTrendingUp className="text-base sm:text-2xl text-green-500" />
                                                )}
                                                {data.changeWeek > 500
                                                    ? '>500'
                                                    : data.changeWeek < -500
                                                      ? '<-500'
                                                      : data.changeWeek}
                                                %
                                            </div>
                                        </td>
                                        <td className="text-xs sm:text-base text-toola-800">
                                            <div className="flex gap-[5px] justify-between sm:max-w-[100px] mx-auto">
                                                {data.changeMonth < 0 ? (
                                                    <IoMdTrendingDown className="text-base sm:text-2xl text-red-500" />
                                                ) : data.changeMonth === 0 ? (
                                                    <IoIosRemove className="text-base sm:text-2xl text-yellow-500" />
                                                ) : (
                                                    <IoMdTrendingUp className="text-base sm:text-2xl text-green-500" />
                                                )}
                                                {data.changeMonth > 500
                                                    ? '>500'
                                                    : data.changeMonth < -500
                                                      ? '<-500'
                                                      : data.changeMonth}
                                                %
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div className="w-full flex items-center justify-center h-[50px]">
                        <p className="text-gray-700">
                            Aucun produit à livrer pour cette date
                        </p>
                    </div>
                )
            ) : (
                <div>
                    <table className="w-full">
                        <thead>
                            <tr className="text-left h-[24px]">
                                <th
                                    className="pl-[10px] w-[50%] sm:w-[35%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                    rowSpan={2}
                                >
                                    Produit
                                </th>
                                <th
                                    className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                    rowSpan={2}
                                >
                                    Quantité
                                </th>
                                <th
                                    className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                    rowSpan={2}
                                >
                                    Prix total
                                </th>
                                <th
                                    className="w-[50%] sm:w-[35%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium text-center"
                                    colSpan={3}
                                >
                                    Fluctuations
                                </th>
                            </tr>
                            <tr>
                                <th className="text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7]">
                                    Jour
                                </th>
                                <th className="text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7]">
                                    Semaine
                                </th>
                                <th className="text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7]">
                                    Mois
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <div className="w-full">
                        {Array(limitPerPage).fill(<LoadingTableLine />)}
                    </div>
                </div>
            )}
            {/* pagination */}
            {isFullyLoaded && dataMeta.total > 0 && (
                <Pagination
                    extra="mt-3"
                    text={false}
                    maxItems={dataMeta ? dataMeta.total : 12}
                    itemsPerPage={[limitPerPage]}
                    page={page}
                    onChange={(page: number, limit: number) =>
                        fetchOrdersData(
                            page,
                            limit,
                            selectedDate,
                            selectedDeliveryTour,
                        )
                    }
                />
            )}
        </Card>
    );
};

export default ProductSummary;
