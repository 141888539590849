import { useEffect, useState } from 'react';
import { getDashboardAnalyticsV2 } from 'interfaces/user';
import ChartBar3Period from './components/ChartBar3Period';
import Scatter from './components/Scatter';
import ListMargin from './components/ListMargin';
import GoBack from 'components/actions/GoBack';

const DashboardV2 = () => {
    const [data, setData] = useState<any>(null);

    const initDashboardData = async () => {
        const res: any = await getDashboardAnalyticsV2();
        if (res.error) {
        }
        setData(res.data);
    };

    useEffect(() => {
        initDashboardData();
    }, []);

    return (
        <div className="flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
            <div className="h-full w-full rounded-[20px]">
                <GoBack />
                <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
                    {/* Statistics */}
                    {/* <div className="z-0 col-span-12 grid h-full w-full grid-cols-2 gap-5 md:grid-cols-2 xl:grid-cols-4">
                        <div id="step-one">
                            <MiniStatistics
                                name="CA du jour"
                                value={data ? `${data.todayBenefit} €` : '-'}
                                icon={
                                    <MdOutlineShoppingBasket color="#0260CB" />
                                }
                                iconBg="bg-lightPrimary"
                            />
                        </div>
                        <div>
                            <MiniStatistics
                                name="CA de la période"
                                value={
                                    data ? `${data.benefitPerPeriod} €` : '-'
                                }
                                icon={<MdEuro color="#0260CB" />}
                                iconBg="bg-lightPrimary"
                            />
                        </div>
                        <div>
                            <MiniStatistics
                                name="Volume de la période"
                                value={
                                    data ? `${data.volumePerPeriod} Kg` : '-'
                                }
                                icon={<TbMoneybag color="#0260CB" />}
                                iconBg="bg-lightPrimary"
                            />
                        </div>
                        <div>
                            <MiniStatistics
                                name="Commandes / période"
                                value={
                                    data ? `${data.totalOrdersPerPeriod}` : '-'
                                }
                                icon={<TbPigMoney color="#0260CB" />}
                                iconBg="bg-lightPrimary"
                            />
                        </div>
                    </div> */}
                    {/* Main data */}
                    <div className="mt-5 grid w-full grid-cols-8 gap-5">
                        <div className="col-span-12">
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
                                <div className="col-span-2">
                                    <ChartBar3Period
                                        title={"Chiffre d'affaire"}
                                        data={data?.monthlyRevenuePerYear}
                                        calculateCumulativeSum={true}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <ChartBar3Period
                                        title={'Nouveaux clients'}
                                        data={data?.last12MonthsNewCustomers}
                                        calculateCumulativeSum={true}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <ChartBar3Period
                                        title={'Nouveaux prospects'}
                                        data={data?.last12MonthsProspects}
                                        calculateCumulativeSum={true}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <ChartBar3Period
                                        title={'Panier moyen'}
                                        data={
                                            data?.last12MonthsAverageBasket
                                                ?.data
                                        }
                                        calculateCumulativeSum={false}
                                    />
                                </div>
                                {/* <div className="col-span-2">
                                    <ChartBar3Period
                                        title={'Conversion prospects/clients'}
                                        data={data?.last12MonthsNewCustomers}
                                    />
                                </div> */}
                                <div className="col-span-2">
                                    <Scatter
                                        title={'Nuage des marges'}
                                        data={data?.allProductsMargin}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <ListMargin
                                        data={data?.allProductsMargin}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardV2;
