import React from 'react';
import Card from 'components/card';

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import { dateToFrenchShort } from 'utils/date';
import { IoMdEye } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { MdCheckCircle } from 'react-icons/md';

type RowObj = {
    name: string;
    status: string;
    date: string;
    progress: number;
    orderId: string;
    deliveryDate: string;
    totalPrice: number;
    _id: string;
    items: any;
    invoiced: boolean;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function LastOrders(props: { tableData: any }) {
    const { tableData } = props;
    const [sorting, setSorting] = React.useState<SortingState>([]);
    let defaultData = tableData;
    const navigate = useNavigate();

    const columns = [
        columnHelper.accessor('orderId', {
            id: 'orderId',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    N° / Livraison
                </p>
            ),
            cell: (info) => (
                <>
                    <p className="text-xs font-bold text-navy-700 dark:text-white">
                        #{info.getValue()}
                    </p>
                    <p className="text-xs font-medium text-gray-400 dark:text-white">
                        {dateToFrenchShort(info.row.original.deliveryDate)}
                    </p>
                </>
            ),
        }),
        columnHelper.accessor('invoiced', {
            id: 'invoiced',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Facture
                </p>
            ),
            cell: (info) => (
                <div className="flex items-center">
                    {!info.getValue() ? (
                        <div className="flex bg-red-400 rounded-xl p-1 px-2 gap-2">
                            <p className="text-[10px] text-white dark:text-white">
                                Non facturée
                            </p>
                        </div>
                    ) : (
                        // <div className="flex bg-green-500 rounded-xl p-1 px-2 gap-1">
                        //     <MdCheckCircle className=" text-white" />
                        //     <p className="text-[10px] text-white dark:text-white">
                        //         Payée
                        //     </p>
                        // </div>
                        <div className="flex bg-red-400 rounded-xl p-1 px-2 gap-2">
                            <p className="text-[10px] text-white dark:text-white">
                                Facturée
                            </p>
                        </div>
                    )}
                </div>
            ),
        }),
        columnHelper.accessor('items', {
            id: 'items',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Commande
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-medium text-navy-700 dark:text-white">
                    {info.getValue().length} articles
                </p>
            ),
        }),
        columnHelper.accessor('totalPrice', {
            id: 'totalPrice',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Total HT
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-medium text-navy-700 dark:text-white">
                    {info.getValue().toFixed(2)} €
                </p>
            ),
        }),
        columnHelper.accessor('_id', {
            id: '_id',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Actions
                </p>
            ),
            cell: (info) => (
                <div className="flex items-center">
                    <IoMdEye
                        title="Voir la commande"
                        className="text-toola-500 h-6 w-6 hover:cursor-pointer hover:text-toola-700"
                        onClick={() => navigate(`/order/${info.getValue()}`)}
                    />
                </div>
            ),
        }),
    ]; // eslint-disable-next-line
    const [data, setData] = React.useState(() => [...defaultData]);
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    return (
        <Card extra={'w-full h-full px-6 pb-6 sm:overflow-x-auto border'}>
            <div className="mt-5 w-full px-[8px]">
                <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                    Dernières commandes
                </h4>
            </div>

            <div className="mt-2 overflow-x-scroll xl:overflow-x-hidden">
                <table className="w-full">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr
                                key={headerGroup.id}
                                className="!border-px !border-gray-400"
                            >
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="cursor-pointer border-b border-gray-200 pt-4 pb-2 pr-4 text-start dark:border-white/30"
                                        >
                                            <div className="items-center justify-between text-xs text-gray-200">
                                                {flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext(),
                                                )}
                                                {{
                                                    asc: '',
                                                    desc: '',
                                                }[
                                                    header.column.getIsSorted() as string
                                                ] ?? null}
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table
                            .getRowModel()
                            .rows.slice(0, 5)
                            .map((row) => {
                                return (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <td
                                                    key={cell.id}
                                                    className="min-w-[90px] border-white/0 py-2 px-2"
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef
                                                            .cell,
                                                        cell.getContext(),
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
                {table.getRowModel().rows.length === 0 && (
                    <div className="flex items-center justify-center h-32">
                        <p className="text-gray-400 dark:text-white">
                            Aucune commande
                        </p>
                    </div>
                )}
            </div>
        </Card>
    );
}
