const PrintOrderCard = (props: { order: any; displayPrice?: boolean }) => {
    const { order, displayPrice } = props;

    return (
        <div
            key={order._id}
            className="h-screen mx-[10px] mt-[10px] p-[15px] border rounded-[30px]"
        >
            {order.customer ? (
                <div className="px-4">
                    <div className="flex items-center justify-between">
                        <p className="flex-grow text-base font-bold text-navy-700 dark:text-white">
                            {order.customer.displayName ||
                                order.customer.company}
                        </p>
                    </div>
                    <p className="mt-5 flex-grow text-base font-bold text-navy-700 dark:text-white">
                        Livraison nº{order.orderId}
                    </p>
                    <p className="mt-5 text-sm max-w-[25%] text-[#A3AED0] dark:text-white">
                        {order.customer.deliveryAddress}
                    </p>
                    <p className="mt-5 text-sm text-[#A3AED0] dark:text-white">
                        {order.customer.contact && 'Contact :'}{' '}
                        {order.customer.contact}
                        <br />
                        {order.customer.phone && 'Téléphone :'}{' '}
                        {order.customer.phone}
                    </p>
                </div>
            ) : (
                <div className="w-full text-center">Pas d'infos client !</div>
            )}
            <table className="w-full mt-5">
                <thead>
                    <tr>
                        <th className="mt-[20px] w-[45%] px-1 text-start text-sm">
                            <p className="text-xs font-bold text-gray-600 dark:text-white">
                                Produit
                            </p>
                        </th>
                        {displayPrice && (
                            <th className="mt-[20px] w-[20%] px-1 text-start text-sm">
                                <p className="text-xs font-bold text-gray-600 dark:text-white">
                                    Prix unitaire HT
                                </p>
                            </th>
                        )}
                        <th className="mt-[20px] w-[15%] px-1 text-start text-sm">
                            <p className="text-xs font-bold text-gray-600 dark:text-white">
                                Quantité
                            </p>
                        </th>
                        {displayPrice && (
                            <th className="mt-[20px] w-[20%] px-1 text-start text-sm">
                                <p className="text-xs font-bold text-gray-600 dark:text-white">
                                    Prix total HT
                                </p>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {order.items.map((product: any) => {
                        return (
                            <tr className="border-t border-gray-200 h-[30px]">
                                <td className="px-[10px]">
                                    <p className="text-xs">{product.name}</p>
                                </td>
                                {displayPrice && (
                                    <td className="px-[10px]">
                                        <p className="font-medium text-xs">
                                            {product.price} €
                                        </p>
                                    </td>
                                )}
                                <td className="px-[10px]">
                                    <p className="font-medium text-sm">
                                        {product.quantity} {product.unity}
                                    </p>
                                </td>
                                {displayPrice && (
                                    <td className="px-[10px]">
                                        <p className="font-medium text-xs">
                                            {(
                                                product.quantity * product.price
                                            ).toFixed(2)}{' '}
                                            €
                                        </p>
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default PrintOrderCard;
