import React from 'react';
import {
    Routes,
    Route,
    Navigate,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { Portal } from '@chakra-ui/portal';
import { ToastContainer } from 'react-toastify';

import Navbar from 'components/navbar';
import Sidebar from 'components/sidebar';
import Footer from 'components/footer/Footer';
import routes from 'routes';

export default function Admin(props: { [x: string]: any }) {
    const { ...rest } = props;
    const location = useLocation();
    const [open, setOpen] = React.useState(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fullSidebar, setFullSidebar] = React.useState(true);
    const [currentRoute, setCurrentRoute] = React.useState('');
    const nav = useNavigate();

    React.useEffect(() => {
        window.innerWidth < 567 ? setOpen(false) : setOpen(true);
        //window.innerWidth < 1400 ? setFullSidebar(false) : setFullSidebar(true);
        window.addEventListener('resize', () => {
            window.innerWidth < 567 ? setOpen(false) : setOpen(true);
            //window.innerWidth < 1400 ? setFullSidebar(false) : setFullSidebar(true);
        });
    }, []);

    React.useEffect(() => {
        if (localStorage.getItem('token') === null) {
            window.location.href = '/auth/login';
        }
        getActiveRoute(routes);
        // eslint-disable-next-line
    }, [location.pathname]);

    // functions for changing the states from components
    const getActiveRoute = (routes: RoutesType[]): string => {
        let activeRoute = 'Default Brand Text';
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].items);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(
                        routes[i].layout + routes[i].path,
                    ) !== -1
                ) {
                    setCurrentRoute(routes[i].name);
                }
            }
        }
        return activeRoute;
    };
    const getActiveNavbar = (routes: RoutesType[]): boolean => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveNavbar = getActiveNavbar(routes[i].items);
                if (collapseActiveNavbar !== activeNavbar) {
                    return collapseActiveNavbar;
                }
            } else {
                if (
                    window.location.href.indexOf(
                        routes[i].layout + routes[i].path,
                    ) !== -1
                ) {
                    return routes[i].secondary;
                }
            }
        }
        return activeNavbar;
    };
    const getRoutes = (routes: RoutesType[]): any => {
        return routes.map((prop, key) => {
            if (prop.layout === '/admin' || prop.layout === '') {
                return (
                    <Route
                        path={`${prop.path}`}
                        element={prop.component}
                        key={key}
                    />
                );
            }
            if (prop.collapse) {
                return getRoutes(prop.items);
            }
            return null;
        });
    };
    document.documentElement.dir = 'ltr';
    return (
        <div className="flex h-full w-full">
            <Sidebar
                open={open}
                fullSidebar={fullSidebar}
                onClose={() => setOpen(false)}
            />
            {/* Navbar & Main Content */}
            <div className="h-full w-full font-dm dark:bg-navy-900">
                {/* Main Content */}
                <main
                    className={`mx-2.5 flex-none transition-all dark:bg-navy-900 md:pr-2 ${
                        fullSidebar ? 'sm:ml-[313px]' : 'sm:ml-[160px]'
                    }`}
                >
                    <ToastContainer />
                    {/* Routes */}
                    <div>
                        <Portal>
                            <Navbar
                                onOpenSidenav={() => setOpen(!open)}
                                openSideNav={() => {
                                    if (window.innerWidth < 567) setOpen(!open);
                                    else setOpen(true);
                                }}
                                brandText={currentRoute}
                                secondary={getActiveNavbar(routes)}
                                {...rest}
                            />
                        </Portal>
                        <div
                            className="mx-auto min-h-screen p-2 !pt-[55px] sm:!pt-[75px] md:p-2"
                            onClick={() => {
                                if (window.innerWidth < 567) setOpen(false);
                            }}
                        >
                            <Routes>
                                {getRoutes(routes)}
                                <Route
                                    path="/"
                                    element={
                                        <Navigate to="/dashboard" replace />
                                    }
                                />
                            </Routes>
                        </div>
                        <div className="p-3">
                            <Footer />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}
