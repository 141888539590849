import { MdDehaze, MdEuro, MdGridView, MdPallet } from 'react-icons/md';
import { TbPigMoney, TbMoneybag } from 'react-icons/tb';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from 'components/card';
import CreateButton from 'components/buttons/CreateButton';
import { getOrderDashboard, getPaginatedOrders } from 'interfaces/order';
import LoadingCard from './components/loadingCard';
import MiniStatistics from 'components/card/MiniStatistics';
import OrderCard from 'components/card/orderCard';
import orderImg from 'assets/img/order.png';
import SearchIcon from 'components/icons/SearchIcon';
import Pagination from 'components/pagination/Pagination';
import ProductSummary from './components/productsSummary';
import ExportMenu from './components/exportMenu';
import { fetchFilter, setFilter } from 'utils/filtersStorage';
import { FaArrowRotateRight } from 'react-icons/fa6';
import { dateToFrenchShort } from 'utils/date';

const ListOrders = () => {
    const nav = useNavigate();
    const [orders, setOrders] = useState(null);
    const [dashboard, setDashboard] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [firstFetch, setFirstFetch] = useState(true);
    const [initSearch, setInitSearch] = useState(true);
    const [limitPerPage, setLimitPerpage] = useState(8);
    const [ordersMeta, setOrdersMeta] = useState(null);
    const [orderType, setOrderType] = useState('all');
    const [view, setView] = useState('grid');
    const [sortBy, setSortBy] = useState('deliveryDate');
    const [toggleState, setToggleState] = useState('pending');
    const isFullyLoaded = orders && dashboard;

    const changeToogleState = (state: string) => {
        setToggleState(state);
        fetchOrders(1, limitPerPage, state, sortBy);
    };

    const fetchOrders = async (
        page: number,
        limit: number,
        state: string = toggleState,
        sortBy: string,
    ) => {
        setLimitPerpage(limit);
        setOrders(null);
        const res = await getPaginatedOrders(
            page,
            limit,
            state,
            search,
            orderType,
            sortBy,
        );
        if (res) {
            setOrders(res.data);
            setOrdersMeta(res.meta);
            setPage(page);
            if (firstFetch) setFirstFetch(false);
        }
    };

    const initOrdersDashboard = async () => {
        const res = await getOrderDashboard();
        if (res) setDashboard(res);
    };

    useEffect(() => {
        fetchOrders(1, limitPerPage, toggleState, sortBy);
    }, [orderType, sortBy]);

    useEffect(() => {
        setOrderType('all');
    }, [toggleState]);

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            nav('/login');
        }
        const filter = fetchFilter('orders');
        if (filter) {
            setToggleState(filter?.toggleState);
            setSearch(filter?.search);
        } else {
            fetchOrders(page, limitPerPage, toggleState, sortBy);
        }
        initOrdersDashboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!firstFetch)
            setFilter('orders', {
                page,
                limitPerPage,
                search,
                toggleState,
                view,
                sortBy,
            });
    }, [page, limitPerPage, search, toggleState, firstFetch, view, sortBy]);

    useEffect(() => {
        // If init (search = '') but cached search is not empty,return
        const filters = fetchFilter('orders');
        if (initSearch && filters?.search !== '') {
            setInitSearch(false);
            return;
        }

        if (firstFetch) {
            const filters = fetchFilter('orders');

            if (filters.view) setView(filters.view);
            if (filters.sortBy) setSortBy(filters.sortBy);

            fetchOrders(
                filters.page || page,
                filters.limitPerPage || limitPerPage,
                filters.toggleState || toggleState,
                filters.sortBy || sortBy,
            );
        } else {
            setOrders(null);
            setOrdersMeta(null);
            const delayDebounceFn = setTimeout(() => {
                fetchOrders(1, limitPerPage, toggleState, sortBy);
            }, 600);
            return () => clearTimeout(delayDebounceFn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    function timeAgo(date: string) {
        const now: any = new Date();
        const givenDate: any = new Date(date);
        const diffInSeconds = Math.floor((now - givenDate) / 1000);

        const days = Math.floor(diffInSeconds / (3600 * 24));
        const hours = Math.floor((diffInSeconds % (3600 * 24)) / 3600);
        const minutes = Math.floor((diffInSeconds % 3600) / 60);
        const seconds = diffInSeconds % 60;

        if (days > 0) {
            return `Il y a ${days} jour${days > 1 ? 's' : ''}`;
        } else if (hours > 0) {
            return `Il y a ${hours} heure${hours > 1 ? 's' : ''}`;
        } else if (minutes > 0) {
            return `Il y a ${minutes} minute${minutes > 1 ? 's' : ''}`;
        } else {
            return `Il y a ${seconds} seconde${seconds > 1 ? 's' : ''}`;
        }
    }

    function isWithin30Minutes(date: Date | string): boolean {
        const start = new Date();
        const end = new Date(date);
        const diffInMs = Math.abs(end.getTime() - start.getTime());
        const diffInMinutes = diffInMs / (1000 * 60);
        return diffInMinutes <= 30;
    }

    return (
        <>
            <div className="mt-4 grid h-full w-full grid-cols-1 gap-5 xl:mt-3">
                <div className="z-0 col-span-12 grid h-full w-full grid-cols-2 gap-3 sm:gap-5 md:grid-cols-2 xl:grid-cols-4">
                    <MiniStatistics
                        name="Commandes"
                        value={
                            isFullyLoaded
                                ? orders.filter(
                                      (c: any) => c.status === 'pending',
                                  ).length
                                : '-'
                        }
                        icon={<MdPallet color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                        extra="sm:hidden"
                    />
                    <MiniStatistics
                        name="Commandes en cours"
                        value={
                            isFullyLoaded
                                ? orders.filter(
                                      (c: any) => c.status === 'pending',
                                  ).length
                                : '-'
                        }
                        icon={<MdPallet color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                        extra="hidden sm:flex"
                    />
                    <MiniStatistics
                        name="CA du jour"
                        value={
                            isFullyLoaded
                                ? `${Math.round(dashboard.revenueOfTheDay)} €`
                                : '-'
                        }
                        icon={<MdEuro color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                    />
                    <MiniStatistics
                        name="CA des 7 derniers jours"
                        value={
                            isFullyLoaded
                                ? `${Math.round(dashboard.revenueoftheWeek)} €`
                                : '-'
                        }
                        icon={<TbMoneybag color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                    />
                    <MiniStatistics
                        name="CA des 30 derniers jours"
                        value={
                            isFullyLoaded
                                ? `${Math.round(dashboard.revenueOfTheMonth)} €`
                                : '-'
                        }
                        icon={<TbPigMoney color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                    />
                </div>
                <div className="col-span-12">
                    <ProductSummary />
                </div>
                {/* <div className="col-span-12">
                    <DiscoverCommandPage />
                </div> */}
                <div className="col-span-12 h-full w-full rounded-[20px]">
                    <Card extra="pt-[10px] pl-[20px] pr-[20px] pb-[20px] border">
                        {
                            <>
                                {/* Status */}
                                <div className="w-full flex items-center m-auto">
                                    <div className="mt-2 flex w-full gap-[2px] overflow-hidden border-b border-gray-200 justify-center flex-row">
                                        <div
                                            className={
                                                toggleState === 'pending'
                                                    ? 'border-b-[4px] border-[#0260CB] pb-[8px] pt-[8px] hover:cursor-default dark:border-brand-400 bg-[#7090B0]/[0.05] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[210px] h-[40px] text-center'
                                                    : 'pb-[11px] pt-[8px] hover:cursor-pointer bg-[#7090B0]/[0.15] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[210px] h-[40px] text-center'
                                            }
                                            onClick={() => {
                                                changeToogleState('pending');
                                            }}
                                        >
                                            <p className="text-xs font-medium text-navy-700 dark:text-white md:text-[15px]">
                                                <span className="hidden sm:contents">
                                                    Commandes en cours
                                                </span>
                                                <span className="contents sm:hidden">
                                                    En cours
                                                </span>{' '}
                                                (
                                                {ordersMeta
                                                    ? ordersMeta.pending
                                                    : '-'}
                                                )
                                            </p>
                                        </div>

                                        <div
                                            className={
                                                toggleState === 'delivered'
                                                    ? 'border-b-[4px] border-[#0260CB] pb-[8px] pt-[8px] hover:cursor-default dark:border-brand-400 bg-[#7090B0]/[0.05] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[210px] h-[40px] text-center'
                                                    : 'pb-[11px] pt-[8px] hover:cursor-pointer bg-[#7090B0]/[0.15] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[210px] h-[40px] text-center'
                                            }
                                            onClick={() => {
                                                changeToogleState('delivered');
                                            }}
                                        >
                                            <p className="text-xs font-medium text-navy-700 dark:text-white md:text-[15px]">
                                                <span className="hidden sm:contents">
                                                    Commandes livrées
                                                </span>
                                                <span className="contents sm:hidden">
                                                    Livrées
                                                </span>{' '}
                                                (
                                                {ordersMeta
                                                    ? ordersMeta.delivered
                                                    : '-'}
                                                )
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                toggleState === 'canceled'
                                                    ? 'border-b-[4px] border-[#0260CB] pb-[8px] pt-[8px] hover:cursor-default dark:border-brand-400 bg-[#7090B0]/[0.05] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[210px] h-[40px] text-center'
                                                    : 'pb-[11px] pt-[8px] hover:cursor-pointer bg-[#7090B0]/[0.15] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[210px] h-[40px] text-center'
                                            }
                                            onClick={() => {
                                                changeToogleState('canceled');
                                            }}
                                        >
                                            <p className="text-xs font-medium text-navy-700 dark:text-white md:text-[15px]">
                                                <span className="hidden sm:contents">
                                                    Commandes annulées
                                                </span>
                                                <span className="contents sm:hidden">
                                                    Annulées
                                                </span>{' '}
                                                (
                                                {ordersMeta
                                                    ? ordersMeta.canceled
                                                    : '-'}
                                                )
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex gap-1 mr-2">
                                        <div
                                            className={`flex items-center text-xl hover:cursor-pointer bg-none text-navy-700 bg-lightPrimary py-2 px-2 hover:bg-gray-100 linear justify-center rounded-lg transition duration-200`}
                                            onClick={() =>
                                                nav('/recurring-orders')
                                            }
                                        >
                                            {/* <p className="text-xs text-center">
                                                Commandes récurrentes
                                            </p> */}
                                            <FaArrowRotateRight
                                                className="flex my-auto hover:cursor-pointer"
                                                size={15}
                                                title="Commandes récurrentes"
                                            />
                                        </div>
                                        <ExportMenu />
                                    </div>
                                </div>
                                {/* Search */}
                                <div className="mt-[20px] flex flex-col sm:flex-row justify-between items-center gap-y-[10px]">
                                    <div className="flex h-[38px] w-full items-center rounded-xl bg-lightPrimary text-sm text-gray-600 sm:w-[40%]">
                                        <SearchIcon />
                                        <input
                                            type="text"
                                            placeholder="Entreprise, mail, téléphone, contact, ...."
                                            value={search}
                                            onChange={(e) =>
                                                setSearch(e.target.value)
                                            }
                                            className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
                                        />
                                    </div>
                                    <CreateButton
                                        value="Entrer une commande"
                                        extra="my-auto h-10 col-start-1 col-span-6 md:col-span-4 lg:col-start-8 lg:col-span-3 3xl:col-start-9 3xl:col-span-2"
                                        onClick={() => nav('/create-order')}
                                    />
                                </div>
                                <div className="mt-5 lg:flex justify-between">
                                    {/* Production status */}
                                    {toggleState === 'pending' && (
                                        <div className="flex mt-1 ml-4 gap-4">
                                            <p
                                                className={`text-xs ${
                                                    orderType === 'all'
                                                        ? 'text-toola-500 underline'
                                                        : 'text-toola-500/80'
                                                } hover:underline hover:cursor-pointer`}
                                                onClick={() =>
                                                    setOrderType('all')
                                                }
                                            >
                                                Tout afficher
                                            </p>
                                            <p
                                                className={`text-xs ${
                                                    orderType === 'preparing'
                                                        ? 'text-toola-500 underline'
                                                        : 'text-toola-500/80'
                                                } hover:underline hover:cursor-pointer`}
                                                onClick={() =>
                                                    setOrderType('preparing')
                                                }
                                            >
                                                Préparation en cours
                                            </p>
                                            <p
                                                className={`text-xs ${
                                                    orderType === 'ready'
                                                        ? 'text-toola-500 underline'
                                                        : 'text-toola-500/80'
                                                } hover:underline hover:cursor-pointer`}
                                                onClick={() =>
                                                    setOrderType('ready')
                                                }
                                            >
                                                Préparation terminée
                                            </p>
                                        </div>
                                    )}
                                    {/* Sort */}
                                    <div className="flex mt-5 lg:mt-1 ml-4 gap-2">
                                        <p className="text-xs">Trier par:</p>
                                        <p
                                            className={`text-xs ${
                                                sortBy === 'deliveryDate'
                                                    ? 'text-toola-500 underline'
                                                    : 'text-toola-500/80'
                                            } hover:underline hover:cursor-pointer`}
                                            onClick={() =>
                                                setSortBy('deliveryDate')
                                            }
                                        >
                                            Date de livraison
                                        </p>
                                        <p
                                            className={`text-xs ${
                                                sortBy === 'createdAt'
                                                    ? 'text-toola-500 underline'
                                                    : 'text-toola-500/80'
                                            } hover:underline hover:cursor-pointer`}
                                            onClick={() =>
                                                setSortBy('createdAt')
                                            }
                                        >
                                            Date de création
                                        </p>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="hidden lg:block absolute left-6 top-6">
                            <div className="flex gap-2">
                                <MdGridView
                                    size={20}
                                    className={`${
                                        view === 'grid' && 'text-toola-500'
                                    } cursor-pointer`}
                                    onClick={() => setView('grid')}
                                />
                                <MdDehaze
                                    size={20}
                                    className={`${
                                        view === 'list' && 'text-toola-500'
                                    } cursor-pointer`}
                                    onClick={() => setView('list')}
                                />
                            </div>
                        </div>
                        <div
                            className={`mt-2 grid grid-cols-1 gap-2 ${
                                view === 'grid' &&
                                'gap-5 md:grid-cols-2 2xl:grid-cols-4'
                            }`}
                        >
                            {/* gap-5 md:grid-cols-2 2xl:grid-cols-4 */}
                            {/* No Order */}
                            {isFullyLoaded && orders.length === 0 && (
                                <div className="col-span-12 mt-10">
                                    <img
                                        className="mx-auto flex h-[250px] w-[250px] items-center justify-center"
                                        src={orderImg}
                                        alt=""
                                    />
                                    <h4 className="col-span-12 my-auto mt-3 text-center text-2xl font-medium text-navy-700 dark:text-white">
                                        Gérer vos commandes facilement
                                    </h4>
                                    <p className="col-span-12 my-auto mt-3 text-center text-base text-navy-700 dark:text-white">
                                        Vous pouvez aussi créer votre page de
                                        commande pour automatiser leur
                                        réception.
                                    </p>
                                    <div className="font-large mt-5 flex items-center justify-center gap-5 rounded-xl">
                                        <button
                                            className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-9"
                                            onClick={() => nav('/create-order')}
                                        >
                                            Créer une page de commande
                                        </button>
                                        <button
                                            className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-9"
                                            onClick={() => nav('/create-order')}
                                        >
                                            Ajouter une commande
                                        </button>
                                    </div>
                                </div>
                            )}

                            {isFullyLoaded && view === 'list' && (
                                <div className="rounded-lg border border-r">
                                    <div className="relative overflow-x-auto">
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                                            <thead className="text-gray-700 bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-2 py-3"
                                                    >
                                                        # Commande
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-2 py-3"
                                                    >
                                                        Client
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-2 py-3"
                                                    >
                                                        Statut
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-2 py-3"
                                                    >
                                                        Date de livraison
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-2 py-3"
                                                    >
                                                        Articles
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-2 py-3"
                                                    >
                                                        Marge Brut
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-2 py-3"
                                                    >
                                                        Prix Total HT
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-2 py-3"
                                                    >
                                                        Prix Total TTC
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orders
                                                    .filter(
                                                        (c: any) =>
                                                            c.status ===
                                                            toggleState,
                                                    )
                                                    .map(
                                                        (
                                                            item: any,
                                                            index: number,
                                                        ) => (
                                                            <tr
                                                                className="bg-white border-b text-gray-800 border-gray-200 hover:bg-gray-100 cursor-pointer"
                                                                onClick={() =>
                                                                    nav(
                                                                        `/order/${item._id}`,
                                                                    )
                                                                }
                                                            >
                                                                <td className="px-2 py-3">
                                                                    {/* Created 10 minutes ago ? Live ping */}

                                                                    <p className="flex my-auto">
                                                                        #
                                                                        {
                                                                            item.orderId
                                                                        }
                                                                    </p>
                                                                    <div className="flex my-auto gap-2">
                                                                        {isWithin30Minutes(
                                                                            item?.createdAt,
                                                                        ) && (
                                                                            <span className="flex my-auto relative h-2 w-2">
                                                                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-toola-500/50 opacity-75"></span>
                                                                                <span className="relative inline-flex rounded-full h-2 w-2 bg-toola-500/50"></span>
                                                                            </span>
                                                                        )}
                                                                        <p className="text-xs text-gray-700">
                                                                            {timeAgo(
                                                                                item.createdAt,
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td className="px-2 max-w-[180px] py-3">
                                                                    {item.displayName ||
                                                                        item.company}
                                                                </td>
                                                                <td className="px-2 py-3">
                                                                    {item.invoiced ? (
                                                                        <span className="text-xs bg-green-200 text-green-700 px-2 py-1 rounded-full">
                                                                            Facturée
                                                                        </span>
                                                                    ) : item.status ===
                                                                      'pending' ? (
                                                                        <span className="text-xs bg-yellow-200 text-yellow-700 px-2 py-1 rounded-full">
                                                                            En
                                                                            cours
                                                                        </span>
                                                                    ) : item.status ===
                                                                      'delivered' ? (
                                                                        <span className="text-xs bg-toola-500/30 text-toola-500 px-2 py-1 rounded-full">
                                                                            Livrée
                                                                        </span>
                                                                    ) : (
                                                                        <span className="text-xs bg-red-200 text-red-700 px-2 py-1 rounded-full">
                                                                            Annulée
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td className="px-2 py-3">
                                                                    {dateToFrenchShort(
                                                                        item.deliveryDate,
                                                                    )}
                                                                </td>
                                                                <td className="px-2 py-3">
                                                                    {
                                                                        item
                                                                            ?.items
                                                                            .length
                                                                    }
                                                                </td>
                                                                <td className="px-2 py-3">
                                                                    <p>
                                                                        {item?.totalMargin?.toFixed(
                                                                            2,
                                                                        ) ||
                                                                            'N/A'}
                                                                        {' €'}
                                                                    </p>
                                                                    <p className="text-xs text-gray-700">
                                                                        {parseFloat(
                                                                            (
                                                                                (item?.totalMargin /
                                                                                    item?.totalHT) *
                                                                                100
                                                                            ).toFixed(
                                                                                2,
                                                                            ),
                                                                        )}
                                                                        %
                                                                    </p>
                                                                </td>
                                                                <td className="px-2 py-3">
                                                                    {
                                                                        item?.totalHT
                                                                    }{' '}
                                                                    €
                                                                </td>
                                                                <td className="px-2 py-3">
                                                                    {
                                                                        item?.totalTTC
                                                                    }{' '}
                                                                    €
                                                                </td>
                                                            </tr>
                                                        ),
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                            {/* Cards list */}
                            {isFullyLoaded &&
                                view === 'grid' &&
                                orders
                                    .filter(
                                        (c: any) => c.status === toggleState,
                                    )
                                    .map((order: any) => {
                                        return (
                                            <OrderCard
                                                id={order._id}
                                                company={order.company}
                                                displayName={order.displayName}
                                                orderId={order.orderId}
                                                deliveryDate={
                                                    order.deliveryDate
                                                }
                                                createdAt={order.createdAt}
                                                items={order.items}
                                                viewed={order.viewed}
                                                orderType={orderType}
                                            />
                                        );
                                    })}

                            {/* Skelleton loader */}
                            {!isFullyLoaded &&
                                Array(limitPerPage)
                                    .fill(<LoadingCard />)
                                    .map((order: any) => {
                                        return order;
                                    })}
                        </div>
                        {/* pagination */}
                        {isFullyLoaded && (
                            <Pagination
                                extra="mt-3"
                                text={true}
                                maxItems={
                                    ordersMeta ? ordersMeta.sectionTotal : 12
                                }
                                itemsPerPage={[8, 12, 16, 30, 50, 100]}
                                defaultItemsPerPage={limitPerPage}
                                page={page}
                                onChange={(page: number, limit: number) =>
                                    fetchOrders(
                                        page,
                                        limit,
                                        toggleState,
                                        sortBy,
                                    )
                                }
                            />
                        )}
                    </Card>
                </div>
            </div>
        </>
    );
};

export default ListOrders;
