const LoadingTableLine = () => {
    return (
        <div className="w-full flex items-center gap-[5px] animate-pulse h-[64px]">
            <div className="w-[80%] sm:w-[15%] pl-[10px]">
                <div className="hidden sm:block">
                    <div className="h-2 sm:h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[60%] my-[7px]"></div>
                    <div className="h-2 bg-[#1B254B]/[0.25] rounded-full dark:bg-gray-700 w-[80%] my-[7px]"></div>
                </div>
                <div className="sm:hidden">
                    <div className="h-2 sm:h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[75%] my-[7px]"></div>
                    <div className="h-2 bg-[#1B254B]/[0.25] rounded-full dark:bg-gray-700 w-[50%] my-[7px]"></div>
                    <div className="h-2 bg-[#1B254B]/[0.25] rounded-full dark:bg-gray-700 w-[60%] my-[7px]"></div>
                    <div className="h-2 bg-[#1B254B]/[0.25] rounded-full dark:bg-gray-700 w-[30%] my-[7px]"></div>
                </div>
            </div>
            <div className="hidden sm:block w-[15%] pl-[10px]">
                <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[80%] my-[7px]"></div>
            </div>
            <div className="hidden sm:block w-[10%] pl-[10px]">
                <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[50%] my-[7px]"></div>
                <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[80%] my-[7px]"></div>
            </div>
            <div className="hidden sm:block w-[45%] pl-[65px]">
                <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[50%] my-[7px]"></div>
            </div>
            <div className="hidden sm:block w-[5%] pl-[20px]">
                <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[90%] my-[7px]"></div>
            </div>
        </div>
    );
};

export default LoadingTableLine;
