import { padStart } from '@fullcalendar/core/internal';

export function transformMongoAggregateToApexChart(
    data: any,
    type: any = 'monthly',
    calculateCumulativeSum = true,
) {
    data.sort((a: any, b: any) => {
        const [monthA, yearA] = a._id.split('-').map(Number);
        const [monthB, yearB] = b._id.split('-').map(Number);
        return yearA !== yearB ? yearA - yearB : monthA - monthB;
    });

    const aggregatedData: any[] = [];
    let cumulativeSum = 0;
    let values: any[] = [];
    let currentPeriod = '';

    data.forEach((item: any) => {
        const [month, year] = item._id.split('-').map(Number);
        let periodKey: any;

        switch (type) {
            case 'monthly':
                periodKey = `${month}-${year}`;
                break;
            case 'quarterly':
                periodKey = `Q${Math.ceil(month / 3)}-${year}`;
                break;
            case 'yearly':
                periodKey = `${year}`;
                break;
        }

        if (currentPeriod !== periodKey) {
            currentPeriod = periodKey;
            cumulativeSum = 0;
            values = [];
        }

        // cumulative sum
        cumulativeSum += item.sum;
        values.push(item.sum);

        const existingEntry = aggregatedData.find((d) => d._id === periodKey);
        if (existingEntry) {
            existingEntry.sum = cumulativeSum;
            existingEntry.values = values;
            existingEntry.average = cumulativeSum / values.length;
        } else {
            aggregatedData.push({
                _id: periodKey,
                sum: cumulativeSum,
                values,
                average: cumulativeSum / values.length,
            });
        }
    });

    return {
        series: [
            {
                name: 'Total',
                data: calculateCumulativeSum
                    ? aggregatedData.map((item: any) => item.sum)
                    : aggregatedData.map((item: any) => item.average),
            },
        ],
        options: {
            chart: {
                type: 'bar',
                toolbar: {
                    tools: {
                        download: false,
                    },
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    borderRadius: 5,
                    borderRadiusApplication: 'end',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: aggregatedData.map((item: any) => {
                    const [period, year] = item._id.split('-');
                    switch (type) {
                        case 'monthly':
                            // Add zero padding to month
                            return `${padStart(period, 2)}/${year}`;
                        case 'quarterly':
                            return `${period} ${year}`;
                        case 'yearly':
                            return period;
                    }
                }),
            },
            yaxis: {
                labels: {
                    formatter: (val: any) => parseInt(val),
                },
            },
        },
    };
}
