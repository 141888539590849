import { GoDotFill } from 'react-icons/go';
import Switch from 'components/switch';
import { getIcon } from 'utils/icons';
import { FaExternalLinkAlt } from 'react-icons/fa';

function B2bDisplayCard(props: {
    name: string;
    img: string;
    unity: string;
    quantity: number;
    type: string;
    id: string;
    alert: number;
    reference?: string;
    productType?: string;
    color?: string;
    price?: number;
    category?: string;
    updateCategory: (id: string, category: string) => void;
}) {
    const {
        name,
        img,
        category,
        quantity,
        type,
        id,
        alert,
        reference,
        color,
        productType,
        price,
        updateCategory,
    } = props;

    const onSwitchChange = (e: any) => {
        if (e.target.checked) {
            updateCategory(id, 'all');
        } else {
            updateCategory(id, 'hidden');
        }
    };

    return (
        <div
            className={`relative items-center rounded-[20px] bg-clip-border border px-[18px] py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none `}
        >
            {color && (
                <div
                    className={`absolute  top-3 left-3 h-4 w-4 bg-opacity-80 rounded-full border`}
                    style={{ backgroundColor: color }}
                ></div>
            )}
            <div className="absolute top-3 right-3 cursor-pointer">
                <FaExternalLinkAlt
                    size={10}
                    onClick={() => {
                        window.open(`/update-product/${id}`, '_blank');
                    }}
                />
            </div>
            <p className="text-xs absolute bottom-2 left-4 text-gray-600">
                {productType === 'transformed' && 'Transformé'}
                {productType === 'assembled' && 'Assemblé'}
                {productType === 'brut' && 'Brut'}
            </p>

            <div className="grid grid-cols-12">
                <div className="-bottom-12 col-span-4 flex h-[87px] w-[87px] items-center justify-center rounded-full">
                    <object
                        data={type === 'product' ? img : getIcon(name, type)}
                        type="image/png"
                        className={`h-[60px] w-[60px] rounded-xl`}
                    >
                        <img
                            className={`h-[60px] w-[60px] p-2 rounded-xl`}
                            src={getIcon(name, type)}
                            alt=""
                        />
                    </object>
                </div>
                <div className="mt-4 col-span-8 ml-4">
                    <h2
                        className={`text-sm leading-6 text-navy-700 dark:text-white`}
                    >
                        {name}
                    </h2>
                    <p className="mb-1 text-xs font-medium text-gray-600">
                        {reference ? reference : 'Référence: inconnue'}
                    </p>
                    <p className=" mb-1 text-sm font-medium text-gray-600">
                        {quantity <= alert && (
                            <>
                                <span className="text-red-500">Caché</span>
                            </>
                        )}
                    </p>
                    {/* <h3
                        className={`text-xl font-bold leading-6 text-navy-700 dark:text-white`}
                    >
                        {quantity % 1 === 0 ? quantity : quantity.toFixed(2)}{' '}
                        {unity}
                    </h3> */}
                </div>
            </div>
            <div className="w-full flex justify-between gap-2">
                {price > 0 || category === 'hidden' ? (
                    category === 'hidden' ? (
                        <div>
                            <div className="mt-2 flex my-auto gap-1">
                                <p className="text-sm">Jamais vendu</p>
                                <GoDotFill
                                    size={20}
                                    className="flex my-auto text-red-500"
                                />
                            </div>
                            <p className="mt-2 text-[10px]">
                                Le produit n'est jamais visible dans votre
                                boutique.
                            </p>
                        </div>
                    ) : (
                        <div>
                            <div className="mt-2 flex my-auto gap-[3px]">
                                <p className="text-sm">Vendable</p>
                                <GoDotFill
                                    size={20}
                                    className="flex my-auto text-green-600"
                                />
                            </div>
                            <p className="mt-2 text-[10px]">
                                Le produit est visible par défaut dans votre
                                boutique.
                            </p>
                        </div>
                    )
                ) : (
                    <div>
                        <div className="mt-2 flex my-auto gap-[3px]">
                            <p className="text-sm">Vendable</p>
                            <GoDotFill
                                size={20}
                                className="flex my-auto text-green-600"
                            />
                        </div>
                        <p className="mt-2 text-[10px]">
                            Le produit est visible par défaut dans votre
                            boutique.
                        </p>
                    </div>
                )}
                {category !== 'hidden' &&
                    category !== 'all' &&
                    category !== '' && (
                        <span className="absolute bottom-2 right-2 text-sm text-gray-600">
                            {category}
                        </span>
                    )}
                <div className="mt-2">
                    <Switch
                        onChange={onSwitchChange}
                        id={id}
                        isDefaultChecked={category !== 'hidden'}
                        color="toola"
                    />
                </div>
            </div>
        </div>
    );
}

export default B2bDisplayCard;
