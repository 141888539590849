import { useEffect, useRef, useState } from 'react';
import { IoMdArrowRoundUp } from 'react-icons/io';

const CustomerSearchBar = (props: {
    label?: string;
    hideLabel?: boolean;
    options: any;
    displayKey: string;
    onSelect: any;
    onSearch: any;
    value?: string | null;
    canCreate?: boolean;
    createNew?: any;
    disabled?: boolean;
    inputSmall?: boolean;
    defaultKey?: string;
    secondaryKey?: string;
    isLoading?: boolean;
}) => {
    const {
        options,
        displayKey,
        onSelect,
        onSearch,
        value,
        disabled,
        defaultKey = '_id',
        isLoading = false,
    } = props;

    const [inputValue, setInputValue] = useState('');
    const [selected, setSelected] = useState('');
    const [open, setOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const listRef = useRef<HTMLUListElement>(null); // Ref for the scrollable list

    useEffect(() => {
        if (value) {
            const defaultValue = options.find(
                (o: any) => o[defaultKey] === value,
            );
            if (defaultValue !== undefined)
                setSelected(defaultValue?.[displayKey]);
        }
    }, [value, options, displayKey, defaultKey]);

    const toggleDropdown = () => {
        if (disabled) return;
        setOpen((prev) => {
            const newOpenState = !prev;

            if (newOpenState) {
                // Focus the input and reset scroll position
                if (inputRef.current) inputRef.current.focus();
                if (listRef.current) listRef.current.scrollTop = 0;
            }

            return newOpenState;
        });
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            if (options.length === 1) {
                setSelected(options[0]?.[displayKey]);
                onSelect(options[0]);
                setOpen(false);
                setInputValue('');
            }
        }
    };

    return (
        <div className="h-10 w-full font-medium gap-2">
            <div className="mt-4 flex mx-auto">
                <div className="w-full">
                    <div className="relative">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg
                                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                            </svg>
                        </div>
                        <input
                            type="search"
                            id="default-search"
                            className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-2xl"
                            placeholder="Recherche client..."
                            required
                            onKeyDown={handleKeyDown}
                            value={inputValue}
                            onClick={toggleDropdown}
                            onChange={(e) => {
                                setOpen(true);
                                setInputValue(e.target.value);
                                onSearch(e.target.value);
                            }}
                        />
                        {!isLoading ? (
                            <button
                                className="text-white absolute end-2.5 bottom-2.5 bg-toola-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                onClick={(e) => {
                                    setOpen(false);
                                }}
                            >
                                <IoMdArrowRoundUp />
                            </button>
                        ) : (
                            <button
                                className="text-white absolute end-2.5 bottom-2.5 bg-toola-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                onClick={(e) => {
                                    setOpen(false);
                                }}
                            >
                                <div className="border-toola-500 h-1 w-1 animate-spin rounded-full border-8 border-t-white" />
                            </button>
                        )}
                    </div>
                </div>
            </div>

            <ul
                ref={listRef}
                className={`mt-2 overflow-y-auto rounded-xl ${
                    open ? 'max-h-[250px]' : 'max-h-0'
                } `}
            >
                {options?.map((option: any, index: number) => (
                    <li
                        key={`${option?.[displayKey]}-${index}`}
                        className={`sticky p-3 text-sm text-gray-800 hover:bg-gray-100 cursor-pointer
              ${
                  option?.name?.toLowerCase() === selected?.toLowerCase() ||
                  options.length === 1
                      ? 'bg-gray-100'
                      : ''
              }`}
                        onClick={() => {
                            if (
                                option?.[displayKey]?.toLowerCase() !==
                                selected.toLowerCase()
                            ) {
                                setSelected(option?.[displayKey]);
                                onSelect(option);
                                setOpen(false);
                                setInputValue('');
                            }
                        }}
                    >
                        {option?.[displayKey]}
                        <div className="mt-1 w-full h-[1px] bg-gray-300"></div>
                    </li>
                ))}
                {options?.length === 0 && (
                    <li className="p-3 text-sm animate-pulse">
                        Aucun résultat
                    </li>
                )}
            </ul>
        </div>
    );
};

export default CustomerSearchBar;
