import Card from 'components/card';
import SearchIcon from 'components/icons/SearchIcon';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ListMargin = (props: { data: any }) => {
    const { data } = props;
    const [period, setPeriod] = useState('descendant');
    const nav = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');

    const filteredData = (data: any) => {
        const filteredData = data.filter((item: any) => {
            return (
                item.ref.toLowerCase().includes(searchQuery) ||
                item.name.toLowerCase().includes(searchQuery)
            );
        });
        return filteredData;
    };

    useEffect(() => {
        if (!data || !data.length) return;
        if (period === 'descendant') {
            data.sort((a: any, b: any) => b.margin - a.margin);
        }
        if (period === 'ascendant') {
            data.sort((a: any, b: any) => a.margin - b.margin);
        }
    }, [period]);

    return (
        <Card
            extra={
                'w-full p-5 h-fit bg-blue-200 h-[320px] shadow shadow-3xl border ma-h'
            }
        >
            <p className="mb-2 text-base font-bold text-navy-700 dark:text-white">
                Marges par produit
            </p>
            <div className="mt-1 flex h-[32px] items-center rounded-xl bg-lightPrimary text-sm text-gray-600">
                <SearchIcon />
                <input
                    type="text"
                    placeholder="Produit ou référence..."
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="text-sm block w-full rounded-full bg-lightPrimary text-navy-700 outline-none"
                />
            </div>
            <div className="absolute right-4 top-4">
                <div className="flex mt-2 gap-4">
                    <p
                        className={`text-sm ${
                            period === 'descendant'
                                ? 'text-toola-500 underline'
                                : ''
                        } cursor-pointer`}
                        onClick={() => setPeriod('descendant')}
                    >
                        Décroissant
                    </p>
                    <p
                        className={`text-sm ${
                            period === 'ascendant'
                                ? 'text-toola-500 underline'
                                : ''
                        } cursor-pointer`}
                        onClick={() => setPeriod('ascendant')}
                    >
                        Croissant
                    </p>
                </div>
            </div>
            {data ? (
                data.length > 0 ? (
                    <div className="mt-2 w-full h-[220px] overflow-y-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="text-left h-[24px]">
                                    <th
                                        className="pl-[10px] w-[50%] sm:w-[35%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                        rowSpan={2}
                                    >
                                        Produit
                                    </th>
                                    <th
                                        className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                        rowSpan={2}
                                    >
                                        T. Marque (%)
                                    </th>
                                    <th
                                        className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                        rowSpan={2}
                                    >
                                        Marge (€)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData(data).map(
                                    (data: any, index: number) => {
                                        return (
                                            <tr
                                                key={index}
                                                className="text-left h-[24px] border-t border-[#E9EDF7] sm:border-none"
                                            >
                                                <td className="text-sm font-medium pl-[10px] text-toola-800">
                                                    <p
                                                        className="flex gap-2 text-toola-400 cursor-pointer hover:underline"
                                                        onClick={() =>
                                                            nav(
                                                                `/update-product/${data._id}`,
                                                            )
                                                        }
                                                    >
                                                        {data.name
                                                            ? data.name.toLowerCase()
                                                            : 'N/A'}{' '}
                                                    </p>
                                                    <div className="sm:hidden font-normal">
                                                        {data.sum +
                                                            ' ' +
                                                            data.unity}
                                                        <br />
                                                        {data.totalPrice}€
                                                    </div>
                                                </td>
                                                <td className="text-sm font-medium hidden sm:table-cell pl-[10px] text-toola-800">
                                                    {data.percentage
                                                        ? data.percentage
                                                        : 'N/A'}{' '}
                                                    %
                                                </td>
                                                <td className="text-sm font-medium hidden sm:table-cell pl-[10px] text-toola-800">
                                                    {data.margin?.toFixed(2)} €
                                                </td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="w-full flex items-center justify-center h-[50px]">
                        <p className="text-gray-700">
                            Aucun produit à livrer pour cette date
                        </p>
                    </div>
                )
            ) : (
                <div>
                    <p>Chargement...</p>
                </div>
            )}
        </Card>
    );
};

export default ListMargin;
