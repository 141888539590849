import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from 'components/card';
import { cleanStr } from 'utils/tools';
import { getLightStock } from 'interfaces/stock';
import { productColorList } from 'utils/lib';
import B2bDisplayCard from './components/B2bDisplayCard';
import SearchSelector from 'components/fields/SearchSelector';
import SearchIcon from 'components/icons/SearchIcon';
import { updateProductCategory } from 'interfaces/products';
import { toast } from 'react-toastify';

const B2bShopProductsDisplay = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [stock, setStock] = useState(null);
    const [category, setCategory] = useState('all');
    const [color, setColor] = useState('all');

    const initStock = async () => {
        const res = await getLightStock();
        if (res.error) {
            navigate('/auth/login');
            return;
        }
        setStock(res.data);
    };

    const filterStock = (stock: any) => {
        if (!stock) return [];
        return stock
            .filter(
                (e: any) =>
                    cleanStr(e.name).includes(cleanStr(search)) ||
                    cleanStr(e.ref).includes(cleanStr(search)),
            )
            .filter((e: any) =>
                category === 'all' || category === ''
                    ? true
                    : category === 'visible'
                      ? e.category !== 'hidden' && e.price > 0
                      : category === 'custom-visible'
                        ? e.category !== 'hidden' && e.price === 0
                        : category === 'hidden'
                          ? e.category === 'hidden'
                          : e.category === category,
            )
            .filter((e: any) =>
                color === 'all' ? true : e.colorCode === color,
            )
            .reverse();
    };

    const categoryDisplayName = (category: string) => {
        if (category === 'all') return 'Tout';
        if (category === 'visible') return 'Vendable';
        if (category === 'hidden') return 'Invendable';
        if (category === 'custom-visible') return 'Vendable';
        return category;
    };

    const updateCategory = async (id: string, category: string) => {
        console.log(id, category);
        const res = await updateProductCategory({ _id: id, category });
        if (res) {
            setStock((prev: any) =>
                prev.map((e: any) => (e._id === id ? { ...e, category } : e)),
            );
            const product = stock.find((e: any) => e._id === id);
            toast.success(
                `${product.name} est maintenant ${
                    category === 'hidden' ? 'caché' : 'visible'
                }`,
            );
        }
    };

    useEffect(() => {
        initStock();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const SkelettonProduct = () => {
        return (
            <div
                className={`animate-pulse relative flex items-center rounded-[20px] bg-clip-border border px-[18px] py-4 shadow-3xl shadow-shadow-500 hover:cursor-pointer dark:!bg-navy-800 dark:shadow-none`}
            >
                <div className="grid grid-cols-12">
                    <div className="-bottom-12 col-span-4 flex h-[87px] w-[87px] items-center justify-center rounded-full">
                        <div className="h-[80px] w-[80px] rounded-xl bg-gray-200"></div>
                    </div>
                    <div className="col-span-8 ml-4">
                        <div className="mt-2 w-[99%] h-4 bg-navy-700/50 rounded-full"></div>
                        <div className="my-1 w-[60px] h-2 bg-gray-600 rounded-full"></div>
                        <p className=" mb-1 text-sm font-medium text-gray-600">
                            <div className="w-5 h-3 bg-gray-600 rounded-full"></div>
                        </p>
                        <div className="w-[60px] h-5 bg-gray-600  rounded-full">
                            {' '}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Card extra={'w-full h-full bg-white mt-5'}>
                <Card extra={'w-full h-full sm:overflow-auto px-6'}>
                    {/* Selector */}
                    <p className="mt-4 font-medium">
                        {filterStock(stock).length} produits dans "
                        {categoryDisplayName(category)}"
                    </p>
                    <div className="flex justify-between">
                        <div className="hidden sm:flex mt-4 w-[80%] flex-wrap gap-2 h-min-7">
                            <p
                                className={`flex my-auto bg-white rounded-xl py-1 px-3 text-xs cursor-pointer border ${
                                    'all' === category && 'font-bold'
                                }`}
                                onClick={() => setCategory('all')}
                            >
                                Tout
                            </p>
                            <p
                                className={`flex my-auto bg-white rounded-xl py-1 px-3 text-xs cursor-pointer border ${
                                    'brut' === category && 'font-bold'
                                }`}
                                onClick={() => setCategory('visible')}
                            >
                                Visible
                            </p>
                            <p
                                className={`flex my-auto bg-white rounded-xl py-1 px-3 text-xs cursor-pointer border ${
                                    'brut' === category && 'font-bold'
                                }`}
                                onClick={() => setCategory('custom-visible')}
                            >
                                Visible par certains
                            </p>
                            <p
                                className={`flex my-auto bg-white rounded-xl py-1 px-3 text-xs cursor-pointer border ${
                                    'transformed' === category && 'font-bold'
                                }`}
                                onClick={() => setCategory('hidden')}
                            >
                                Caché
                            </p>
                            {stock
                                ?.map((item: any) => item.category)
                                ?.filter(
                                    (value: any, index: any, self: any) =>
                                        self.indexOf(value) === index,
                                )
                                ?.map((item: any) => {
                                    if (
                                        item !== 'hidden' &&
                                        item !== 'all' &&
                                        item
                                    ) {
                                        return (
                                            <p
                                                className={`flex my-auto bg-white rounded-xl py-1 px-3 text-xs cursor-pointer border ${
                                                    item === category &&
                                                    'font-bold'
                                                }`}
                                                onClick={() =>
                                                    setCategory(item)
                                                }
                                            >
                                                {item}
                                            </p>
                                        );
                                    }
                                })}
                        </div>
                        <div className="pt-3 z-50 w-[180px] text-sm">
                            <SearchSelector
                                displayKey="name"
                                options={[
                                    {
                                        name: 'Toutes les couleurs',
                                        id: 'all',
                                        _id: 'all',
                                    },
                                    ...productColorList.filter(
                                        (e: any) =>
                                            stock &&
                                            stock.some(
                                                (s: any) =>
                                                    s.colorCode === e.id,
                                            ),
                                    ),
                                ]}
                                value={color}
                                onSelect={(e: any) => {
                                    setColor(e.id);
                                }}
                                defaultKey="id"
                                canCreate={false}
                                inputSmall={true}
                            />
                        </div>
                    </div>
                    {/* Search bar */}
                    <div className="mt-2 col-span-12 flex h-[38px] flex-grow items-center rounded-xl bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white lg:col-span-4">
                        <SearchIcon />
                        <input
                            type="text"
                            placeholder="Recherche..."
                            onChange={(e) => setSearch(e.target.value)}
                            className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
                        />
                    </div>
                    {/* Skelleton Loader */}
                    {!stock && (
                        <div className="z-0 col-span-12 mb-10 mt-2 grid h-full w-full grid-cols-1 gap-3 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            {[
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                                15, 16,
                            ].map((item, index) => (
                                <SkelettonProduct key={index} />
                            ))}
                        </div>
                    )}
                    {/* Items list */}
                    <div className="z-0 col-span-12 mb-10 mt-2 grid h-full w-full grid-cols-1 gap-3 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                        {stock &&
                            filterStock(stock).map(
                                (item: any, index: number) => {
                                    return (
                                        <B2bDisplayCard
                                            id={item._id}
                                            key={index}
                                            name={item.name}
                                            type={item.type}
                                            alert={item.alert}
                                            img={item.imageUrl}
                                            productType={item.productType}
                                            unity={item.unity}
                                            price={item.price}
                                            quantity={item.quantity}
                                            reference={item.ref}
                                            color={item.colorCode}
                                            category={item.category}
                                            updateCategory={updateCategory}
                                        />
                                    );
                                },
                            )}
                        {stock && filterStock(stock).length === 0 && (
                            <div className="mt-10 col-span-12 flex justify-center items-center">
                                <p className="text-gray-600 text-xl">
                                    Aucun produit trouvé
                                </p>
                            </div>
                        )}
                    </div>
                </Card>
            </Card>
        </>
    );
};

export default B2bShopProductsDisplay;
